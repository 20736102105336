export const CUSTOMERS_LIST = "CUSTOMERS_LIST";
export const CUSTOMERS_LIST_SUCCESS = "CUSTOMERS_LIST_SUCCESS";
export const CUSTOMERS_LIST_FAIL = "CUSTOMERS_LIST_FAIL";

/* >>>>>>>>>>>>>> EDIT CUSTOMER >>>>>>>>>>>>>>>*/

export const EDIT_CUSTOMER = "EDIT_CUSTOMER";
export const EDIT_CUSTOMER_SUCCESS = "EDIT_CUSTOMER_SUCCESS";
export const EDIT_CUSTOMER_FAIL = "EDIT_CUSTOMER_FAIL";

/* >>>>>>>>>>>Update User <<<<<<<<<<<<<<< */
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

/* >>>>>>>>Add User <<<<<<<<<<<<<<< */
export const ADD_USER = "ADD_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAIL = "ADD_USER_FAIL";

/* >>>>>>>>>>>BLOCK_UNBLOCK_USER <<<<<<<<<<<<<<< */
export const BLOCK_UNBLOCK_USER = "BLOCK_UNBLOCK_USER";
export const BLOCK_UNBLOCK_USER_SUCCESS = "BLOCK_UNBLOCK_USER_SUCCESS";
export const BLOCK_UNBLOCK_USER_FAIL = "BLOCK_UNBLOCK_USER_FAIL";

/* >>>>>>>>>>>>>> DELETE USER >>>>>>>>>>>>>>>*/
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

/* >>>>>>>>>>>>>> ADD TASKS >>>>>>>>>>>>>>>*/
export const ADD_TASKS = "ADD_TASKS";
export const ADD_TASKS_SUCCESS = "ADD_TASKS_SUCCESS";
export const ADD_TASKS_FAIL = "ADD_TASKS_FAIL";

/* >>>>>>>>>>>>>> GET TASKS >>>>>>>>>>>>>>>*/
export const GET_TASKS_BY_ID = "GET_TASKS_BY_ID";
export const GET_TASKS_BY_ID_SUCCESS = "GET_TASKS_BY_ID_SUCCESS";
export const GET_TASKS_BY_ID_FAIL = "GET_TASKS_BY_ID_FAIL";

/* >>>>>>>>>>>>>> UPDATE TASKS >>>>>>>>>>>>>>>*/
export const UPDATE_TASKS = "UPDATE_TASKS";
export const UPDATE_TASKS_SUCCESS = "UPDATE_TASKS_SUCCESS";
export const UPDATE_TASKS_FAIL = "UPDATE_TASKS_FAIL";

/* >>>>>>>>>>>>>> SINGLE TASKS >>>>>>>>>>>>>>>*/
export const GET_SINGLE_TASKS = "GET_SINGLE_TASKS";
export const GET_SINGLE_TASKS_SUCCESS = "GET_SINGLE_TASKS_SUCCESS";
export const GET_SINGLE_TASKS_FAIL = "GET_SINGLE_TASKS_FAIL";

/* >>>>>>>>>>>>>> DELETE TASKS >>>>>>>>>>>>>>>*/
export const DELETE_TASKS = "DELETE_TASKS";
export const DELETE_TASKS_SUCCESS = "DELETE_TASKS_SUCCESS";
export const DELETE_TASKS_FAIL = "DELETE_TASKS_FAIL";

/* >>>>>>>>>>>>>> ADD NOTE >>>>>>>>>>>>>>>*/
export const ADD_NOTE = "ADD_NOTE";
export const ADD_NOTE_SUCCESS = "ADD_NOTE_SUCCESS";
export const ADD_NOTE_FAIL = "ADD_NOTE_FAIL";

/* >>>>>>>>>>>>>> GET NOTE >>>>>>>>>>>>>>>*/
export const GET_NOTE_BY_ID = "GET_NOTE_BY_ID";
export const GET_NOTE_BY_ID_SUCCESS = "GET_NOTE_BY_ID_SUCCESS";
export const GET_NOTE_BY_ID_FAIL = "GET_NOTE_BY_ID_FAIL";

/* >>>>>>>>>>>>>> UPDATE NOTE >>>>>>>>>>>>>>>*/
export const UPDATE_NOTE = "UPDATE_NOTE";
export const UPDATE_NOTE_SUCCESS = "UPDATE_NOTE_SUCCESS";
export const UPDATE_NOTE_FAIL = "UPDATE_NOTE_FAIL";

/* >>>>>>>>>>>>>> SINGLE NOTE >>>>>>>>>>>>>>>*/
export const GET_SINGLE_NOTE = "GET_SINGLE_NOTE";
export const GET_SINGLE_NOTE_SUCCESS = "GET_SINGLE_NOTE_SUCCESS";
export const GET_SINGLE_NOTE_FAIL = "GET_SINGLE_NOTE_FAIL";

/* >>>>>>>>>>>>>> DELETE NOTE >>>>>>>>>>>>>>>*/
export const DELETE_NOTE = "DELETE_NOTE";
export const DELETE_NOTE_SUCCESS = "DELETE_NOTE_SUCCESS";
export const DELETE_NOTE_FAIL = "DELETE_NOTE_FAIL";
