import { MenuItem, Select } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";

const Vehicles = ({
  vehicles,
  setVehicles,
  errors,
  setError,
  handleTabChange,
  handleBackBtn,
  handleSubmitBlur,
  Configures,
  extraFields,
}) => {
  const [yearList, setYearList] = useState([]);
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const last20Years = currentYear - 20;
    const yearList = [];

    for (let year = currentYear; year >= last20Years; year--) {
      yearList.push({ year: year });
    }

    setYearList(yearList);
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    // const Error = { ...errors };

    // switch (name) {
    //   case "year":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "make":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "model":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "mileage":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "purchase_lease_date":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "cutrrent_market_value":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "amount_owned":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "monthly_payment":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "name":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "address":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "phone_of_lender_lessor":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "another_year":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "another_make":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "another_model":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "another_mileage":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "another_purchase_lease_date":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "another_cutrrent_market_value":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "another_amount_owned":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "another_monthly_payment":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "another_name":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "another_address":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   default:
    //     break;
    // }
    setVehicles((pre) => ({
      ...pre,
      [name]: value,
    }));
    // setError((pre) => ({ ...pre, ...Error }));
  };

  const handlePhone = (name, phoneNumber, dialCode, validate) => {
    if (!phoneNumber || !dialCode) return false;
    const Error = { ...errors };
    switch (name) {
      case "another_phone_of_lender_lessor":
        if (!validate) {
          Error[name] = "Invalid Number";
        } else {
          Error[name] = "";
        }
        break;

      case "phone_of_lender_lessor":
        if (!validate) {
          Error[name] = "Invalid Number";
        } else {
          Error[name] = "";
        }
        break;

      default:
        break;
    }
    setVehicles((pre) => ({
      ...pre,
      [name]: phoneNumber,
    }));
    setError((pre) => ({ ...pre, ...Error }));
  };

  const handleRadioChange = (name, value) => {
    setVehicles((pre) => ({ ...pre, [name]: value }));
  };

  const handleCheckChange = (name, value) => {
    if (vehicles[name]?.includes(value)) {
      setVehicles((pre) => ({
        ...pre,
        [name]: pre[name]?.filter((item) => {
          return item !== value;
        }),
      }));
    } else {
      setVehicles((pre) => ({
        ...pre,
        [name]: pre[name] ? [...pre[name], value] : [value],
      }));
    }
    // handleSubmitBlur();
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setVehicles((pre) => ({ ...pre, [name]: value }));
  };
  return (
    <div className="bg-white card-box px-4 py-3 rounded">
      <Form>
        <Row className="align-items-start">
          {Configures?.year_visible && (
            <Col lg="6" className="my-2 doc-select">
              <label htmlFor="" className="form-label2 m-0">
                Vehicle Year
              </label>
              <Select
                className="demo-simple-select-label mt-2"
                id="demo-simple-select"
                name="year"
                value={vehicles?.year}
                onChange={handleChange}
                MenuProps={yearList}
                onBlur={handleSubmitBlur}
              >
                {yearList &&
                  yearList.length > 0 &&
                  yearList.map((item, idx) => {
                    return (
                      <MenuItem key={item?.year} value={item?.year}>
                        {item?.year}
                      </MenuItem>
                    );
                  })}
              </Select>
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.year}
              </span>
            </Col>
          )}
          {Configures?.make_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Vehicle Make
              </label>
              <input
                type="text"
                name="make"
                value={vehicles?.make}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.make}
              </span>
            </Col>
          )}
          {Configures?.model_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Vehicle Model
              </label>
              <input
                type="text"
                name="model"
                value={vehicles?.model}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.model}
              </span>
            </Col>
          )}
          {Configures?.mileage_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Vehicle Mileage
              </label>
              <input
                type="number"
                name="mileage"
                value={vehicles?.mileage}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.mileage}
              </span>
            </Col>
          )}
          {Configures?.purchase_lease_date_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Purchase/Lease Date
              </label>
              <input
                type="date"
                name="purchase_lease_date"
                value={vehicles?.purchase_lease_date}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.purchase_lease_date}
              </span>
            </Col>
          )}
          {Configures?.cutrrent_market_value_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Current Market Value
              </label>
              <input
                type="number"
                name="cutrrent_market_value"
                value={vehicles?.cutrrent_market_value}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.cutrrent_market_value}
              </span>
            </Col>
          )}
          {Configures?.amount_owned_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Amount Owed
              </label>
              <input
                type="number"
                name="amount_owned"
                value={vehicles?.amount_owned}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.amount_owned}
              </span>
            </Col>
          )}
          {Configures?.monthly_payment_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Monthly Payment
              </label>
              <input
                type="number"
                name="monthly_payment"
                value={vehicles?.monthly_payment}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.monthly_payment}
              </span>
            </Col>
          )}
          {Configures?.name_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Name of Lender/Lesser
              </label>
              <input
                type="text"
                name="name"
                value={vehicles?.name}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.name}
              </span>
            </Col>
          )}
          {Configures?.address_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Address of Lender/Lesser
              </label>
              <input
                type="text"
                name="address"
                value={vehicles?.address}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.address}
              </span>
            </Col>
          )}
          {Configures?.phone_of_lender_lessor_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Phone of Lender/Lesser
              </label>
              <PhoneInput
                country={"us"}
                value={vehicles?.phone_of_lender_lessor}
                placeholder="Phone Number"
                onChange={(data, country) => {
                  let validateWithLength = country.format?.split(".");
                  validateWithLength = validateWithLength?.length - 1;
                  if (validateWithLength == data.length) {
                    handlePhone(
                      "phone_of_lender_lessor",
                      data,
                      country.dialCode,
                      true
                    );
                  } else {
                    handlePhone(
                      "phone_of_lender_lessor",
                      data,
                      country.dialCode,
                      false
                    );
                  }
                }}
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.phone_of_lender_lessor}
              </span>
            </Col>
          )}

          <Col lg="12" className="my-2">
            <h6>Another Vehicle Description</h6>
          </Col>
          {Configures?.another_year_visible && (
            <Col lg="6" className="my-2 doc-select">
              <label htmlFor="" className="form-label2 m-0">
                Vehicle Year
              </label>
              <Select
                className="demo-simple-select-label mt-2"
                id="demo-simple-select"
                name="another_year"
                value={vehicles?.another_year}
                onChange={handleChange}
                MenuProps={yearList}
                onBlur={handleSubmitBlur}
              >
                {yearList &&
                  yearList.length > 0 &&
                  yearList.map((item, idx) => {
                    return (
                      <MenuItem key={item?.year} value={item?.year}>
                        {item?.year}
                      </MenuItem>
                    );
                  })}
              </Select>
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.another_year}
              </span>
            </Col>
          )}
          {Configures?.another_make_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Vehicle Make
              </label>
              <input
                type="text"
                name="another_make"
                value={vehicles?.another_make}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.another_make}
              </span>
            </Col>
          )}
          {Configures?.another_model_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Vehicle Model
              </label>
              <input
                type="text"
                name="another_model"
                value={vehicles?.another_model}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.another_model}
              </span>
            </Col>
          )}
          {Configures?.another_mileage_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Vehicle Mileage
              </label>
              <input
                type="number"
                name="another_mileage"
                value={vehicles?.another_mileage}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.another_mileage}
              </span>
            </Col>
          )}
          {Configures?.another_purchase_lease_date_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Purchase/Lease Date
              </label>
              <input
                type="date"
                name="another_purchase_lease_date"
                value={vehicles?.another_purchase_lease_date}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.another_purchase_lease_date}
              </span>
            </Col>
          )}
          {Configures?.another_cutrrent_market_value_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Current Market Value
              </label>
              <input
                type="number"
                name="another_cutrrent_market_value"
                value={vehicles?.another_cutrrent_market_value}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.another_cutrrent_market_value}
              </span>
            </Col>
          )}
          {Configures?.another_amount_owned_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Amount Owed
              </label>
              <input
                type="number"
                name="another_amount_owned"
                value={vehicles?.another_amount_owned}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.another_amount_owned}
              </span>
            </Col>
          )}
          {Configures?.another_monthly_payment_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Monthly Payment
              </label>
              <input
                type="number"
                name="another_monthly_payment"
                value={vehicles?.another_monthly_payment}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.another_monthly_payment}
              </span>
            </Col>
          )}
          {Configures?.another_name_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Name of Lender/Lesser
              </label>
              <input
                type="text"
                name="another_name"
                value={vehicles?.another_name}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.another_name}
              </span>
            </Col>
          )}
          {Configures?.another_address_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Address of Lender/Lesser
              </label>
              <input
                type="text"
                name="another_address"
                value={vehicles?.another_address}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.another_address}
              </span>
            </Col>
          )}
          {Configures?.another_phone_of_lender_lessor_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Phone of Lender/Lesser
              </label>
              <PhoneInput
                country={"us"}
                placeholder="Phone Number"
                value={vehicles?.another_phone_of_lender_lessor}
                onChange={(data, country) => {
                  let validateWithLength = country.format?.split(".");
                  validateWithLength = validateWithLength?.length - 1;
                  if (validateWithLength == data.length) {
                    handlePhone(
                      "another_phone_of_lender_lessor",
                      data,
                      country.dialCode,
                      true
                    );
                  } else {
                    handlePhone(
                      "another_phone_of_lender_lessor",
                      data,
                      country.dialCode,
                      false
                    );
                  }
                }}
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.another_phone_of_lender_lessor}
              </span>
            </Col>
          )}

          {extraFields &&
            extraFields.length > 0 &&
            extraFields.map((item, idx) => {
              return (
                <>
                  {Configures &&
                    Configures[item?.title.toLowerCase().split(" ").join("_")] +
                      "_visible" && (
                      <>
                        {(item?.type === "text" ||
                          item?.type === "number" ||
                          item?.type === "email" ||
                          item?.type === "password" ||
                          item?.type === "date" ||
                          item?.type === "time" ||
                          item?.type === "date-time-local") && (
                          <Col lg="6" className="my-2" key={idx}>
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize"
                            >
                              {item?.title}
                            </label>
                            <input
                              type={item?.type}
                              name={item?.title
                                .toLowerCase()
                                .split(" ")
                                .join("_")}
                              value={
                                (vehicles &&
                                  vehicles[
                                    item?.title
                                      .toLowerCase()
                                      .split(" ")
                                      .join("_")
                                  ]) ||
                                ""
                              }
                              onChange={handleChange}
                              placeholder={item?.placeholder}
                              className="form-control"
                              onBlur={handleSubmitBlur}
                            />

                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}

                        {item?.type === "radio" && (
                          <Col
                            lg="6"
                            className="my-2 dynamic-checkbox"
                            key={idx}
                          >
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize w-100"
                            >
                              {item?.title}
                            </label>
                            {item?.option &&
                              item?.option.length > 0 &&
                              item?.option.map((s, i) => {
                                return (
                                  <>
                                    <input
                                      type={item?.type}
                                      name={item?.title
                                        .toLowerCase()
                                        .split(" ")
                                        .join("_")}
                                      value={
                                        (vehicles &&
                                          vehicles[
                                            item?.title
                                              .toLowerCase()
                                              .split(" ")
                                              .join("_")
                                          ]) ||
                                        ""
                                      }
                                      checked={
                                        (vehicles &&
                                          vehicles[
                                            item?.title
                                              .toLowerCase()
                                              .split(" ")
                                              .join("_")
                                          ] == s.title) ||
                                        ""
                                      }
                                      onChange={() =>
                                        handleRadioChange(
                                          item?.title
                                            .toLowerCase()
                                            .split(" ")
                                            .join("_"),
                                          s.title
                                        )
                                      }
                                      className=""
                                      onBlur={handleSubmitBlur}
                                    />
                                    <label
                                      htmlFor=""
                                      className="form-label2 m-0 text-capitalize"
                                    >
                                      {s.title}
                                    </label>
                                  </>
                                );
                              })}

                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}

                        {item?.type === "checkbox" && (
                          <Col
                            lg="6"
                            className="my-2 dynamic-checkbox"
                            key={idx}
                          >
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize w-100"
                            >
                              {item?.title}
                            </label>
                            {item?.option &&
                              item?.option.length > 0 &&
                              item?.option.map((s, i) => {
                                return (
                                  <>
                                    <input
                                      type={item?.type}
                                      name={item?.title
                                        .toLowerCase()
                                        .split(" ")
                                        .join("_")}
                                      value={
                                        (vehicles &&
                                          vehicles[
                                            item?.title
                                              .toLowerCase()
                                              .split(" ")
                                              .join("_")
                                          ]) ||
                                        ""
                                      }
                                      checked={
                                        (vehicles &&
                                          vehicles[
                                            item?.title
                                              .toLowerCase()
                                              .split(" ")
                                              .join("_")
                                          ]?.includes(s.title)) ||
                                        []
                                      }
                                      onChange={() =>
                                        handleCheckChange(
                                          item?.title
                                            .toLowerCase()
                                            .split(" ")
                                            .join("_"),
                                          s.title
                                        )
                                      }
                                      className=""
                                      onBlur={handleSubmitBlur}
                                    />
                                    <label
                                      htmlFor=""
                                      className="form-label2 m-0 text-capitalize"
                                    >
                                      {s.title}
                                    </label>
                                  </>
                                );
                              })}

                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}

                        {item?.type === "select" && (
                          <Col lg="6" className="my-2 doc-select" key={idx}>
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize w-100"
                            >
                              {item?.title}
                            </label>
                            <Select
                              className="demo-simple-select-label"
                              id="demo-simple-select"
                              name={item?.title
                                .toLowerCase()
                                .split(" ")
                                .join("_")}
                              value={
                                (vehicles &&
                                  vehicles[
                                    item?.title
                                      .toLowerCase()
                                      .split(" ")
                                      .join("_")
                                  ]) ||
                                ""
                              }
                              onBlur={handleSubmitBlur}
                              onChange={(e) =>
                                handleSelectChange(
                                  e,
                                  item?.title.toLowerCase().split(" ").join("_")
                                )
                              }
                              MenuProps={item?.option}
                            >
                              {item?.option &&
                                item?.option.length > 0 &&
                                item?.option.map((s, i) => {
                                  return (
                                    <MenuItem key={s.title} value={s.title}>
                                      {s.title}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}

                        {item?.type === "multiselect" && (
                          <Col lg="6" className="my-2 doc-select" key={idx}>
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize w-100"
                            >
                              {item?.title}
                            </label>
                            <Select
                              className="demo-simple-select-label"
                              id="demo-simple-select"
                              name={item?.title
                                .toLowerCase()
                                .split(" ")
                                .join("_")}
                              value={
                                (vehicles &&
                                  vehicles[
                                    item?.title
                                      .toLowerCase()
                                      .split(" ")
                                      .join("_")
                                  ]) ||
                                ""
                              }
                              onBlur={handleSubmitBlur}
                              multiple
                              onChange={(e) =>
                                handleSelectChange(
                                  e,
                                  item?.title.toLowerCase().split(" ").join("_")
                                )
                              }
                              MenuProps={item?.option}
                            >
                              {item?.option &&
                                item?.option.length > 0 &&
                                item?.option.map((s, i) => {
                                  return (
                                    <MenuItem key={s.title} value={s.title}>
                                      {s.title}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}
                      </>
                    )}
                </>
              );
            })}

          <Col lg="12" className="my-2">
            <div className="pt-4 btn-wrp d-flex algign-items-center">
              <Button
                //   disabled={errors?.disabled}
                onClick={() => handleTabChange("assets")}
                className={`d-inline-flex align-items-center justify-content-center me-2`}
              >
                Next
              </Button>
              <Button
                //   disabled={errors?.disabled}
                onClick={() => handleBackBtn("realPropertyOwned")}
                className={`back-form-btnn d-inline-flex align-items-center justify-content-center me-2`}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Vehicles;
