import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import PropertyAddons from "../PropertyAddons";

const RealPropertyOwned = ({
  realPropertyOwned,
  setRealPropertyOwned,
  errors,
  setError,
  handleTabChange,
  handleSubmitBlur,
  handleBackBtn,
  Configures,
  extraFields,
  handleSaveField,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    // const Error = { ...errors };

    // switch (name) {
    //   case "property_description":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "location":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "current_fair_market_value":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "loan_balance":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "amount_of_monthly_payment":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "amount_of_your_equity":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "name_of_lender":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "address":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "account_no":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   default:
    //     break;
    // }
    setRealPropertyOwned((pre) => ({
      ...pre,
      [name]: value,
    }));
    // setError((pre) => ({ ...pre, ...Error }));
  };

  const handleRadioChange = (name, value) => {
    setRealPropertyOwned((pre) => ({ ...pre, [name]: value }));
  };

  const handleCheckChange = (name, value) => {
    if (realPropertyOwned[name]?.includes(value)) {
      setRealPropertyOwned((pre) => ({
        ...pre,
        [name]: pre[name]?.filter((item) => {
          return item !== value;
        }),
      }));
    } else {
      setRealPropertyOwned((pre) => ({
        ...pre,
        [name]: pre[name] ? [...pre[name], value] : [value],
      }));
    }
    // handleSubmitBlur();
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setRealPropertyOwned((pre) => ({ ...pre, [name]: value }));
  };

  const handleExtraChange = (e) => {
    setRealPropertyOwned(e);
  };
  return (
    <div className="bg-white card-box px-4 py-3 rounded">
      <Form>
        <Row className="align-items-start">
          {/* {Configures?.property_description_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Property Description
              </label>
              <input
                type="text"
                name="property_description"
                value={realPropertyOwned?.property_description}
                onChange={handleChange}
                placeholder="i.e. Home, Rental, Time Share"
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.property_description}
              </span>
            </Col>
          )} */}
          <PropertyAddons
            handleChange={handleExtraChange}
            data={realPropertyOwned || []}
            handleSaveField={handleSaveField}
            realPropertyOwned={realPropertyOwned}
            setRealPropertyOwned={setRealPropertyOwned}
            handleSubmitBlur={handleSubmitBlur}
          />

          {extraFields &&
            extraFields.length > 0 &&
            extraFields.map((item, idx) => {
              return (
                <>
                  {Configures &&
                    Configures[item?.title.toLowerCase().split(" ").join("_")] +
                      "_visible" && (
                      <>
                        {(item?.type === "text" ||
                          item?.type === "number" ||
                          item?.type === "email" ||
                          item?.type === "password" ||
                          item?.type === "date" ||
                          item?.type === "time" ||
                          item?.type === "date-time-local") && (
                          <Col lg="6" className="my-2" key={idx}>
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize"
                            >
                              {item?.title}
                            </label>
                            <input
                              type={item?.type}
                              name={item?.title
                                .toLowerCase()
                                .split(" ")
                                .join("_")}
                              value={
                                (realPropertyOwned &&
                                  realPropertyOwned[
                                    item?.title
                                      .toLowerCase()
                                      .split(" ")
                                      .join("_")
                                  ]) ||
                                ""
                              }
                              onChange={handleChange}
                              placeholder={item?.placeholder}
                              className="form-control"
                              onBlur={handleSubmitBlur}
                            />

                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}

                        {item?.type === "radio" && (
                          <Col
                            lg="6"
                            className="my-2 dynamic-checkbox"
                            key={idx}
                          >
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize w-100"
                            >
                              {item?.title}
                            </label>
                            {item?.option &&
                              item?.option.length > 0 &&
                              item?.option.map((s, i) => {
                                return (
                                  <>
                                    <input
                                      type={item?.type}
                                      name={item?.title
                                        .toLowerCase()
                                        .split(" ")
                                        .join("_")}
                                      value={
                                        (realPropertyOwned &&
                                          realPropertyOwned[
                                            item?.title
                                              .toLowerCase()
                                              .split(" ")
                                              .join("_")
                                          ]) ||
                                        ""
                                      }
                                      checked={
                                        (realPropertyOwned &&
                                          realPropertyOwned[
                                            item?.title
                                              .toLowerCase()
                                              .split(" ")
                                              .join("_")
                                          ] == s.title) ||
                                        ""
                                      }
                                      onChange={() =>
                                        handleRadioChange(
                                          item?.title
                                            .toLowerCase()
                                            .split(" ")
                                            .join("_"),
                                          s.title
                                        )
                                      }
                                      className=""
                                      onBlur={handleSubmitBlur}
                                    />
                                    <label
                                      htmlFor=""
                                      className="form-label2 m-0 text-capitalize"
                                    >
                                      {s.title}
                                    </label>
                                  </>
                                );
                              })}

                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}

                        {item?.type === "checkbox" && (
                          <Col
                            lg="6"
                            className="my-2 dynamic-checkbox"
                            key={idx}
                          >
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize w-100"
                            >
                              {item?.title}
                            </label>
                            {item?.option &&
                              item?.option.length > 0 &&
                              item?.option.map((s, i) => {
                                return (
                                  <>
                                    <input
                                      type={item?.type}
                                      name={item?.title
                                        .toLowerCase()
                                        .split(" ")
                                        .join("_")}
                                      value={
                                        (realPropertyOwned &&
                                          realPropertyOwned[
                                            item?.title
                                              .toLowerCase()
                                              .split(" ")
                                              .join("_")
                                          ]) ||
                                        ""
                                      }
                                      checked={
                                        (realPropertyOwned &&
                                          realPropertyOwned[
                                            item?.title
                                              .toLowerCase()
                                              .split(" ")
                                              .join("_")
                                          ]?.includes(s.title)) ||
                                        []
                                      }
                                      onChange={() =>
                                        handleCheckChange(
                                          item?.title
                                            .toLowerCase()
                                            .split(" ")
                                            .join("_"),
                                          s.title
                                        )
                                      }
                                      className=""
                                      onBlur={handleSubmitBlur}
                                    />
                                    <label
                                      htmlFor=""
                                      className="form-label2 m-0 text-capitalize"
                                    >
                                      {s.title}
                                    </label>
                                  </>
                                );
                              })}

                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}

                        {item?.type === "select" && (
                          <Col lg="6" className="my-2 doc-select" key={idx}>
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize w-100"
                            >
                              {item?.title}
                            </label>
                            <Select
                              className="demo-simple-select-label"
                              id="demo-simple-select"
                              name={item?.title
                                .toLowerCase()
                                .split(" ")
                                .join("_")}
                              value={
                                (realPropertyOwned &&
                                  realPropertyOwned[
                                    item?.title
                                      .toLowerCase()
                                      .split(" ")
                                      .join("_")
                                  ]) ||
                                ""
                              }
                              onBlur={handleSubmitBlur}
                              onChange={(e) =>
                                handleSelectChange(
                                  e,
                                  item?.title.toLowerCase().split(" ").join("_")
                                )
                              }
                              MenuProps={item?.option}
                            >
                              {item?.option &&
                                item?.option.length > 0 &&
                                item?.option.map((s, i) => {
                                  return (
                                    <MenuItem key={s.title} value={s.title}>
                                      {s.title}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}

                        {item?.type === "multiselect" && (
                          <Col lg="6" className="my-2 doc-select" key={idx}>
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize w-100"
                            >
                              {item?.title}
                            </label>
                            <Select
                              className="demo-simple-select-label"
                              id="demo-simple-select"
                              name={item?.title
                                .toLowerCase()
                                .split(" ")
                                .join("_")}
                              value={
                                (realPropertyOwned &&
                                  realPropertyOwned[
                                    item?.title
                                      .toLowerCase()
                                      .split(" ")
                                      .join("_")
                                  ]) ||
                                ""
                              }
                              onBlur={handleSubmitBlur}
                              multiple
                              onChange={(e) =>
                                handleSelectChange(
                                  e,
                                  item?.title.toLowerCase().split(" ").join("_")
                                )
                              }
                              MenuProps={item?.option}
                            >
                              {item?.option &&
                                item?.option.length > 0 &&
                                item?.option.map((s, i) => {
                                  return (
                                    <MenuItem key={s.title} value={s.title}>
                                      {s.title}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}
                      </>
                    )}
                </>
              );
            })}

          <Col lg="12" className="my-2">
            <div className="pt-4 btn-wrp d-flex algign-items-center">
              <Button
                //   disabled={errors?.disabled}
                onClick={() => handleTabChange("vehicles")}
                className={`d-inline-flex align-items-center justify-content-center me-2`}
              >
                Next
              </Button>
              <Button
                //   disabled={errors?.disabled}
                onClick={() => handleBackBtn("personalAssets")}
                className={`back-form-btnn d-inline-flex align-items-center justify-content-center me-2`}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default RealPropertyOwned;
