import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Container,
  Form,
  Row,
  Tab,
  Nav,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loading from "../../../Common/extra/loading";
import { checkValidations } from "../../../Services/Helper";
import {
  getConfigure,
  getFieldData,
  postFinancial,
} from "../../../store/actions";
import Assets from "./financialComponents/Assets";
import EmploymentInfo from "./financialComponents/empInfo";
import IncomeExpenseStatement from "./financialComponents/incomeExpense";
import OtherFinancialInfo from "./financialComponents/otherFinancialInfo";
import PersonalAssets from "./financialComponents/personalAssets";
import PersonalInfo from "./financialComponents/personalInfo";
import RealPropertyOwned from "./financialComponents/realPropertyOwned";
import Vehicles from "./financialComponents/vehicles";
import { toast } from "react-toastify";
import LegalInfo from "./financialComponents/Legal";
import BankruptcyInfo from "./financialComponents/Bankruptcy";

const FinancialForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let id = localStorage && localStorage.getItem("id");
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("personalInfo");
  const [isComplete, setIsComplete] = useState({
    personalInfo: true,
    empInfo: true,
    otherFinanceInfo: true,
    personalAssets: true,
    realPropertyOwned: true,
    vehicles: true,
    assets: true,
    incomeExpense: true,
    legal: true,
    bankruptcy: true,
  });

  const [personalInfo, setPersonalInfo] = useState({
    full_name: "",
    marital_status: "",
    address: "",
    home_phone: "",
    cell_phone: "",
    work_phone: "",
    email: "",
    fax: "",
    social_security_no: "",
    date_of_birth: "",
    dependents_name: "",
    dependents_age: "",
    dependents_relationship: "",
  });

  const [legalInfo, setLegalInfo] = useState({
    full_name: "",
    address: "",
    cell_phone: "",
    email: "",
  });

  const [bankruptcyInfo, setBankruptcyInfo] = useState({
    bank_name: "",
    account_number: "",
    amount: "",
    cell_phone: "",
    email: "",
    date_of_birth: "",
  });

  const [personalInfoErrors, setPersonalInfoError] = useState({
    full_name: "",
    marital_status: "",
    address: "",
    home_phone: "",
    cell_phone: "",
    work_phone: "",
    email: "",
    fax: "",
    social_security_no: "",
    date_of_birth: "",
    dependents_name: "",
    dependents_age: "",
    dependents_relationship: "",
  });

  const [legalInfoErrors, setLegalInfoError] = useState({
    full_name: "",
    address: "",
    cell_phone: "",
    email: "",
  });
  const [bankruptcyInfoErrors, setBankruptcyInfoError] = useState({
    bank_name: "",
    account_number: "",
    amount: "",
    cell_phone: "",
    email: "",
    date_of_birth: "",
  });

  const [employmentInfo, setEmploymentInfo] = useState({
    employer: "",
    length_of_job: "",
    employer_address: "",
    occupation: "",
    pay_period: "",

    spouse_employer: "",
    spouse_length_of_job: "",
    spouse_employer_address: "",
    spouse_occupation: "",
    spouse_pay_period: "",
  });

  const [employmentInfoError, setEmploymentInfoError] = useState({
    employer: "",
    length_of_job: "",
    employer_address: "",
    occupation: "",
    pay_period: "",

    spouse_employer: "",
    spouse_length_of_job: "",
    spouse_employer_address: "",
    spouse_occupation: "",
    spouse_pay_period: "",
  });
  const [otherFinanceInfo, setOtherFinanceInfo] = useState({
    are_you_currently_a_party_to_law_suit: "",
    law_suit_yes_plaintiff_defendent: "",
    law_suit_yes_location_of_filing: "",
    law_suit_yes_case_no: "",
    law_suit_yes_subject_of_suit: "",
    have_you_ever_filed_for_bankcrupty_protection: "",
    bankcrupty_protection_yes_date_of_filing: "",
    bankcrupty_protection_yes_date_of_discharge: "",
    bankcrupty_protection_yes_petition_no: "",
    bankcrupty_protection_yes_location: "",
    increase_decrease_in_income: "",
    beneficiary_to_any_trust_estate_life_insurance: "",
  });

  const [otherFinanceInfoError, setOtherFinanceInfoError] = useState({
    are_you_currently_a_party_to_law_suit: "",
    law_suit_yes_plaintiff_defendent: "",
    law_suit_yes_location_of_filing: "",
    law_suit_yes_case_no: "",
    law_suit_yes_subject_of_suit: "",
    have_you_ever_filed_for_bankcrupty_protection: "",
    bankcrupty_protection_yes_date_of_filing: "",
    bankcrupty_protection_yes_date_of_discharge: "",
    bankcrupty_protection_yes_petition_no: "",
    bankcrupty_protection_yes_location: "",
    increase_decrease_in_income: "",
    beneficiary_to_any_trust_estate_life_insurance: "",
  });

  const [personalAssets, setPersonalAssets] = useState({
    cash_on_hand: "",

    personal_fullname: "",
    personal_address_of_bank: "",
    personal_account_type: "",
    personal_account_number: "",
    personal_account_balance: "",

    additional_fullname: "",
    additional_address_of_bank: "",
    additional_account_type: "",
    additional_account_number: "",
    additional_account_balance: "",

    investment_type: "",
    investment_fullname: "",
    investment_address_of_institution: "",
    investment_account_number: "",
    investment_account_balance: "",

    whole_life_insurance: "",

    credit_fullname: "",
    credit_address_of_bank: "",
    credit_account_type: "",
    credit_account_number: "",
    credit_account_balance: "",
  });

  const [personalAssetsError, setPersonalAssetsError] = useState({
    cash_on_hand: "",

    personal_fullname: "",
    personal_address_of_bank: "",
    personal_account_type: "",
    personal_account_number: "",
    personal_account_balance: "",

    additional_fullname: "",
    additional_address_of_bank: "",
    additional_account_type: "",
    additional_account_number: "",
    additional_account_balance: "",

    investment_type: "",
    investment_fullname: "",
    investment_address_of_institution: "",
    investment_account_number: "",
    investment_account_balance: "",

    whole_life_insurance: "",

    credit_fullname: "",
    credit_address_of_bank: "",
    credit_account_type: "",
    credit_account_number: "",
    credit_account_balance: "",
  });

  const [realPropertyOwned, setRealPropertyOwned] = useState([
    {
      property_description: "",
      location: "",
      current_fair_market_value: "",
      loan_balance: "",
      amount_of_monthly_payment: "",
      amount_of_your_equity: "",
      name_of_lender: "",
      address: "",
      account_no: "",
    },
  ]);

  const [realPropertyOwnedError, setRealPropertyOwnedError] = useState({
    property_description: "",
    location: "",
    current_fair_market_value: "",
    loan_balance: "",
    amount_of_monthly_payment: "",
    amount_of_your_equity: "",
    name_of_lender: "",
    address: "",
    account_no: "",
  });

  const [vehicles, setVehicles] = useState({
    year: "",
    make: "",
    model: "",
    mileage: "",
    purchase_lease_date: "",
    cutrrent_market_value: "",
    amount_owned: "",
    monthly_payment: "",
    name: "",
    address: "",
    phone_of_lender_lessor: "",

    another_year: "",
    another_make: "",
    another_model: "",
    another_mileage: "",
    another_purchase_lease_date: "",
    another_cutrrent_market_value: "",
    another_amount_owned: "",
    another_monthly_payment: "",
    another_name: "",
    another_address: "",
    another_phone_of_lender_lessor: "",
  });

  const [vehiclesError, setVehiclesError] = useState({
    year: "",
    make: "",
    model: "",
    mileage: "",
    purchase_lease_date: "",
    cutrrent_market_value: "",
    amount_owned: "",
    monthly_payment: "",
    name: "",
    address: "",
    phone_of_lender_lessor: "",

    another_year: "",
    another_make: "",
    another_model: "",
    another_mileage: "",
    another_purchase_lease_date: "",
    another_cutrrent_market_value: "",
    another_amount_owned: "",
    another_monthly_payment: "",
    another_name: "",
    another_address: "",
    another_phone_of_lender_lessor: "",
  });

  const [assets, setAssets] = useState({
    furniture_location: "",
    furniture_value: "",
    furniture_loanbalance: "",

    personal_location: "",
    personal_value: "",
    personal_loanbalance: "",

    artwork_location: "",
    artwork_value: "",
    artwork_loanbalance: "",

    jewellery_location: "",
    jewellery_value: "",
    jewellery_loanbalance: "",

    collection_location: "",
    collection_value: "",
    collection_loanbalance: "",

    guns_location: "",
    guns_value: "",
    guns_loanbalance: "",

    coins_location: "",
    coins_value: "",
    coins_loanbalance: "",

    antiques_location: "",
    antiques_value: "",
    antiques_loanbalance: "",
  });

  const [assetsError, setAssetsError] = useState({
    furniture_location: "",
    furniture_value: "",
    furniture_loanbalance: "",

    personal_location: "",
    personal_value: "",
    personal_loanbalance: "",

    artwork_location: "",
    artwork_value: "",
    artwork_loanbalance: "",

    jewellery_location: "",
    jewellery_value: "",
    jewellery_loanbalance: "",

    collection_location: "",
    collection_value: "",
    collection_loanbalance: "",

    guns_location: "",
    guns_value: "",
    guns_loanbalance: "",

    coins_location: "",
    coins_value: "",
    coins_loanbalance: "",

    antiques_location: "",
    antiques_value: "",
    antiques_loanbalance: "",
  });

  const [incomeExpenseState, setIncomeExpenseState] = useState({
    gross_you: "",
    gross_spouse_partner: "",

    rent_you: "",
    rent_spouse_partner: "",

    other_you: "",
    other_spouse_partner: "",

    child_you: "",
    child_spouse_partner: "",

    soc_you: "",
    soc_spouse_partner: "",

    pension_you: "",
    pension_spouse_partner: "",

    misc_you: "",
    misc_spouse_partner: "",

    primary_home_mortgage: "",
    primary_home_tax_ins: "",
    any_other_mortgage: "",
    any_other_property_tax_ins: "",
    credit_card_payments_total: "",
    any_other_revolving_debt: "",
    installment_loan_payments: "",
    dependent_child_care: "",

    personal_loans: "",
    income_tax_from_paycheck: "",
    fourZeroOneKLoans: "",
    child_support: "",
    alimony: "",
    irs_liens: "",
    tuition: "",

    student_loans: "",
    gas: "",
    electric: "",
    water: "",
    trash_sewer: "",
    telephone: "",
    cell_phone: "",
    internet: "",
    cable_satellite: "",
    vehicle_insurance: "",
    maintenance: "",
    food: "",
    transportation: "",
    gardening_landscaping: "",
    housecleaning: "",
    other: "",

    insurance_medical_amount: "",
    insurance_medical_paycheck: "",

    insurance_dental_amount: "",
    insurance_dental_paycheck: "",

    insurance_life_amount: "",
    insurance_life_paycheck: "",

    insurance_vision_amount: "",
    insurance_vision_paycheck: "",

    insurance_disability_amount: "",
    insurance_disability_paycheck: "",

    insurance_surrender_amount: "",
    insurance_surrender_paycheck: "",
  });

  const [incomeExpenseStateError, setIncomeExpenseStateError] = useState({
    gross_you: "",
    gross_spouse_partner: "",

    rent_you: "",
    rent_spouse_partner: "",

    other_you: "",
    other_spouse_partner: "",

    child_you: "",
    child_spouse_partner: "",

    soc_you: "",
    soc_spouse_partner: "",

    pension_you: "",
    pension_spouse_partner: "",

    misc_you: "",
    misc_spouse_partner: "",

    primary_home_mortgage: "",
    primary_home_tax_ins: "",
    any_other_mortgage: "",
    any_other_property_tax_ins: "",
    credit_card_payments_total: "",
    any_other_revolving_debt: "",
    installment_loan_payments: "",
    dependent_child_care: "",
    personal_loans: "",
    income_tax_from_paycheck: "",
    fourZeroOneKLoans: "",
    child_support: "",
    alimony: "",
    irs_liens: "",
    tuition: "",
    student_loans: "",
    gas: "",
    electric: "",
    water: "",
    trash_sewer: "",
    telephone: "",
    cell_phone: "",
    internet: "",
    cable_satellite: "",
    vehicle_insurance: "",
    maintenance: "",
    food: "",
    transportation: "",
    gardening_landscaping: "",
    housecleaning: "",
    other: "",

    insurance_medical_amount: "",
    insurance_medical_paycheck: "",

    insurance_dental_amount: "",
    insurance_dental_paycheck: "",

    insurance_life_amount: "",
    insurance_life_paycheck: "",

    insurance_vision_amount: "",
    insurance_vision_paycheck: "",

    insurance_disability_amount: "",
    insurance_disability_paycheck: "",
  });

  const allData = useSelector((s) => s.Profile?.financialFormData);
  const Configures = useSelector(
    (s) => s?.login?.getConfigure?.data?.option_value
  );
  const FieldData = useSelector(
    (s) => s?.login?.getFieldData?.field_data?.option_value
  );
  console.log(Configures, FieldData, "FieldDataFieldData");

  useEffect(() => {
    personalInfo &&
      FieldData &&
      FieldData?.personal_information_fields &&
      FieldData?.personal_information_fields.length > 0 &&
      FieldData?.personal_information_fields.map((item) => {
        return (
          !personalInfo[item?.title.toLowerCase().split(" ").join("_")] &&
          setPersonalInfo((pre) => ({
            ...pre,
            [item?.title.toLowerCase().split(" ").join("_")]: "",
          }))
        );
      });

    bankruptcyInfo &&
      FieldData &&
      FieldData?.bankruptcy_field &&
      FieldData?.bankruptcy_field.length > 0 &&
      FieldData?.bankruptcy_field.map((item) => {
        return (
          !bankruptcyInfo[item?.title.toLowerCase().split(" ").join("_")] &&
          setBankruptcyInfo((pre) => ({
            ...pre,
            [item?.title.toLowerCase().split(" ").join("_")]: "",
          }))
        );
      });

    legalInfo &&
      FieldData &&
      FieldData?.legal_fields &&
      FieldData?.legal_fields.length > 0 &&
      FieldData?.legal_fields.map((item) => {
        return (
          !legalInfo[item?.title.toLowerCase().split(" ").join("_")] &&
          setLegalInfo((pre) => ({
            ...pre,
            [item?.title.toLowerCase().split(" ").join("_")]: "",
          }))
        );
      });

    employmentInfo &&
      FieldData &&
      FieldData?.employment_information_fields &&
      FieldData?.employment_information_fields.length > 0 &&
      FieldData?.employment_information_fields.map((item) => {
        return (
          !employmentInfo[item?.title.toLowerCase().split(" ").join("_")] &&
          setEmploymentInfo((pre) => ({
            ...pre,
            [item?.title.toLowerCase().split(" ").join("_")]: "",
          }))
        );
      });

    otherFinanceInfo &&
      FieldData &&
      FieldData?.other_finanical_information_fields &&
      FieldData?.other_finanical_information_fields.length > 0 &&
      FieldData?.other_finanical_information_fields.map((item) => {
        return (
          !otherFinanceInfo[item?.title.toLowerCase().split(" ").join("_")] &&
          setOtherFinanceInfo((pre) => ({
            ...pre,
            [item?.title.toLowerCase().split(" ").join("_")]: "",
          }))
        );
      });

    personalAssets &&
      FieldData &&
      FieldData?.personal_assets_fields &&
      FieldData?.personal_assets_fields.length > 0 &&
      FieldData?.personal_assets_fields.map((item) => {
        return (
          !personalAssets[item?.title.toLowerCase().split(" ").join("_")] &&
          setPersonalAssets((pre) => ({
            ...pre,
            [item?.title.toLowerCase().split(" ").join("_")]: "",
          }))
        );
      });

    realPropertyOwned &&
      FieldData &&
      FieldData?.real_property_owned_fields &&
      FieldData?.real_property_owned_fields.length > 0 &&
      FieldData?.real_property_owned_fields.map((item) => {
        return (
          !realPropertyOwned[item?.title.toLowerCase().split(" ").join("_")] &&
          setRealPropertyOwned((pre) => ({
            ...pre,
            [item?.title.toLowerCase().split(" ").join("_")]: "",
          }))
        );
      });

    vehicles &&
      FieldData &&
      FieldData?.vehicles_fields &&
      FieldData?.vehicles_fields.length > 0 &&
      FieldData?.vehicles_fields.map((item) => {
        return (
          !vehicles[item?.title.toLowerCase().split(" ").join("_")] &&
          setVehicles((pre) => ({
            ...pre,
            [item?.title.toLowerCase().split(" ").join("_")]: "",
          }))
        );
      });

    assets &&
      FieldData &&
      FieldData?.assets_fields &&
      FieldData?.assets_fields.length > 0 &&
      FieldData?.assets_fields.map((item) => {
        return (
          !assets[item?.title.toLowerCase().split(" ").join("_")] &&
          setAssets((pre) => ({
            ...pre,
            [item?.title.toLowerCase().split(" ").join("_")]: "",
          }))
        );
      });

    incomeExpenseState &&
      FieldData &&
      FieldData?.income_fields &&
      FieldData?.income_fields.length > 0 &&
      FieldData?.income_fields.map((item) => {
        return (
          !incomeExpenseState[item?.title.toLowerCase().split(" ").join("_")] &&
          setIncomeExpenseState((pre) => ({
            ...pre,
            [item?.title.toLowerCase().split(" ").join("_")]: "",
          }))
        );
      });
  }, [FieldData, allData]);

  useEffect(() => {
    if (personalInfo) {
      const personalInfoVal = checkValidations(personalInfo);
      if (personalInfoVal.error) {
        setIsComplete((pre) => ({ ...pre, personalInfo: true }));
      } else {
        setIsComplete((pre) => ({ ...pre, personalInfo: false }));
      }
    }
  }, [personalInfo]);

  useEffect(() => {
    if (legalInfo) {
      const legalInfoVal = checkValidations(legalInfo);
      console.log(legalInfoVal, "legalInfoVal");
      if (legalInfoVal.error) {
        setIsComplete((pre) => ({ ...pre, legal: true }));
      } else {
        setIsComplete((pre) => ({ ...pre, legal: false }));
      }
    }
  }, [legalInfo]);

  useEffect(() => {
    if (bankruptcyInfo) {
      const bankruptcyInfoVal = checkValidations(bankruptcyInfo);
      if (bankruptcyInfoVal.error) {
        setIsComplete((pre) => ({ ...pre, bankruptcy: true }));
      } else {
        setIsComplete((pre) => ({ ...pre, bankruptcy: false }));
      }
    }
  }, [bankruptcyInfo]);

  useEffect(() => {
    if (employmentInfo) {
      const empInfoVal = checkValidations(employmentInfo);
      if (empInfoVal.error) {
        setIsComplete((pre) => ({ ...pre, empInfo: true }));
      } else {
        setIsComplete((pre) => ({ ...pre, empInfo: false }));
      }
    }
  }, [employmentInfo]);

  useEffect(() => {
    if (otherFinanceInfo) {
      const otherFinInfo = checkValidations(otherFinanceInfo);
      if (otherFinInfo.error) {
        setIsComplete((pre) => ({ ...pre, otherFinanceInfo: true }));
      } else {
        setIsComplete((pre) => ({ ...pre, otherFinanceInfo: false }));
      }
    }
  }, [otherFinanceInfo]);

  useEffect(() => {
    if (personalAssets) {
      const personalAssetsVal = checkValidations(personalAssets);
      if (personalAssetsVal.error) {
        setIsComplete((pre) => ({ ...pre, personalAssets: true }));
      } else {
        setIsComplete((pre) => ({ ...pre, personalAssets: false }));
      }
    }
  }, [personalAssets]);

  useEffect(() => {
    if (realPropertyOwned) {
      const realpropertyVal = checkValidations(realPropertyOwned);
      if (realpropertyVal.error) {
        setIsComplete((pre) => ({ ...pre, realPropertyOwned: true }));
      } else {
        setIsComplete((pre) => ({ ...pre, realPropertyOwned: false }));
      }
    }
  }, [realPropertyOwned]);

  useEffect(() => {
    if (vehicles) {
      const vehiclesVal = checkValidations(vehicles);
      if (vehiclesVal.error) {
        setIsComplete((pre) => ({ ...pre, vehicles: true }));
      } else {
        setIsComplete((pre) => ({ ...pre, vehicles: false }));
      }
    }
  }, [vehicles]);

  useEffect(() => {
    if (assets) {
      const assetsVal = checkValidations(assets);
      if (assetsVal.error) {
        setIsComplete((pre) => ({ ...pre, assets: true }));
      } else {
        setIsComplete((pre) => ({ ...pre, assets: false }));
      }
    }
  }, [assets]);

  useEffect(() => {
    if (incomeExpenseState) {
      const incomeVal = checkValidations(incomeExpenseState);
      if (incomeVal.error) {
        setIsComplete((pre) => ({ ...pre, incomeExpense: true }));
      } else {
        setIsComplete((pre) => ({ ...pre, incomeExpense: false }));
      }
    }
  }, [incomeExpenseState]);

  useEffect(() => {
    dispatch(
      getConfigure({ option_id: "1", option_key: "AtsFinancialQuestionnaire" })
    );
    dispatch(getFieldData());
  }, []);

  const getRequiredKeys = (obj, state) => {
    let keysObj = {};
    let arr = Object.entries(obj)
      .filter(
        ([key, values]) => values.visible == "true" && values.required == "true"
      )
      .map((data) => (keysObj[data[0]] = ""));

    for (const key in state) {
      if (!(key in keysObj)) {
        delete state[key];
      }
    }
    return state;
  };

  // useEffect(() => {
  //   if (!Configures?.personal_information) return;

  //   getRequiredKeys(Configures?.personal_information, personalInfo);
  // }, [Configures?.personal_information]);

  const handleTabChange = (tab) => {
    // if (activeTab == "personalInfo") {
    //   let validateKeys = getRequiredKeys(
    //     Configures?.personal_information,
    //     personalInfo
    //   );
    //   const personalCheck = checkValidations(validateKeys);
    //   if (
    //     !personalCheck.error &&
    //     personalInfoErrors.cell_phone !== "Invalid Number" &&
    //     personalInfoErrors.work_phone !== "Invalid Number" &&
    //     personalInfoErrors.home_phone !== "Invalid Number" &&
    //     personalInfoErrors.email !== "Invalid Email"
    //   ) {
    setActiveTab(tab);
    //   } else {
    //     if (personalInfo.full_name == "") {
    //       setPersonalInfoError((pre) => ({ ...pre, full_name: "Required" }));
    //     }
    //     if (personalInfo.marital_status == "") {
    //       setPersonalInfoError((pre) => ({
    //         ...pre,
    //         marital_status: "Required",
    //       }));
    //     }
    //     if (personalInfo.address == "") {
    //       setPersonalInfoError((pre) => ({ ...pre, address: "Required" }));
    //     }
    //     if (personalInfo.cell_phone == "") {
    //       setPersonalInfoError((pre) => ({ ...pre, cell_phone: "Required" }));
    //     }
    //     if (personalInfo.work_phone == "") {
    //       setPersonalInfoError((pre) => ({ ...pre, work_phone: "Required" }));
    //     }
    //     if (personalInfo.home_phone == "") {
    //       setPersonalInfoError((pre) => ({ ...pre, home_phone: "Required" }));
    //     }
    //     if (personalInfo.email == "") {
    //       setPersonalInfoError((pre) => ({ ...pre, email: "Required" }));
    //     }
    //     if (personalInfo.fax == "") {
    //       setPersonalInfoError((pre) => ({ ...pre, fax: "Required" }));
    //     }
    //     if (personalInfo.social_security_no == "") {
    //       setPersonalInfoError((pre) => ({
    //         ...pre,
    //         social_security_no: "Required",
    //       }));
    //     }
    //     if (personalInfo.date_of_birth == "") {
    //       setPersonalInfoError((pre) => ({
    //         ...pre,
    //         date_of_birth: "Required",
    //       }));
    //     }
    //     if (personalInfo.dependents_name == "") {
    //       setPersonalInfoError((pre) => ({
    //         ...pre,
    //         dependents_name: "Required",
    //       }));
    //     }
    //     if (personalInfo.dependents_age == "") {
    //       setPersonalInfoError((pre) => ({
    //         ...pre,
    //         dependents_age: "Required",
    //       }));
    //     }
    //     if (personalInfo.dependents_relationship == "") {
    //       setPersonalInfoError((pre) => ({
    //         ...pre,
    //         dependents_relationship: "Required",
    //       }));
    //     }
    //   }
    // } else if (activeTab == "empInfo") {
    //   let validateKeys = getRequiredKeys(
    //     Configures?.employment_information,
    //     employmentInfo
    //   );
    //   const Check = checkValidations(validateKeys);
    //   if (!Check.error) {
    //     setActiveTab(tab);
    //   } else {
    //     if (employmentInfo.employer == "") {
    //       setEmploymentInfoError((pre) => ({ ...pre, employer: "Required" }));
    //     }
    //     if (employmentInfo.employer_address == "") {
    //       setEmploymentInfoError((pre) => ({
    //         ...pre,
    //         employer_address: "Required",
    //       }));
    //     }
    //     if (employmentInfo.length_of_job == "") {
    //       setEmploymentInfoError((pre) => ({
    //         ...pre,
    //         length_of_job: "Required",
    //       }));
    //     }
    //     if (employmentInfo.occupation == "") {
    //       setEmploymentInfoError((pre) => ({ ...pre, occupation: "Required" }));
    //     }
    //     if (employmentInfo.pay_period == "") {
    //       setEmploymentInfoError((pre) => ({ ...pre, pay_period: "Required" }));
    //     }
    //     if (employmentInfo.spouse_employer == "") {
    //       setEmploymentInfoError((pre) => ({
    //         ...pre,
    //         spouse_employer: "Required",
    //       }));
    //     }
    //     if (employmentInfo.spouse_length_of_job == "") {
    //       setEmploymentInfoError((pre) => ({
    //         ...pre,
    //         spouse_length_of_job: "Required",
    //       }));
    //     }
    //     if (employmentInfo.spouse_employer_address == "") {
    //       setEmploymentInfoError((pre) => ({
    //         ...pre,
    //         spouse_employer_address: "Required",
    //       }));
    //     }
    //     if (employmentInfo.spouse_occupation == "") {
    //       setEmploymentInfoError((pre) => ({
    //         ...pre,
    //         spouse_occupation: "Required",
    //       }));
    //     }
    //     if (employmentInfo.spouse_pay_period == "") {
    //       setEmploymentInfoError((pre) => ({
    //         ...pre,
    //         spouse_pay_period: "Required",
    //       }));
    //     }
    //   }
    // } else if (activeTab == "otherFinancialInfo") {
    //   let validateKeys = getRequiredKeys(
    //     Configures?.other_finanical_information,
    //     otherFinanceInfo
    //   );
    //   const Check = checkValidations(validateKeys);
    //   if (
    //     !Check.error ||
    //     Check.error ==
    //       "Bankcrupty_protection_yes_date_of_filing,Bankcrupty_protection_yes_date_of_discharge,Bankcrupty_protection_yes_petition_no,Bankcrupty_protection_yes_location is required!" ||
    //     Check.error ==
    //       "Law_suit_yes_plaintiff_defendent,Law_suit_yes_location_of_filing,Law_suit_yes_case_no,Law_suit_yes_subject_of_suit is required!"
    //   ) {
    //     setActiveTab(tab);
    //   } else {
    //     if (otherFinanceInfo.are_you_currently_a_party_to_law_suit == "") {
    //       setOtherFinanceInfoError((pre) => ({
    //         ...pre,
    //         are_you_currently_a_party_to_law_suit: "Required",
    //       }));
    //     }
    //     if (
    //       otherFinanceInfo.are_you_currently_a_party_to_law_suit == "Yes" &&
    //       otherFinanceInfo.law_suit_yes_plaintiff_defendent == ""
    //     ) {
    //       setOtherFinanceInfoError((pre) => ({
    //         ...pre,
    //         law_suit_yes_plaintiff_defendent: "Required",
    //       }));
    //     }
    //     if (
    //       otherFinanceInfo.are_you_currently_a_party_to_law_suit == "Yes" &&
    //       otherFinanceInfo.law_suit_yes_location_of_filing == ""
    //     ) {
    //       setOtherFinanceInfoError((pre) => ({
    //         ...pre,
    //         law_suit_yes_location_of_filing: "Required",
    //       }));
    //     }
    //     if (
    //       otherFinanceInfo.are_you_currently_a_party_to_law_suit == "Yes" &&
    //       otherFinanceInfo.law_suit_yes_case_no == ""
    //     ) {
    //       setOtherFinanceInfoError((pre) => ({
    //         ...pre,
    //         law_suit_yes_case_no: "Required",
    //       }));
    //     }
    //     if (
    //       otherFinanceInfo.are_you_currently_a_party_to_law_suit == "Yes" &&
    //       otherFinanceInfo.law_suit_yes_subject_of_suit == ""
    //     ) {
    //       setOtherFinanceInfoError((pre) => ({
    //         ...pre,
    //         law_suit_yes_subject_of_suit: "Required",
    //       }));
    //     }
    //     if (
    //       otherFinanceInfo.have_you_ever_filed_for_bankcrupty_protection == ""
    //     ) {
    //       setOtherFinanceInfoError((pre) => ({
    //         ...pre,
    //         have_you_ever_filed_for_bankcrupty_protection: "Required",
    //       }));
    //     }
    //     if (
    //       otherFinanceInfo.have_you_ever_filed_for_bankcrupty_protection ==
    //         "Yes" &&
    //       otherFinanceInfo.bankcrupty_protection_yes_date_of_filing == ""
    //     ) {
    //       setOtherFinanceInfoError((pre) => ({
    //         ...pre,
    //         bankcrupty_protection_yes_date_of_filing: "Required",
    //       }));
    //     }
    //     if (
    //       otherFinanceInfo.have_you_ever_filed_for_bankcrupty_protection ==
    //         "Yes" &&
    //       otherFinanceInfo.bankcrupty_protection_yes_date_of_discharge == ""
    //     ) {
    //       setOtherFinanceInfoError((pre) => ({
    //         ...pre,
    //         bankcrupty_protection_yes_date_of_discharge: "Required",
    //       }));
    //     }
    //     if (
    //       otherFinanceInfo.have_you_ever_filed_for_bankcrupty_protection ==
    //         "Yes" &&
    //       otherFinanceInfo.bankcrupty_protection_yes_petition_no == ""
    //     ) {
    //       setOtherFinanceInfoError((pre) => ({
    //         ...pre,
    //         bankcrupty_protection_yes_petition_no: "Required",
    //       }));
    //     }
    //     if (
    //       otherFinanceInfo.have_you_ever_filed_for_bankcrupty_protection ==
    //         "Yes" &&
    //       otherFinanceInfo.bankcrupty_protection_yes_location == ""
    //     ) {
    //       setOtherFinanceInfoError((pre) => ({
    //         ...pre,
    //         bankcrupty_protection_yes_location: "Required",
    //       }));
    //     }
    //     if (otherFinanceInfo.increase_decrease_in_income == "") {
    //       setOtherFinanceInfoError((pre) => ({
    //         ...pre,
    //         increase_decrease_in_income: "Required",
    //       }));
    //     }
    //     if (
    //       otherFinanceInfo.beneficiary_to_any_trust_estate_life_insurance == ""
    //     ) {
    //       setOtherFinanceInfoError((pre) => ({
    //         ...pre,
    //         beneficiary_to_any_trust_estate_life_insurance: "Required",
    //       }));
    //     }
    //   }
    // } else if (activeTab == "personalAssets") {
    //   let validateKeys = getRequiredKeys(
    //     Configures?.personal_assets,
    //     personalAssets
    //   );
    //   const Check = checkValidations(validateKeys);
    //   if (!Check.error) {
    //     setActiveTab(tab);
    //   } else {
    //     if (personalAssets.cash_on_hand == "") {
    //       setPersonalAssetsError((pre) => ({
    //         ...pre,
    //         cash_on_hand: "Required",
    //       }));
    //     }
    //     if (personalAssets.personal_fullname == "") {
    //       setPersonalAssetsError((pre) => ({
    //         ...pre,
    //         personal_fullname: "Required",
    //       }));
    //     }
    //     if (personalAssets.personal_address_of_bank == "") {
    //       setPersonalAssetsError((pre) => ({
    //         ...pre,
    //         personal_address_of_bank: "Required",
    //       }));
    //     }
    //     if (personalAssets.personal_account_type == "") {
    //       setPersonalAssetsError((pre) => ({
    //         ...pre,
    //         personal_account_type: "Required",
    //       }));
    //     }
    //     if (personalAssets.personal_account_number == "") {
    //       setPersonalAssetsError((pre) => ({
    //         ...pre,
    //         personal_account_number: "Required",
    //       }));
    //     }
    //     if (personalAssets.personal_account_balance == "") {
    //       setPersonalAssetsError((pre) => ({
    //         ...pre,
    //         personal_account_balance: "Required",
    //       }));
    //     }
    //     if (personalAssets.additional_fullname == "") {
    //       setPersonalAssetsError((pre) => ({
    //         ...pre,
    //         additional_fullname: "Required",
    //       }));
    //     }
    //     if (personalAssets.additional_address_of_bank == "") {
    //       setPersonalAssetsError((pre) => ({
    //         ...pre,
    //         additional_address_of_bank: "Required",
    //       }));
    //     }
    //     if (personalAssets.additional_account_type == "") {
    //       setPersonalAssetsError((pre) => ({
    //         ...pre,
    //         additional_account_type: "Required",
    //       }));
    //     }
    //     if (personalAssets.additional_account_number == "") {
    //       setPersonalAssetsError((pre) => ({
    //         ...pre,
    //         additional_account_number: "Required",
    //       }));
    //     }
    //     if (personalAssets.additional_account_balance == "") {
    //       setPersonalAssetsError((pre) => ({
    //         ...pre,
    //         additional_account_balance: "Required",
    //       }));
    //     }
    //     if (personalAssets.investment_type == "") {
    //       setPersonalAssetsError((pre) => ({
    //         ...pre,
    //         investment_type: "Required",
    //       }));
    //     }
    //     if (personalAssets.investment_fullname == "") {
    //       setPersonalAssetsError((pre) => ({
    //         ...pre,
    //         investment_fullname: "Required",
    //       }));
    //     }
    //     if (personalAssets.investment_address_of_institution == "") {
    //       setPersonalAssetsError((pre) => ({
    //         ...pre,
    //         investment_address_of_institution: "Required",
    //       }));
    //     }

    //     if (personalAssets.investment_account_number == "") {
    //       setPersonalAssetsError((pre) => ({
    //         ...pre,
    //         investment_account_number: "Required",
    //       }));
    //     }
    //     if (personalAssets.investment_account_balance == "") {
    //       setPersonalAssetsError((pre) => ({
    //         ...pre,
    //         investment_account_balance: "Required",
    //       }));
    //     }
    //     if (personalAssets.whole_life_insurance == "") {
    //       setPersonalAssetsError((pre) => ({
    //         ...pre,
    //         whole_life_insurance: "Required",
    //       }));
    //     }
    //     if (personalAssets.credit_fullname == "") {
    //       setPersonalAssetsError((pre) => ({
    //         ...pre,
    //         credit_fullname: "Required",
    //       }));
    //     }
    //     if (personalAssets.credit_address_of_bank == "") {
    //       setPersonalAssetsError((pre) => ({
    //         ...pre,
    //         credit_address_of_bank: "Required",
    //       }));
    //     }
    //     if (personalAssets.credit_account_type == "") {
    //       setPersonalAssetsError((pre) => ({
    //         ...pre,
    //         credit_account_type: "Required",
    //       }));
    //     }
    //     if (personalAssets.credit_account_number == "") {
    //       setPersonalAssetsError((pre) => ({
    //         ...pre,
    //         credit_account_number: "Required",
    //       }));
    //     }
    //     if (personalAssets.credit_account_balance == "") {
    //       setPersonalAssetsError((pre) => ({
    //         ...pre,
    //         credit_account_balance: "Required",
    //       }));
    //     }
    //   }
    // } else if (activeTab == "realPropertyOwned") {
    //   let validateKeys = getRequiredKeys(
    //     Configures?.real_property_owned,
    //     realPropertyOwned
    //   );
    //   const Check = checkValidations(validateKeys);
    //   if (!Check.error) {
    //     setActiveTab(tab);
    //   } else {
    //     if (realPropertyOwned.property_description == "") {
    //       setRealPropertyOwnedError((pre) => ({
    //         ...pre,
    //         property_description: "Required",
    //       }));
    //     }
    //     if (realPropertyOwned.location == "") {
    //       setRealPropertyOwnedError((pre) => ({
    //         ...pre,
    //         location: "Required",
    //       }));
    //     }
    //     if (realPropertyOwned.current_fair_market_value == "") {
    //       setRealPropertyOwnedError((pre) => ({
    //         ...pre,
    //         current_fair_market_value: "Required",
    //       }));
    //     }
    //     if (realPropertyOwned.loan_balance == "") {
    //       setRealPropertyOwnedError((pre) => ({
    //         ...pre,
    //         loan_balance: "Required",
    //       }));
    //     }
    //     if (realPropertyOwned.amount_of_monthly_payment == "") {
    //       setRealPropertyOwnedError((pre) => ({
    //         ...pre,
    //         amount_of_monthly_payment: "Required",
    //       }));
    //     }
    //     if (realPropertyOwned.amount_of_your_equity == "") {
    //       setRealPropertyOwnedError((pre) => ({
    //         ...pre,
    //         amount_of_your_equity: "Required",
    //       }));
    //     }
    //     if (realPropertyOwned.name_of_lender == "") {
    //       setRealPropertyOwnedError((pre) => ({
    //         ...pre,
    //         name_of_lender: "Required",
    //       }));
    //     }
    //     if (realPropertyOwned.address == "") {
    //       setRealPropertyOwnedError((pre) => ({
    //         ...pre,
    //         address: "Required",
    //       }));
    //     }
    //     if (realPropertyOwned.account_no == "") {
    //       setRealPropertyOwnedError((pre) => ({
    //         ...pre,
    //         account_no: "Required",
    //       }));
    //     }
    //   }
    // } else if (activeTab == "vehicles") {
    //   let validateKeys = getRequiredKeys(Configures?.vehicles, vehicles);

    //   const Check = checkValidations(validateKeys);
    //   if (
    //     !Check.error &&
    //     vehiclesError.phone_of_lender_lessor !== "Invalid Number" &&
    //     vehiclesError.another_phone_of_lender_lessor !== "Invalid Number"
    //   ) {
    //     setActiveTab(tab);
    //   } else {
    //     if (vehicles.year == "") {
    //       setVehiclesError((pre) => ({
    //         ...pre,
    //         year: "Required",
    //       }));
    //     }
    //     if (vehicles.make == "") {
    //       setVehiclesError((pre) => ({
    //         ...pre,
    //         make: "Required",
    //       }));
    //     }
    //     if (vehicles.model == "") {
    //       setVehiclesError((pre) => ({
    //         ...pre,
    //         model: "Required",
    //       }));
    //     }
    //     if (vehicles.mileage == "") {
    //       setVehiclesError((pre) => ({
    //         ...pre,
    //         mileage: "Required",
    //       }));
    //     }
    //     if (vehicles.purchase_lease_date == "") {
    //       setVehiclesError((pre) => ({
    //         ...pre,
    //         purchase_lease_date: "Required",
    //       }));
    //     }
    //     if (vehicles.cutrrent_market_value == "") {
    //       setVehiclesError((pre) => ({
    //         ...pre,
    //         cutrrent_market_value: "Required",
    //       }));
    //     }
    //     if (vehicles.amount_owned == "") {
    //       setVehiclesError((pre) => ({
    //         ...pre,
    //         amount_owned: "Required",
    //       }));
    //     }
    //     if (vehicles.monthly_payment == "") {
    //       setVehiclesError((pre) => ({
    //         ...pre,
    //         monthly_payment: "Required",
    //       }));
    //     }
    //     if (vehicles.name == "") {
    //       setVehiclesError((pre) => ({
    //         ...pre,
    //         name: "Required",
    //       }));
    //     }

    //     if (vehicles.address == "") {
    //       setVehiclesError((pre) => ({
    //         ...pre,
    //         address: "Required",
    //       }));
    //     }
    //     if (vehicles.phone_of_lender_lessor == "") {
    //       setVehiclesError((pre) => ({
    //         ...pre,
    //         phone_of_lender_lessor: "Required",
    //       }));
    //     }
    //     if (vehicles.another_year == "") {
    //       setVehiclesError((pre) => ({
    //         ...pre,
    //         another_year: "Required",
    //       }));
    //     }
    //     if (vehicles.another_make == "") {
    //       setVehiclesError((pre) => ({
    //         ...pre,
    //         another_make: "Required",
    //       }));
    //     }
    //     if (vehicles.another_model == "") {
    //       setVehiclesError((pre) => ({
    //         ...pre,
    //         another_model: "Required",
    //       }));
    //     }
    //     if (vehicles.another_mileage == "") {
    //       setVehiclesError((pre) => ({
    //         ...pre,
    //         another_mileage: "Required",
    //       }));
    //     }
    //     if (vehicles.another_purchase_lease_date == "") {
    //       setVehiclesError((pre) => ({
    //         ...pre,
    //         another_purchase_lease_date: "Required",
    //       }));
    //     }
    //     if (vehicles.another_cutrrent_market_value == "") {
    //       setVehiclesError((pre) => ({
    //         ...pre,
    //         another_cutrrent_market_value: "Required",
    //       }));
    //     }
    //     if (vehicles.another_amount_owned == "") {
    //       setVehiclesError((pre) => ({
    //         ...pre,
    //         another_amount_owned: "Required",
    //       }));
    //     }
    //     if (vehicles.another_monthly_payment == "") {
    //       setVehiclesError((pre) => ({
    //         ...pre,
    //         another_monthly_payment: "Required",
    //       }));
    //     }
    //     if (vehicles.another_name == "") {
    //       setVehiclesError((pre) => ({
    //         ...pre,
    //         another_name: "Required",
    //       }));
    //     }
    //     if (vehicles.another_address == "") {
    //       setVehiclesError((pre) => ({
    //         ...pre,
    //         another_address: "Required",
    //       }));
    //     }
    //     if (vehicles.another_phone_of_lender_lessor == "") {
    //       setVehiclesError((pre) => ({
    //         ...pre,
    //         another_phone_of_lender_lessor: "Required",
    //       }));
    //     }
    //   }
    // } else if (activeTab == "assets") {
    //   let validateKeys = getRequiredKeys(Configures?.assets, assets);
    //   const Check = checkValidations(validateKeys);
    //   if (!Check.error) {
    //     setActiveTab(tab);
    //   } else {
    //     if (assets.furniture_location == "") {
    //       setAssetsError((pre) => ({
    //         ...pre,
    //         furniture_location: "Required",
    //       }));
    //     }
    //     if (assets.furniture_value == "") {
    //       setAssetsError((pre) => ({
    //         ...pre,
    //         furniture_value: "Required",
    //       }));
    //     }
    //     if (assets.furniture_loanbalance == "") {
    //       setAssetsError((pre) => ({
    //         ...pre,
    //         furniture_loanbalance: "Required",
    //       }));
    //     }

    //     if (assets.personal_location == "") {
    //       setAssetsError((pre) => ({
    //         ...pre,
    //         personal_location: "Required",
    //       }));
    //     }
    //     if (assets.personal_value == "") {
    //       setAssetsError((pre) => ({
    //         ...pre,
    //         personal_value: "Required",
    //       }));
    //     }
    //     if (assets.personal_loanbalance == "") {
    //       setAssetsError((pre) => ({
    //         ...pre,
    //         personal_loanbalance: "Required",
    //       }));
    //     }

    //     if (assets.artwork_location == "") {
    //       setAssetsError((pre) => ({
    //         ...pre,
    //         artwork_location: "Required",
    //       }));
    //     }
    //     if (assets.artwork_value == "") {
    //       setAssetsError((pre) => ({
    //         ...pre,
    //         artwork_value: "Required",
    //       }));
    //     }
    //     if (assets.artwork_loanbalance == "") {
    //       setAssetsError((pre) => ({
    //         ...pre,
    //         artwork_loanbalance: "Required",
    //       }));
    //     }

    //     if (assets.jewellery_location == "") {
    //       setAssetsError((pre) => ({
    //         ...pre,
    //         jewellery_location: "Required",
    //       }));
    //     }
    //     if (assets.jewellery_value == "") {
    //       setAssetsError((pre) => ({
    //         ...pre,
    //         jewellery_value: "Required",
    //       }));
    //     }
    //     if (assets.jewellery_loanbalance == "") {
    //       setAssetsError((pre) => ({
    //         ...pre,
    //         jewellery_loanbalance: "Required",
    //       }));
    //     }

    //     if (assets.collection_location == "") {
    //       setAssetsError((pre) => ({
    //         ...pre,
    //         collection_location: "Required",
    //       }));
    //     }
    //     if (assets.collection_value == "") {
    //       setAssetsError((pre) => ({
    //         ...pre,
    //         collection_value: "Required",
    //       }));
    //     }
    //     if (assets.collection_loanbalance == "") {
    //       setAssetsError((pre) => ({
    //         ...pre,
    //         collection_loanbalance: "Required",
    //       }));
    //     }

    //     if (assets.guns_location == "") {
    //       setAssetsError((pre) => ({
    //         ...pre,
    //         guns_location: "Required",
    //       }));
    //     }
    //     if (assets.guns_value == "") {
    //       setAssetsError((pre) => ({
    //         ...pre,
    //         guns_value: "Required",
    //       }));
    //     }
    //     if (assets.guns_loanbalance == "") {
    //       setAssetsError((pre) => ({
    //         ...pre,
    //         guns_loanbalance: "Required",
    //       }));
    //     }

    //     if (assets.coins_location == "") {
    //       setAssetsError((pre) => ({
    //         ...pre,
    //         coins_location: "Required",
    //       }));
    //     }
    //     if (assets.coins_value == "") {
    //       setAssetsError((pre) => ({
    //         ...pre,
    //         coins_value: "Required",
    //       }));
    //     }
    //     if (assets.coins_loanbalance == "") {
    //       setAssetsError((pre) => ({
    //         ...pre,
    //         coins_loanbalance: "Required",
    //       }));
    //     }

    //     if (assets.antiques_location == "") {
    //       setAssetsError((pre) => ({
    //         ...pre,
    //         antiques_location: "Required",
    //       }));
    //     }
    //     if (assets.antiques_value == "") {
    //       setAssetsError((pre) => ({
    //         ...pre,
    //         antiques_value: "Required",
    //       }));
    //     }
    //     if (assets.antiques_loanbalance == "") {
    //       setAssetsError((pre) => ({
    //         ...pre,
    //         antiques_loanbalance: "Required",
    //       }));
    //     }
    //   }
    // } else if (activeTab == "incomeExpenseStatement") {
    //   const Check = checkValidations(incomeExpenseState);
    //   if (!Check.error) {
    //     setActiveTab(tab);
    //   } else {
    //     if (incomeExpenseState.gross_you == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         gross_you: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.gross_spouse_partner == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         gross_spouse_partner: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.rent_you == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         rent_you: "Required",
    //       }));
    //     }

    //     if (incomeExpenseState.rent_spouse_partner == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         rent_spouse_partner: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.other_you == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         other_you: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.other_spouse_partner == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         other_spouse_partner: "Required",
    //       }));
    //     }

    //     if (incomeExpenseState.child_you == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         child_you: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.child_spouse_partner == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         child_spouse_partner: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.soc_you == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         soc_you: "Required",
    //       }));
    //     }

    //     if (incomeExpenseState.soc_spouse_partner == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         soc_spouse_partner: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.pension_you == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         pension_you: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.pension_spouse_partner == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         pension_spouse_partner: "Required",
    //       }));
    //     }

    //     if (incomeExpenseState.misc_you == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         misc_you: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.misc_spouse_partner == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         misc_spouse_partner: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.primary_home_mortgage == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         primary_home_mortgage: "Required",
    //       }));
    //     }

    //     if (incomeExpenseState.primary_home_tax_ins == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         primary_home_tax_ins: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.any_other_mortgage == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         any_other_mortgage: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.any_other_property_tax_ins == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         any_other_property_tax_ins: "Required",
    //       }));
    //     }

    //     if (incomeExpenseState.credit_card_payments_total == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         credit_card_payments_total: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.any_other_revolving_debt == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         any_other_revolving_debt: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.installment_loan_payments == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         installment_loan_payments: "Required",
    //       }));
    //     }

    //     if (incomeExpenseState.dependent_child_care == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         dependent_child_care: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.personal_loans == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         personal_loans: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.income_tax_from_paycheck == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         income_tax_from_paycheck: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.fourZeroOneKLoans == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         fourZeroOneKLoans: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.child_support == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         child_support: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.alimony == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         alimony: "Required",
    //       }));
    //     }

    //     if (incomeExpenseState.irs_liens == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         irs_liens: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.tuition == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         tuition: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.student_loans == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         student_loans: "Required",
    //       }));
    //     }

    //     if (incomeExpenseState.gas == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         gas: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.electric == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         electric: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.water == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         water: "Required",
    //       }));
    //     }

    //     if (incomeExpenseState.trash_sewer == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         trash_sewer: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.telephone == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         telephone: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.cell_phone == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         cell_phone: "Required",
    //       }));
    //     }

    //     if (incomeExpenseState.internet == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         internet: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.cable_satellite == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         cable_satellite: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.vehicle_insurance == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         vehicle_insurance: "Required",
    //       }));
    //     }

    //     if (incomeExpenseState.maintenance == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         maintenance: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.food == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         food: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.transportation == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         transportation: "Required",
    //       }));
    //     }

    //     if (incomeExpenseState.gardening_landscaping == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         gardening_landscaping: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.housecleaning == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         housecleaning: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.other == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         other: "Required",
    //       }));
    //     }

    //     if (incomeExpenseState.insurance_medical_amount == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         insurance_medical_amount: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.insurance_medical_paycheck == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         insurance_medical_paycheck: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.insurance_dental_amount == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         insurance_dental_amount: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.insurance_dental_paycheck == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         insurance_dental_paycheck: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.insurance_life_amount == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         insurance_life_amount: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.insurance_life_paycheck == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         insurance_life_paycheck: "Required",
    //       }));
    //     }

    //     if (incomeExpenseState.insurance_vision_amount == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         insurance_vision_amount: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.insurance_vision_paycheck == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         insurance_vision_paycheck: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.insurance_disability_amount == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         insurance_disability_amount: "Required",
    //       }));
    //     }

    //     if (incomeExpenseState.insurance_disability_paycheck == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         insurance_disability_paycheck: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.insurance_surrender_amount == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         insurance_surrender_amount: "Required",
    //       }));
    //     }
    //     if (incomeExpenseState.insurance_surrender_paycheck == "") {
    //       setIncomeExpenseStateError((pre) => ({
    //         ...pre,
    //         insurance_surrender_paycheck: "Required",
    //       }));
    //     }
    //   }
    // }
  };

  const handleSubmitBlur = () => {
    let body = {
      personal_information: personalInfo,
      legal_fields: legalInfo,
      bankruptcy_field: bankruptcyInfo,
      employment_information: employmentInfo,
      other_finanical_information: otherFinanceInfo,
      personal_assets: personalAssets,
      real_property_owned: realPropertyOwned,
      vehicles: vehicles,
      another_vehicle_description: {},
      assets: assets,
      income: incomeExpenseState,
      expenses: {},
      expenses_insurance: {},
    };
    dispatch(postFinancial({ user_id: Number(id), ...body }, id));
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const callBack = (res) => {
      if (res.data) {
        toast.success("Form Submitted Successfully!");
        navigate("/client/financial-form");
        setActiveTab("personalInfo");
        // window.location.reload();
      }
    };
    let body = {
      personal_information: personalInfo,
      employment_information: employmentInfo,
      other_finanical_information: otherFinanceInfo,
      personal_assets: personalAssets,
      real_property_owned: realPropertyOwned,
      vehicles: vehicles,
      another_vehicle_description: {},
      assets: assets,
      income: incomeExpenseState,
      expenses: {},
      expenses_insurance: {},
    };
    dispatch(postFinancial({ user_id: Number(id), ...body }, id, callBack));
  };

  const handleBackBtn = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (allData) {
      setPersonalInfo(allData?.personal_information);
      setLegalInfo(allData?.legal_fields);
      setBankruptcyInfo(allData?.bankruptcy_field);
      setEmploymentInfo(allData?.employment_information);
      setOtherFinanceInfo(allData?.other_finanical_information);
      setPersonalAssets(allData?.personal_assets);
      setRealPropertyOwned(allData?.real_property_owned);
      setVehicles(allData?.vehicles);
      setAssets(allData?.assets);
      setIncomeExpenseState(allData?.income);
    }
  }, [allData]);

  return (
    <>
      {loading && <Loading className={"cstmLoader"} />}
      <section className="dashboard profile-main py-2">
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="top mb-2">
                <Breadcrumb>
                  <Breadcrumb.Item>Financial Questionaire</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </Col>
            <Col lg="12" className="my-2">
              <Tab.Container id="left-tabs-example">
                <Nav
                  variant="pills"
                  className="bg-white mb-3 commonTabs commonfinanceTabs viewprofile"
                >
                  <Nav.Item>
                    <Nav.Link
                      className={`bg-transparent border-0 py-3 position-relative px-3 ${
                        isComplete.personalInfo ? "form-undone" : "form-done"
                      } ${activeTab == "personalInfo" && "active"}`}
                      onClick={() => handleTabChange("personalInfo")}
                    >
                      Personal Information
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className={`bg-transparent border-0 py-3 position-relative px-3 ${
                        isComplete.empInfo ? "form-undone" : "form-done"
                      } ${activeTab == "empInfo" && "active"}`}
                      onClick={() => handleTabChange("empInfo")}
                    >
                      Employment Information
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className={`bg-transparent border-0 py-3 position-relative px-3 ${
                        isComplete.otherFinanceInfo
                          ? "form-undone"
                          : "form-done"
                      } ${activeTab == "otherFinancialInfo" && "active"}`}
                      onClick={() => handleTabChange("otherFinancialInfo")}
                    >
                      Other Financial Information
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className={`bg-transparent border-0 py-3 position-relative px-3 ${
                        isComplete.personalAssets ? "form-undone" : "form-done"
                      } ${activeTab == "personalAssets" && "active"}`}
                      onClick={() => handleTabChange("personalAssets")}
                    >
                      Personal Assets
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className={`bg-transparent border-0 py-3 position-relative px-3 ${
                        isComplete.realPropertyOwned
                          ? "form-undone"
                          : "form-done"
                      } ${activeTab == "realPropertyOwned" && "active"}`}
                      onClick={() => handleTabChange("realPropertyOwned")}
                    >
                      Real Property Owned
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className={`bg-transparent border-0 py-3 position-relative px-3 ${
                        isComplete.vehicles ? "form-undone" : "form-done"
                      } ${activeTab == "vehicles" && "active"}`}
                      onClick={() => handleTabChange("vehicles")}
                    >
                      Vehicles
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className={`bg-transparent border-0 py-3 position-relative px-3 ${
                        isComplete.assets ? "form-undone" : "form-done"
                      } ${activeTab == "assets" && "active"}`}
                      onClick={() => handleTabChange("assets")}
                    >
                      Assets
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className={`bg-transparent border-0 py-3 position-relative px-3 ${
                        isComplete.incomeExpense ? "form-undone" : "form-done"
                      } ${activeTab == "incomeExpenseStatement" && "active"}`}
                      onClick={() => handleTabChange("incomeExpenseStatement")}
                    >
                      Income and Expense Statement
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      className={`bg-transparent border-0 py-3 position-relative px-3 ${
                        isComplete.legal ? "form-undone" : "form-done"
                      } ${activeTab == "legal" && "active"}`}
                      onClick={() => handleTabChange("legal")}
                    >
                      Legal
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className={`bg-transparent border-0 py-3 position-relative px-3 ${
                        isComplete.bankruptcy ? "form-undone" : "form-done"
                      } ${activeTab == "bankruptcy" && "active"}`}
                      onClick={() => handleTabChange("bankruptcy")}
                    >
                      Bankruptcy
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane
                    className={activeTab == "personalInfo" ? "active show" : ""}
                  >
                    <PersonalInfo
                      personalInfo={personalInfo}
                      setPersonalInfo={setPersonalInfo}
                      handleTabChange={handleTabChange}
                      errors={personalInfoErrors}
                      setError={setPersonalInfoError}
                      handleSubmitBlur={handleSubmitBlur}
                      Configures={Configures?.personal_information}
                      extraFields={FieldData?.personal_information_fields}
                    />
                  </Tab.Pane>

                  <Tab.Pane
                    className={activeTab == "legal" ? "active show" : ""}
                  >
                    <LegalInfo
                      legalInfo={legalInfo}
                      setLegalInfo={setLegalInfo}
                      errors={legalInfoErrors}
                      setError={setLegalInfoError}
                      Configures={Configures?.legal_fields}
                      extraFields={FieldData?.legal_fields}
                      handleTabChange={handleTabChange}
                      handleSubmitBlur={handleSubmitBlur}
                    />
                  </Tab.Pane>
                  <Tab.Pane
                    className={activeTab == "bankruptcy" ? "active show" : ""}
                  >
                    <BankruptcyInfo
                      bankruptcyInfo={bankruptcyInfo}
                      setBankruptcyInfo={setBankruptcyInfo}
                      errors={bankruptcyInfoErrors}
                      setError={setBankruptcyInfoError}
                      Configures={Configures?.bankruptcy_field}
                      extraFields={FieldData?.bankruptcy_field}
                      handleTabChange={handleTabChange}
                      handleSubmitBlur={handleSubmitBlur}
                    />
                  </Tab.Pane>

                  <Tab.Pane
                    className={activeTab == "empInfo" ? "active show" : ""}
                  >
                    <EmploymentInfo
                      employmentInfo={employmentInfo}
                      setEmploymentInfo={setEmploymentInfo}
                      errors={employmentInfoError}
                      setError={setEmploymentInfoError}
                      handleTabChange={handleTabChange}
                      handleSubmitBlur={handleSubmitBlur}
                      handleBackBtn={handleBackBtn}
                      Configures={Configures?.employment_information}
                      extraFields={FieldData?.employment_information_fields}
                    />
                  </Tab.Pane>
                  <Tab.Pane
                    className={
                      activeTab == "otherFinancialInfo" ? "active show" : ""
                    }
                  >
                    <OtherFinancialInfo
                      otherFinanceInfo={otherFinanceInfo}
                      setOtherFinanceInfo={setOtherFinanceInfo}
                      errors={otherFinanceInfoError}
                      setError={setOtherFinanceInfoError}
                      handleTabChange={handleTabChange}
                      handleSubmitBlur={handleSubmitBlur}
                      handleBackBtn={handleBackBtn}
                      Configures={Configures?.other_finanical_information}
                      extraFields={
                        FieldData?.other_finanical_information_fields
                      }
                    />
                  </Tab.Pane>
                  <Tab.Pane
                    className={
                      activeTab == "personalAssets" ? "active show" : ""
                    }
                  >
                    <PersonalAssets
                      personalAssets={personalAssets}
                      setPersonalAssets={setPersonalAssets}
                      errors={personalAssetsError}
                      setError={setPersonalAssetsError}
                      handleTabChange={handleTabChange}
                      handleSubmitBlur={handleSubmitBlur}
                      handleBackBtn={handleBackBtn}
                      Configures={Configures?.personal_assets}
                      extraFields={FieldData?.personal_assets_fields}
                    />
                  </Tab.Pane>
                  <Tab.Pane
                    className={
                      activeTab == "realPropertyOwned" ? "active show" : ""
                    }
                  >
                    <RealPropertyOwned
                      realPropertyOwned={realPropertyOwned}
                      setRealPropertyOwned={setRealPropertyOwned}
                      errors={realPropertyOwnedError}
                      setError={setRealPropertyOwnedError}
                      handleTabChange={handleTabChange}
                      handleSubmitBlur={handleSubmitBlur}
                      handleBackBtn={handleBackBtn}
                      Configures={Configures?.real_property_owned}
                      extraFields={FieldData?.real_property_owned_fields}
                    />
                  </Tab.Pane>
                  <Tab.Pane
                    className={activeTab == "vehicles" ? "active show" : ""}
                  >
                    <Vehicles
                      vehicles={vehicles}
                      setVehicles={setVehicles}
                      errors={vehiclesError}
                      setError={setVehiclesError}
                      handleTabChange={handleTabChange}
                      handleSubmitBlur={handleSubmitBlur}
                      handleBackBtn={handleBackBtn}
                      Configures={Configures?.vehicles}
                      extraFields={FieldData?.vehicles_fields}
                    />
                  </Tab.Pane>
                  <Tab.Pane
                    className={
                      activeTab == "incomeExpenseStatement" ? "active show" : ""
                    }
                  >
                    <IncomeExpenseStatement
                      incomeExpenseState={incomeExpenseState}
                      setIncomeExpenseState={setIncomeExpenseState}
                      errors={incomeExpenseStateError}
                      setError={setIncomeExpenseStateError}
                      handleTabChange={handleTabChange}
                      handleSubmitBlur={handleSubmitBlur}
                      handleBackBtn={handleBackBtn}
                      handleSubmitForm={handleSubmitForm}
                      ConfiguresIncome={Configures?.income}
                      extraFields={FieldData?.income_fields}
                    />
                  </Tab.Pane>
                  <Tab.Pane
                    className={activeTab == "assets" ? "active show" : ""}
                  >
                    <Assets
                      assets={assets}
                      setAssets={setAssets}
                      errors={assetsError}
                      setError={setAssetsError}
                      handleTabChange={handleTabChange}
                      handleSubmitBlur={handleSubmitBlur}
                      handleBackBtn={handleBackBtn}
                      Configures={Configures?.assets}
                      extraFields={FieldData?.assets_fields}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default FinancialForm;
