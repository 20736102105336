import * as CONST from "./actionTypes";

export const customerList = (payload, callBack) => {
  return {
    type: CONST.CUSTOMERS_LIST,
    payload: { ...payload },
    callBack,
  };
};

export const customerList_Success = (payload) => ({
  type: CONST.CUSTOMERS_LIST_SUCCESS,
  payload,
});

export const customerList_Fail = (payload) => ({
  type: CONST.CUSTOMERS_LIST_FAIL,
  payload,
});

/* >>>>>>>>>>>>>> EDIT CUSTOMER >>>>>>>>>>>>>>>*/
export const editCustomer = (payload, callBack) => {
  return {
    type: CONST.EDIT_CUSTOMER,
    payload: payload,
    callBack,
  };
};

export const editCustomer_Success = (payload) => ({
  type: CONST.EDIT_CUSTOMER_SUCCESS,
  payload,
});

export const editCustomer_Fail = (payload) => ({
  type: CONST.EDIT_CUSTOMER_FAIL,
  payload,
});

/* >>>>>>>>>>>>>> UPDATE USER >>>>>>>>>>>>>>>*/

export const updateUser = (payload, callBack) => {
  return {
    type: CONST.UPDATE_USER,
    payload: { ...payload },
    callBack,
  };
};

export const updateUser_Success = (payload) => ({
  type: CONST.UPDATE_USER_SUCCESS,
  payload,
});

export const updateUser_Fail = (payload) => ({
  type: CONST.UPDATE_USER_FAIL,
  payload,
});

/* >>>>>>>>>>>>>> ADD USER >>>>>>>>>>>>>>>*/

export const addUser = (payload, callBack) => {
  return {
    type: CONST.ADD_USER,
    payload: { ...payload },
    callBack,
  };
};

export const addUser_Success = (payload) => ({
  type: CONST.ADD_USER_SUCCESS,
  payload,
});

export const addUser_Fail = (payload) => ({
  type: CONST.ADD_USER_FAIL,
  payload,
});

/* >>>>>>>>>>>>>> BLOCK_UNBLOCK USER >>>>>>>>>>>>>>>*/

export const blockUnblockUser = (payload, callBack) => {
  return {
    type: CONST.BLOCK_UNBLOCK_USER,
    payload: { ...payload },
    callBack,
  };
};

export const blockUnblockUser_Success = (payload) => ({
  type: CONST.BLOCK_UNBLOCK_USER_SUCCESS,
  payload,
});

export const blockUnblockUser_Fail = (payload) => ({
  type: CONST.BLOCK_UNBLOCK_USER_FAIL,
  payload,
});

/* >>>>>>>>>>>>>> DELETE USER >>>>>>>>>>>>>>>*/

export const deleteUser = (payload, callBack) => {
  return {
    type: CONST.DELETE_USER,
    payload: { ...payload },
    callBack,
  };
};

export const deleteUser_Success = (payload) => ({
  type: CONST.DELETE_USER_SUCCESS,
  payload,
});

export const deleteUser_Fail = (payload) => ({
  type: CONST.DELETE_USER_FAIL,
  payload,
});

/* >>>>>>>>>>>>>> ADD TASKS >>>>>>>>>>>>>>>*/

export const addTasks = (payload, callBack) => {
  return {
    type: CONST.ADD_TASKS,
    payload: payload,
    callBack,
  };
};

export const addTasks_Success = (payload) => ({
  type: CONST.ADD_TASKS_SUCCESS,
  payload,
});

export const addTasks_Fail = (payload) => ({
  type: CONST.ADD_TASKS_FAIL,
  payload,
});

/* >>>>>>>>>>>>>> get task >>>>>>>>>>>>>>>*/

export const getTasksById = (payload, callBack) => {
  return {
    type: CONST.GET_TASKS_BY_ID,
    payload: payload,
    callBack,
  };
};

export const getTasksById_Success = (payload) => ({
  type: CONST.GET_TASKS_BY_ID_SUCCESS,
  payload,
});

export const getTasksById_Fail = (payload) => ({
  type: CONST.GET_TASKS_BY_ID_FAIL,
  payload,
});

/* >>>>>>>>>>>>>> update task >>>>>>>>>>>>>>>*/

export const updateTasks = (payload, callBack) => {
  return {
    type: CONST.UPDATE_TASKS,
    payload: payload,
    callBack,
  };
};

export const updateTasks_Success = (payload) => ({
  type: CONST.UPDATE_TASKS_SUCCESS,
  payload,
});

export const updateTasks_Fail = (payload) => ({
  type: CONST.UPDATE_TASKS_FAIL,
  payload,
});

/* >>>>>>>>>>>>>> SINGLE TASKS >>>>>>>>>>>>>>>*/

export const getSingleTasks = (payload, callBack) => {
  return {
    type: CONST.GET_SINGLE_TASKS,
    payload: payload,
    callBack,
  };
};

export const getSingleTasks_Success = (payload) => ({
  type: CONST.GET_SINGLE_TASKS_SUCCESS,
  payload,
});

export const getSingleTasks_Fail = (payload) => ({
  type: CONST.GET_SINGLE_TASKS_FAIL,
  payload,
});

/* >>>>>>>>>>>>>> DELETE TASKS >>>>>>>>>>>>>>>*/

export const deleteTasks = (payload, callBack) => {
  return {
    type: CONST.DELETE_TASKS,
    payload: payload,
    callBack,
  };
};

export const deleteTasks_Success = (payload) => ({
  type: CONST.DELETE_TASKS_SUCCESS,
  payload: payload,
});

export const deleteTasks_Fail = (payload) => ({
  type: CONST.DELETE_TASKS_FAIL,
  payload,
});

/* >>>>>>>>>>>>>> ADD NOTE >>>>>>>>>>>>>>>*/

export const addNote = (payload, callBack) => {
  return {
    type: CONST.ADD_NOTE,
    payload: payload,
    callBack,
  };
};

export const addNote_Success = (payload) => ({
  type: CONST.ADD_NOTE_SUCCESS,
  payload,
});

export const addNote_Fail = (payload) => ({
  type: CONST.ADD_NOTE_FAIL,
  payload,
});

/* >>>>>>>>>>>>>> get note >>>>>>>>>>>>>>>*/

export const getNoteById = (payload, callBack) => {
  return {
    type: CONST.GET_NOTE_BY_ID,
    payload: payload,
    callBack,
  };
};

export const getNoteById_Success = (payload) => ({
  type: CONST.GET_NOTE_BY_ID_SUCCESS,
  payload,
});

export const getNoteById_Fail = (payload) => ({
  type: CONST.GET_NOTE_BY_ID_FAIL,
  payload,
});

/* >>>>>>>>>>>>>> update note >>>>>>>>>>>>>>>*/

export const updateNote = (payload, callBack) => {
  return {
    type: CONST.UPDATE_NOTE,
    payload: payload,
    callBack,
  };
};

export const updateNote_Success = (payload) => ({
  type: CONST.UPDATE_NOTE_SUCCESS,
  payload,
});

export const updateNote_Fail = (payload) => ({
  type: CONST.UPDATE_NOTE_FAIL,
  payload,
});

/* >>>>>>>>>>>>>> SINGLE NOTE >>>>>>>>>>>>>>>*/

export const getSingleNote = (payload, callBack) => {
  return {
    type: CONST.GET_SINGLE_NOTE,
    payload: payload,
    callBack,
  };
};

export const getSingleNote_Success = (payload) => ({
  type: CONST.GET_SINGLE_NOTE_SUCCESS,
  payload,
});

export const getSingleNote_Fail = (payload) => ({
  type: CONST.GET_SINGLE_NOTE_FAIL,
  payload,
});

/* >>>>>>>>>>>>>> DELETE NOTE >>>>>>>>>>>>>>>*/

export const deleteNote = (payload, callBack) => {
  return {
    type: CONST.DELETE_NOTE,
    payload: payload,
    callBack,
  };
};

export const deleteNote_Success = (payload) => ({
  type: CONST.DELETE_NOTE_SUCCESS,
  payload: payload,
});

export const deleteNote_Fail = (payload) => ({
  type: CONST.DELETE_NOTE_FAIL,
  payload,
});
