import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Breadcrumb } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
// import { fileUpload, getProfile, getDocuments } from "../../store/actions";
import { isEmail, isEmpty } from "validator";
import { PASS_REGEX, Numeric } from "../../Services/URLS";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { MenuItem, Select } from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import moment from "moment";
import Loading from "../../Common/extra/loading";
import {
  fileUpload,
  forgotPassOtp,
  getUserProfile,
  updateUserData,
  userData,
} from "../../store/actions";
import { GET_YEARS_FN, checkValidations } from "../../Services/Helper";

var UsStates = [
  { code: "AL", name: "Alabama" },
  { code: "AK", name: "Alaska" },
  { code: "AZ", name: "Arizona" },
  { code: "AR", name: "Arkansas" },
  { code: "CA", name: "California" },
  { code: "CO", name: "Colorado" },
  { code: "CT", name: "Connecticut" },
  { code: "DE", name: "Delaware" },
  { code: "FL", name: "Florida" },
  { code: "GA", name: "Georgia" },
  { code: "HI", name: "Hawaii" },
  { code: "ID", name: "Idaho" },
  { code: "IL", name: "Illinois" },
  { code: "IN", name: "Indiana" },
  { code: "IA", name: "Iowa" },
  { code: "KS", name: "Kansas" },
  { code: "KY", name: "Kentucky" },
  { code: "LA", name: "Louisiana" },
  { code: "ME", name: "Maine" },
  { code: "MD", name: "Maryland" },
  { code: "MA", name: "Massachusetts" },
  { code: "MI", name: "Michigan" },
  { code: "MN", name: "Minnesota" },
  { code: "MS", name: "Mississippi" },
  { code: "MO", name: "Missouri" },
  { code: "MT", name: "Montana" },
  { code: "NE", name: "Nebraska" },
  { code: "NV", name: "Nevada" },
  { code: "NH", name: "New Hampshire" },
  { code: "NJ", name: "New Jersey" },
  { code: "NM", name: "New Mexico" },
  { code: "NY", name: "New York" },
  { code: "NC", name: "North Carolina" },
  { code: "ND", name: "North Dakota" },
  { code: "OH", name: "Ohio" },
  { code: "OK", name: "Oklahoma" },
  { code: "OR", name: "Oregon" },
  { code: "PA", name: "Pennsylvania" },
  { code: "RI", name: "Rhode Island" },
  { code: "SC", name: "South Carolina" },
  { code: "SD", name: "South Dakota" },
  { code: "TN", name: "Tennessee" },
  { code: "TX", name: "Texas" },
  { code: "UT", name: "Utah" },
  { code: "VT", name: "Vermont" },
  { code: "VA", name: "Virginia" },
  { code: "WA", name: "Washington" },
  { code: "WV", name: "West Virginia" },
  { code: "WI", name: "Wisconsin" },
  { code: "WY", name: "Wyoming" },
];

const MarryStatus = [
  {
    name: "single",
    id: "single",
  },
  {
    name: "married filing jointly",
    id: "married filing jointly",
  },
  {
    name: "married filing separately",
    id: "married filing separately",
  },
  {
    name: "head of household",
    id: "head of household",
  },
  {
    name: "qualifying widow(er)",
    id: "qualifying widow(er)",
  },
];

const Zones = [
  {
    name: "EST",
    id: "EST",
  },
  {
    name: "CST",
    id: "CST",
  },
  {
    name: "MST",
    id: "MST",
  },
  {
    name: "MDT",
    id: "MDT",
  },
  {
    name: "PST",
    id: "PST",
  },
  {
    name: "Alaska",
    id: "Alaska",
  },
  {
    name: "Hawai",
    id: "Hawai",
  },
];

const Languages = [
  {
    name: "English",
    id: "ENG",
  },
  {
    name: "Spanish",
    id: "ESP",
  },
];

const TaxAgnecy = [
  {
    name: "Federal",
    id: "FEDERAL",
  },
  {
    name: "State",
    id: "STATE",
  },
  {
    name: "Both",
    id: "BOTH",
  },
];

const TaxType = [
  {
    name: "Personal",
    id: "Personal",
  },
  {
    name: "Business",
    id: "Business",
  },
  {
    name: "Personal and Business",
    id: "Personal and Business",
  },
  {
    name: "Payrol",
    id: "Payrol",
  },
  {
    name: "Other",
    id: "Other",
  },
];

const TaxProblems = [
  {
    name: "Assets Seized",
    id: "ASSETS_SEIZED",
  },
  {
    name: "Bank Account Levy",
    id: "BANK_ACCOUNT_LEVY",
  },
  {
    name: "Can't Pay Unpaid Taxes",
    id: "CANT_PAY_UNPAID_TAXES",
  },
  {
    name: "Innocent Spouse",
    id: "INNOCENT_SPOUSE",
  },
  {
    name: "License Suspension Revocation",
    id: "License_Suspension_Revocation",
  },
  {
    name: "Lien Filed",
    id: "LIEN_FILED",
  },
  {
    name: "Passport Suspension Revocation",
    id: "Passport_Suspension_Revocation",
  },
  {
    name: "Received Audit Notice",
    id: "RECEIVED_AUDIT_NOTICE",
  },
  {
    name: "Unpaid Penalties and Interest",
    id: "UNPAID_PENALTIES_AND_INTEREST",
  },
  {
    name: "Wage Garnishment",
    id: "WAGE_GARNISHMENT",
  },
  {
    name: "Received IRS Letter",
    id: "RECEIVED_IRS_LETTER",
  },
  {
    name: "Issue Claiming Dependents",
    id: "ISSUE_CLAIMING_DEPENDENTS",
  },
  {
    name: "ID Theft",
    id: "ID_THEFT",
  },
  {
    name: "IRS Refund",
    id: "IRS_REFUND",
  },
  {
    name: "Other",
    id: "OTHER",
  },
];

const BusinessTypes = [
  {
    name: "Sole Proprietorship",
    id: "sole proprietorship",
  },
  {
    name: "Partnership",
    id: "partnership",
  },
  {
    name: "LLP",
    id: "llp",
  },
  {
    name: "LLC (Single)",
    id: "llc (single)",
  },
  {
    name: "LLC (Multiple)",
    id: "llc (multiple)",
  },
  {
    name: "S Corp",
    id: "s corp",
  },
  {
    name: "C Corp",
    id: "c corp",
  },
  {
    name: "Self Employed",
    id: "Self Employed",
  },
];

const Default = [
  {
    name: "Yes",
    id: "1",
  },
  {
    name: "No",
    id: "0",
  },
];

const ProfileForm = () => {
  const Years = GET_YEARS_FN();
  const currentDate = new Date();
  const minDate = new Date();
  minDate.setFullYear(currentDate.getFullYear() - 18);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [picLoading, setPicLoading] = useState(false);
  const [data, setData] = useState({
    userId: "",
    FirstName: "",
    LastName: "",
    Email: "",
    Address: "",
    AptNo: "",
    City: "",
    State: "",
    Zip: "",
    document_template: "",
    CellPhone: "",
    WorkPhone: "",
    HomePhone: "",
    DOB: moment(new Date()).format("YYYY-MM-DD"),
    SSN: "",
    Marital_Status: "",
    TAX_RELIEF_TAX_AGENCY: "",
    Notes: "",
    Language: "",
    TAX_RELIEF_TAX_TYPE: "",
    SourceName: "",
    BusinessType: "",
    BusinessName: "",
    EIN: "",
    SMSPermit: "",
    TaxProblem: "",
    profile_image: "",
    timezone: "",
    have_any_nonfiled_tax_returns: "",
    years_unfiled_returns: [],
    amount_owed_to_irs: "",
    amount_owed_to_state: "",
    pick_your_states: [],
  });

  const [errors, setError] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    Address: "",
    AptNo: "",
    City: "",
    State: "",
    Zip: "",
    document_template: "",
    CellPhone: "",
    WorkPhone: "",
    HomePhone: "",
    DOB: "",
    SSN: "",
    Marital_Status: "",
    TAX_RELIEF_TAX_AGENCY: "",
    Notes: "",
    Language: "",
    TAX_RELIEF_TAX_TYPE: "",
    SourceName: "",
    BusinessType: "",
    BusinessName: "",
    EIN: "",
    SMSPermit: "",
    TaxProblem: "",
    OpenerName: "",
    SETOfficerName: "",
    TaxPreparerName: "",
    DistributionId: "",
    StatusID: "",
    ProductID: "",
    UDF: "",
    disabled: true,
    profile_image: "",
    timezone: "",
    have_any_nonfiled_tax_returns: "",
    years_unfiled_returns: [],
    amount_owed_to_irs: "",
    amount_owed_to_state: "",
    pick_your_states: [],
  });
  const user = useSelector((s) => s?.login?.profile?.data);
  const userId = localStorage && localStorage.getItem("id");

  // useEffect(() => {
  //   if (userId) {
  //     dispatch(userData({ id: userId }));
  //   }
  // }, [userId]);

  useEffect(() => {
    let validate = checkValidations(data);
    if (
      validate.error ===
      "BusinessType,BusinessName,EIN,OpenerName,SETOfficerName,TaxPreparerName,DistributionId,StatusID,ProductID,UDF is required!"
    ) {
      setError((pre) => ({ ...pre, disabled: false }));
    } else if (
      validate.error ===
      "OpenerName,SETOfficerName,TaxPreparerName,DistributionId,StatusID,ProductID,UDF is required!"
    ) {
      setError((pre) => ({ ...pre, disabled: false }));
    } else if (
      validate.error ===
      "SSN,OpenerName,SETOfficerName,TaxPreparerName,DistributionId,StatusID,ProductID,UDF is required!"
    ) {
      setError((pre) => ({ ...pre, disabled: false }));
    } else if (
      validate.error ===
      "DOB,SSN,OpenerName,SETOfficerName,TaxPreparerName,DistributionId,StatusID,ProductID,UDF is required!"
    ) {
      setError((pre) => ({ ...pre, disabled: false }));
    } else if (
      validate.error ===
      "DOB,OpenerName,SETOfficerName,TaxPreparerName,DistributionId,StatusID,ProductID,UDF is required!"
    ) {
      setError((pre) => ({ ...pre, disabled: false }));
    } else if (validate.error === "UDF is required!") {
      setError((pre) => ({ ...pre, disabled: false }));
    } else if (validate.error === "UDF is required!") {
      setError((pre) => ({ ...pre, disabled: false }));
    } else {
      setError((pre) => ({ ...pre, disabled: true }));
    }
  }, [data]);

  useEffect(() => {
    if (user?.amtaxsettlement_data) {
      setData((pre) => ({
        ...pre,
        userId: user?.id || "",
        FirstName: user?.amtaxsettlement_data?.FirstName || "",
        LastName: user?.amtaxsettlement_data?.LastName || "",
        Email: user?.amtaxsettlement_data?.Email || "",
        Address: user?.amtaxsettlement_data?.Address || "",
        AptNo: user?.amtaxsettlement_data?.AptNo || "",
        City: user?.amtaxsettlement_data?.City || "",
        State: user?.amtaxsettlement_data?.State || "",
        Zip: user?.amtaxsettlement_data?.Zip || "",
        document_template: user?.document_template || "",
        CellPhone:
          user?.amtaxsettlement_data?.CellPhone?.replace(/[\D]/g, "") || "",
        WorkPhone:
          user?.amtaxsettlement_data?.WorkPhone?.replace(/[\D]/g, "") || "",
        HomePhone:
          user?.amtaxsettlement_data?.HomePhone?.replace(/[\D]/g, "") || "",
        DOB: moment(user?.amtaxsettlement_data?.DOB).format("YYYY-MM-DD") || "",
        SSN:
          "*****" +
            user?.amtaxsettlement_data?.SSN?.replace(/[\D]/g, "").substring(
              5
            ) || "",
        Marital_Status: user?.amtaxsettlement_data?.Marital_Status || "",
        TAX_RELIEF_TAX_AGENCY:
          user?.amtaxsettlement_data?.TAX_RELIEF_TAX_AGENCY || "",
        Notes: user?.amtaxsettlement_data?.Notes || "",
        Language: user?.amtaxsettlement_data?.Language || "",
        TAX_RELIEF_TAX_TYPE:
          user?.amtaxsettlement_data?.TAX_RELIEF_TAX_TYPE || "",
        SourceName: user?.amtaxsettlement_data?.SourceName || "",
        BusinessType: user?.amtaxsettlement_data?.BusinessType || "",
        BusinessName: user?.amtaxsettlement_data?.BusinessName || "",
        EIN: user?.amtaxsettlement_data?.EIN || "",
        SMSPermit: user?.amtaxsettlement_data?.SMSPermit || "",
        TaxProblem: user?.amtaxsettlement_data?.TaxProblem || "",
        OpenerName: user?.amtaxsettlement_data?.OpenerName || "",
        SETOfficerName: user?.amtaxsettlement_data?.SETOfficerName || "",
        TaxPreparerName: user?.amtaxsettlement_data?.TaxPreparerName || "",
        DistributionId: user?.amtaxsettlement_data?.DistributionId || "",
        StatusID: user?.amtaxsettlement_data?.StatusID || "",
        ProductID: user?.amtaxsettlement_data?.ProductID || "",
        UDF: user?.amtaxsettlement_data?.UDF || "",
        profile_image: user?.profile_image || "",
        timezone: user?.timezone || "",
        have_any_nonfiled_tax_returns: user?.have_any_nonfiled_tax_returns,
        years_unfiled_returns:
          user?.years_unfiled_returns && user?.years_unfiled_returns.length > 0
            ? JSON.parse(user?.years_unfiled_returns)
            : [],
        amount_owed_to_irs: user?.amount_owed_to_irs,
        amount_owed_to_state: user?.amount_owed_to_state,
        pick_your_states: user?.pick_your_states,
      }));
    } else {
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const Error = { ...errors };

    switch (name) {
      case "FirstName":
        Error[name] = "";
        if (value.length < 1) {
          Error[name] = "Required";
          Error["disabled"] = true;
        } else {
          Error["disabled"] = true;
        }
        break;
      case "LastName":
        Error[name] = "";
        if (value.length < 1) {
          Error[name] = "Required";
          Error["disabled"] = true;
        } else {
          Error["disabled"] = true;
        }
        break;

      case "Email": {
        if (isEmpty(value)) {
          Error[name] = "Required";
          Error["disabled"] = true;
        } else if (!isEmail(value)) {
          Error[name] = "Invalid Email";

          Error["disabled"] = true;
        } else {
          Error[name] = "";
          Error["disabled"] = true;
        }
        break;
      }
      case "Address":
        Error[name] = "";
        if (value.length < 1) {
          Error[name] = "Required";
          Error["disabled"] = true;
        } else {
          Error["disabled"] = true;
        }
        break;

      case "AptNo":
        Error[name] = "";
        if (value.length < 1) {
          Error[name] = "Required";
          Error["disabled"] = true;
        } else {
          Error["disabled"] = true;
        }
        break;

      case "City":
        Error[name] = "";
        if (value.length < 1) {
          Error[name] = "Required";
          Error["disabled"] = true;
        } else {
          Error["disabled"] = true;
        }
        break;

      case "State":
        Error[name] = "";
        if (value.length < 1) {
          Error[name] = "Required";
          Error["disabled"] = true;
        } else {
          Error["disabled"] = true;
        }
        break;

      case "Zip":
        Error[name] = "";
        if (value.length < 1) {
          Error[name] = "Required";
          Error["disabled"] = true;
        } else {
          Error["disabled"] = true;
        }
        break;

      case "SSN":
        Error[name] = "";
        if (value.length > 0 && value.length < 9) {
          Error[name] = "Social Security Number must contain 9 digits";
          Error["disabled"] = true;
        } else if (value.length > 9) {
          Error[name] = "Social Security Number must contain 9 digits";
          Error["disabled"] = true;
        } else {
          Error["disabled"] = true;
        }
        break;

      case "Marital_Status":
        Error[name] = "";
        if (value.length < 1) {
          Error[name] = "Required";
          Error["disabled"] = true;
        } else {
          Error["disabled"] = true;
        }
        break;

      case "TAX_RELIEF_TAX_AGENCY":
        Error[name] = "";
        if (value.length < 1) {
          Error[name] = "Required";
          Error["disabled"] = true;
        } else {
          Error["disabled"] = true;
        }
        break;

      case "Notes":
        Error[name] = "";
        if (value.length < 1) {
          Error[name] = "Required";
          Error["disabled"] = true;
        } else {
          Error["disabled"] = true;
        }
        break;

      case "Language":
        Error[name] = "";
        if (value.length < 1) {
          Error[name] = "Required";
          Error["disabled"] = true;
        } else {
          Error["disabled"] = true;
        }
        break;

      case "TaxProblem":
        Error[name] = "";
        if (value.length < 1) {
          Error[name] = "Required";
          Error["disabled"] = true;
        } else {
          Error["disabled"] = true;
        }
        break;

      case "TAX_RELIEF_TAX_TYPE":
        Error[name] = "";
        if (value.length < 1) {
          Error[name] = "Required";
          Error["disabled"] = true;
        } else {
          Error["disabled"] = true;
        }
        break;

      case "SourceName":
        Error[name] = "";
        if (value.length < 1) {
          Error[name] = "Required";
          Error["disabled"] = true;
        } else {
          Error["disabled"] = true;
        }
        break;

      // case "BusinessType":
      //   Error[name] = "";
      //   if (value.length < 1) {
      //     Error[name] = "Required";
      //     Error["disabled"] = true;
      //   } else {
      //     Error["disabled"] = true;
      //   }
      //   break;

      // case "BusinessName":
      //   Error[name] = "";
      //   if (value.length < 1) {
      //     Error[name] = "Required";
      //     Error["disabled"] = true;
      //   } else {
      //     Error["disabled"] = true;
      //   }
      //   break;
      // case "EIN":
      //   Error[name] = "";
      //   if (value.length < 1) {
      //     Error[name] = "Required";
      //     Error["disabled"] = true;
      //   } else {
      //     Error["disabled"] = true;
      //   }
      //   break;
      case "SMSPermit":
        Error[name] = "";
        if (value.length < 1) {
          Error[name] = "Required";
          Error["disabled"] = true;
        } else {
          Error["disabled"] = true;
        }
        break;

      case "document_template":
        Error[name] = "";
        if (value.length < 1) {
          Error[name] = "Required";
          Error["disabled"] = true;
        } else {
          Error["disabled"] = true;
        }
        break;

      default:
        break;
    }
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setError((pre) => ({ ...pre, ...Error }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const callBack = (response) => {
      if (response.data) {
        dispatch(userData({ id: userId }));
        localStorage.setItem("name", response.data.name);
        // window.location.reload();
        setLoading(false);
      }
    };
    let body = {
      userId: data.userId,
      FirstName: data.FirstName,
      LastName: data.LastName,
      Email: data.Email,
      Address: data.Address,
      AptNo: data.AptNo,
      City: data.City,
      State: data.State,
      Zip: data.Zip,
      document_template: data.document_template,
      CellPhone: "+" + data.CellPhone,
      WorkPhone: "+" + data.WorkPhone,
      HomePhone: "+" + data.HomePhone,
      DOB: moment(data.DOB).format("MM/DD/YYYY"),
      SSN: data.SSN,
      Marital_Status: data.Marital_Status,
      TAX_RELIEF_TAX_AGENCY: data.TAX_RELIEF_TAX_AGENCY,
      Notes: data.Notes,
      Language: data.Language,
      TAX_RELIEF_TAX_TYPE: data.TAX_RELIEF_TAX_TYPE,
      SourceName: data.SourceName,
      SMSPermit: data.SMSPermit,
      TaxProblem: data.TaxProblem,
      OpenerName: data.OpenerName,
      SETOfficerName: data.SETOfficerName,
      TaxPreparerName: data.TaxPreparerName,
      DistributionId: data.DistributionId,
      StatusID: data.StatusID,
      ProductID: data.ProductID,
      UDF: data.UDF,
      status: "active",
      profile_image: data.profile_image,
      timezone: data.timezone,
      have_any_nonfiled_tax_returns: data.have_any_nonfiled_tax_returns,
      years_unfiled_returns: JSON.stringify(data.years_unfiled_returns),
      amount_owed_to_irs: data.amount_owed_to_irs,
      amount_owed_to_state: data.amount_owed_to_state,
      pick_your_states: data.pick_your_states,
    };

    let businessBody = {
      BusinessType: data.BusinessType,
      BusinessName: data.BusinessName,
      EIN: data.EIN,
    };

    let finalBody;
    if (
      data.TAX_RELIEF_TAX_TYPE == "Personal and Business" ||
      data.TAX_RELIEF_TAX_TYPE == "Business"
    ) {
      finalBody = { ...body, ...businessBody };
    } else {
      finalBody = { ...body };
    }

    dispatch(updateUserData(finalBody, callBack));
  };

  const handlePhone = (name, phoneNumber, dialCode, validate) => {
    if (!phoneNumber || !dialCode) return false;
    const Error = { ...errors };
    switch (name) {
      case "CellPhone":
        if (!validate) {
          Error[name] = "Invalid Number";
        } else {
          Error[name] = "";
        }
        break;

      case "WorkPhone":
        if (!validate) {
          Error[name] = "Invalid Number";
        } else {
          Error[name] = "";
        }
        break;

      case "HomePhone":
        if (!validate) {
          Error[name] = "Invalid Number";
        } else {
          Error[name] = "";
        }
        break;

      default:
        break;
    }
    setData((pre) => ({
      ...pre,
      [name]: phoneNumber,
    }));
    setError((pre) => ({ ...pre, ...Error }));
  };

  const handleFileUpload = (e) => {
    let image = e.target.files[0];
    let formData = new FormData();
    formData.append("project_image[]", image);
    setPicLoading(true);
    const callback = (url) => {
      if (url.files) {
        setData((pre) => ({ ...pre, profile_image: url.files[0] }));
        setPicLoading(false);
      }
    };

    dispatch(fileUpload(formData, null, callback));
  };
  return (
    <>
      {loading && <Loading className={"cstmLoader"} />}
      <section className="dashboard profile-main py-2">
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="top mb-2">
                <Breadcrumb>
                  <Breadcrumb.Item>Profile</Breadcrumb.Item>
                  <Breadcrumb.Item active>Edit</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </Col>
            <Col lg="12" className="my-2">
              <div className="bg-white card-box px-4 py-3 rounded">
                <Form>
                  <Row className="align-items-start">
                    <Col lg="12">
                      <div className="profile-upload-main">
                        {picLoading && (
                          <Loading
                            style={
                              " d-flex cstmLoader align-items-center justify-content-center position-absolute"
                            }
                            css={{ height: "100px", width: "100px" }}
                          />
                        )}
                        <input
                          type="file"
                          name="profile_image"
                          onChange={handleFileUpload}
                          title="Upload image"
                        />
                        <img
                          src={
                            data.profile_image
                              ? data.profile_image
                              : "/assets/images/dummy-profile"
                          }
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          xmlnsSvgjs="http://svgjs.com/svgjs"
                          width="512"
                          height="512"
                          x="0"
                          y="0"
                          viewBox="0 0 471.04 471.04"
                          style={{ enableBackground: "new 0 0 512 512" }}
                          xmlSpace="preserve"
                          class="camera-icon-upload"
                        >
                          <g>
                            <path
                              d="M414.72 112.64h-49.152l-27.136-40.96c-10.24-15.36-28.16-24.576-46.592-24.576H179.2c-18.432 0-36.352 9.216-46.592 24.576l-27.136 40.96H56.32A56.158 56.158 0 0 0 0 168.96v198.656a56.158 56.158 0 0 0 56.32 56.32h358.4a56.158 56.158 0 0 0 56.32-56.32V168.96a56.158 56.158 0 0 0-56.32-56.32zm-179.2 265.216c-70.144 0-126.976-56.832-126.976-126.976s56.832-126.464 126.976-126.464 126.976 56.832 126.976 126.976c0 69.632-56.832 126.464-126.976 126.464zM407.552 192h-22.528c-9.216-.512-16.384-8.192-15.872-17.408.512-8.704 7.168-15.36 15.872-15.872h20.48c9.216-.512 16.896 6.656 17.408 15.872.512 9.216-6.144 16.896-15.36 17.408z"
                              fill="#000000"
                              data-original="#000000"
                              class=""
                            ></path>
                            <path
                              d="M235.52 180.736c-38.912 0-70.656 31.744-70.656 70.656s31.744 70.144 70.656 70.144 70.656-31.744 70.656-70.656c0-38.912-31.744-70.144-70.656-70.144z"
                              fill="#000000"
                              data-original="#000000"
                              class=""
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </Col>
                    <Col lg="6" className="my-2">
                      <label htmlFor="" className="form-label2 m-0">
                        First Name
                      </label>
                      <input
                        type="text"
                        name="FirstName"
                        value={data?.FirstName}
                        onChange={handleChange}
                        placeholder=""
                        className="form-control"
                      />
                      <span className="note error_note py-2 m-0 text-danger">
                        {errors.FirstName}
                      </span>
                    </Col>
                    <Col lg="6" className="my-2">
                      <label htmlFor="" className="form-label2 m-0">
                        Last Name
                      </label>
                      <input
                        type="text"
                        name="LastName"
                        value={data?.LastName}
                        onChange={handleChange}
                        placeholder=""
                        className="form-control"
                      />
                      <span className="note error_note py-2 m-0 text-danger">
                        {errors.LastName}
                      </span>
                    </Col>
                    <Col lg="6" className="my-2">
                      <label htmlFor="" className="form-label2 m-0">
                        Email
                      </label>
                      <input
                        type="email"
                        name="Email"
                        value={data?.Email}
                        onChange={handleChange}
                        placeholder=""
                        className="form-control"
                      />
                      <span className="note error_note py-2 m-0 text-danger">
                        {errors.Email}
                      </span>
                    </Col>
                    <Col lg="6" className="my-2 col-12 phone-input">
                      <label for="" className="form-label2 m-0">
                        Cell Phone
                      </label>
                      <PhoneInput
                        country={"us"}
                        value={data.CellPhone}
                        placeholder="Cell Phone"
                        onChange={(data, country) => {
                          let validateWithLength = country.format?.split(".");
                          validateWithLength = validateWithLength?.length - 1;
                          if (validateWithLength == data.length) {
                            handlePhone(
                              "CellPhone",
                              data,
                              country.dialCode,
                              true
                            );
                          } else {
                            handlePhone(
                              "CellPhone",
                              data,
                              country.dialCode,
                              false
                            );
                          }
                        }}
                      />
                      <span className="note error_note py-2 m-0 text-danger">
                        {errors.CellPhone}
                      </span>
                    </Col>
                    <Col lg="6" className="my-2 col-12">
                      <label for="" className="form-label2 m-0">
                        Work Phone
                      </label>
                      <PhoneInput
                        country={"us"}
                        value={data.WorkPhone}
                        placeholder="Work Phone"
                        onChange={(data, country) => {
                          let validateWithLength = country.format?.split(".");
                          validateWithLength = validateWithLength?.length - 1;
                          if (validateWithLength == data.length) {
                            handlePhone(
                              "WorkPhone",
                              data,
                              country.dialCode,
                              true
                            );
                          } else {
                            handlePhone(
                              "WorkPhone",
                              data,
                              country.dialCode,
                              false
                            );
                          }
                        }}
                      />
                      <span className="note error_note py-2 m-0 text-danger">
                        {errors.WorkPhone}
                      </span>
                    </Col>
                    <Col lg="6" className="my-2 col-12">
                      <label for="" className="form-label2 m-0">
                        Home Phone
                      </label>
                      <PhoneInput
                        country={"us"}
                        value={data.HomePhone}
                        placeholder="Home Phone"
                        onChange={(data, country) => {
                          let validateWithLength = country.format?.split(".");
                          validateWithLength = validateWithLength?.length - 1;
                          if (validateWithLength == data.length) {
                            handlePhone(
                              "HomePhone",
                              data,
                              country.dialCode,
                              true
                            );
                          } else {
                            handlePhone(
                              "HomePhone",
                              data,
                              country.dialCode,
                              false
                            );
                          }
                        }}
                      />
                      <span className="note error_note py-2 m-0 text-danger">
                        {errors.HomePhone}
                      </span>
                    </Col>
                    <Col lg="6" className="my-2">
                      <label htmlFor="" className="form-label2 m-0">
                        Address
                      </label>
                      <input
                        type="text"
                        name="Address"
                        value={data?.Address}
                        onChange={handleChange}
                        placeholder=""
                        className="form-control"
                      />
                      <span className="note error_note py-2 m-0 text-danger">
                        {errors.Address}
                      </span>
                    </Col>
                    <Col lg="6" className="my-2">
                      <label htmlFor="" className="form-label2 m-0">
                        Apt No.
                      </label>
                      <input
                        type="text"
                        name="AptNo"
                        value={data?.AptNo}
                        onChange={handleChange}
                        placeholder=""
                        className="form-control"
                      />

                      <span className="note error_note py-2 m-0 text-danger">
                        {errors.AptNo}
                      </span>
                    </Col>

                    <Col lg="6" className="my-2">
                      <label htmlFor="" className="form-label2 m-0">
                        City
                      </label>
                      <input
                        type="text"
                        name="City"
                        value={data?.City}
                        onChange={handleChange}
                        placeholder=""
                        className="form-control"
                      />
                      <span className="note error_note py-2 m-0 text-danger">
                        {errors.City}
                      </span>
                    </Col>
                    <Col lg="6" className="my-2 doc-select">
                      <label htmlFor="" className="form-label2 m-0">
                        State
                      </label>
                      {/* <input
                        type="text"
                        name="State"
                        value={data?.State}
                        onChange={handleChange}
                        placeholder=""
                        className="form-control"
                      /> */}
                      <Select
                        className="demo-simple-select-label mt-2"
                        id="demo-simple-select"
                        name="State"
                        value={data.State}
                        onChange={handleChange}
                        MenuProps={UsStates}
                      >
                        {UsStates &&
                          UsStates.length > 0 &&
                          UsStates.map((item, idx) => {
                            return (
                              <MenuItem key={item.code} value={item.code}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <span className="note error_note py-2 m-0 text-danger">
                        {errors.State}
                      </span>
                    </Col>
                    <Col lg="6" className="my-2">
                      <label htmlFor="" className="form-label2 m-0">
                        Zip
                      </label>
                      <input
                        type="number"
                        name="Zip"
                        value={data?.Zip}
                        onChange={handleChange}
                        placeholder=""
                        className="form-control"
                      />
                      <span className="note error_note py-2 m-0 text-danger">
                        {errors.Zip}
                      </span>
                    </Col>
                    <Col lg="6" className="my-2">
                      <label htmlFor="" className="form-label2 m-0">
                        DOB
                      </label>
                      <input
                        type="date"
                        name="DOB"
                        value={data?.DOB}
                        onChange={handleChange}
                        placeholder=""
                        max={minDate.toISOString().split("T")[0]}
                        className="form-control"
                      />
                      <span className="note error_note py-2 m-0 text-danger">
                        {errors.DOB}
                      </span>
                    </Col>
                    <Col lg="6" className="my-2">
                      <label htmlFor="" className="form-label2 m-0">
                        SSN
                      </label>
                      <input
                        type="text"
                        name="SSN"
                        value={
                          // data?.SSN.length == 9
                          // ? "*****" + data?.SSN.substring(5)
                          // : data?.SSN
                          data?.SSN
                        }
                        onChange={handleChange}
                        placeholder="Enter SSN (e.g., XXX-XX-XXXX)"
                        className="form-control"
                      />
                      <span className="note error_note py-2 m-0 text-danger">
                        {errors.SSN}
                      </span>
                    </Col>
                    <Col lg="6" className="my-2 doc-select">
                      <label htmlFor="" className="form-label2 m-0">
                        Marital Status
                      </label>
                      <Select
                        className="demo-simple-select-label mt-2"
                        id="demo-simple-select"
                        name="Marital_Status"
                        value={data.Marital_Status}
                        onChange={handleChange}
                        MenuProps={MarryStatus}
                      >
                        {MarryStatus &&
                          MarryStatus.length > 0 &&
                          MarryStatus.map((item, idx) => {
                            return (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <span className="note error_note py-2 m-0 text-danger">
                        {errors.Marital_Status}
                      </span>
                    </Col>
                    <Col lg="6" className="my-2 doc-select">
                      <label htmlFor="" className="form-label2 m-0">
                        Tax Relief Tax Agency
                      </label>
                      <Select
                        className="demo-simple-select-label mt-2"
                        id="demo-simple-select"
                        name="TAX_RELIEF_TAX_AGENCY"
                        value={data.TAX_RELIEF_TAX_AGENCY}
                        onChange={handleChange}
                        MenuProps={TaxAgnecy}
                      >
                        {TaxAgnecy &&
                          TaxAgnecy.length > 0 &&
                          TaxAgnecy.map((item, idx) => {
                            return (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      {/* <span className="note error_note py-2 m-0 text-danger">
                        {errors.TAX_RELIEF_TAX_AGENCY}
                      </span> */}
                    </Col>

                    {(data.TAX_RELIEF_TAX_AGENCY == "FEDERAL" ||
                      data.TAX_RELIEF_TAX_AGENCY == "BOTH") && (
                      <Col lg="6" className="my-2">
                        <label htmlFor="" className="form-label2 m-0">
                          Amount Owed to Federal
                        </label>
                        <input
                          type="number"
                          name="amount_owed_to_irs"
                          value={data?.amount_owed_to_irs}
                          onChange={handleChange}
                          placeholder=""
                          className="form-control"
                        />
                      </Col>
                    )}

                    {(data.TAX_RELIEF_TAX_AGENCY == "STATE" ||
                      data.TAX_RELIEF_TAX_AGENCY == "BOTH") && (
                      <Col lg="6" className="my-2">
                        <label htmlFor="" className="form-label2 m-0">
                          Amount Owed to State
                        </label>
                        <input
                          type="number"
                          name="amount_owed_to_state"
                          value={data?.amount_owed_to_state}
                          onChange={handleChange}
                          placeholder=""
                          className="form-control"
                        />
                      </Col>
                    )}

                    {(data.TAX_RELIEF_TAX_AGENCY == "STATE" ||
                      data.TAX_RELIEF_TAX_AGENCY == "BOTH") && (
                      <Col lg="6" className="my-2 doc-select">
                        <label htmlFor="" className="form-label2 m-0">
                          Pick your States
                        </label>
                        <Select
                          className="demo-simple-select-label mt-2"
                          id="demo-simple-select"
                          name="pick_your_states"
                          value={data.pick_your_states || []}
                          multiple
                          onChange={handleChange}
                          MenuProps={UsStates}
                        >
                          {UsStates &&
                            UsStates.length > 0 &&
                            UsStates.map((item, idx) => {
                              return (
                                <MenuItem key={item.code} value={item.code}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        {/* <span className="note error_note py-2 m-0 text-danger">
                        {errors.State}
                      </span> */}
                      </Col>
                    )}
                    <Col lg="6" className="my-2 doc-select">
                      <label htmlFor="" className="form-label2 m-0">
                        Language
                      </label>
                      <Select
                        className="demo-simple-select-label mt-2"
                        id="demo-simple-select"
                        name="Language"
                        value={data.Language}
                        onChange={handleChange}
                        MenuProps={Languages}
                      >
                        {Languages &&
                          Languages.length > 0 &&
                          Languages.map((item, idx) => {
                            return (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      {/* <span className="note error_note py-2 m-0 text-danger">
                        {errors.Language}
                      </span> */}
                    </Col>
                    <Col lg="6" className="my-2 doc-select">
                      <label htmlFor="" className="form-label2 m-0">
                        Do you have any Non-Filed Tax Returns?
                      </label>
                      <Select
                        className="demo-simple-select-label mt-2"
                        id="demo-simple-select"
                        name="have_any_nonfiled_tax_returns"
                        value={data.have_any_nonfiled_tax_returns}
                        onChange={handleChange}
                        MenuProps={Default}
                      >
                        {Default &&
                          Default.length > 0 &&
                          Default.map((item, idx) => {
                            return (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </Col>

                    {data.have_any_nonfiled_tax_returns == "1" && (
                      <Col lg="6" className="my-2 doc-select">
                        <label htmlFor="" className="form-label2 m-0">
                          Select Years Unfiled Tax Returns?
                        </label>
                        <Select
                          className="demo-simple-select-label mt-2"
                          id="demo-simple-select"
                          name="years_unfiled_returns"
                          value={data.years_unfiled_returns || []}
                          onChange={handleChange}
                          MenuProps={Years || []}
                          multiple
                        >
                          {Years &&
                            Years.length > 0 &&
                            Years.map((item, idx) => {
                              return (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </Col>
                    )}
                    <Col lg="6" className="my-2">
                      <label htmlFor="" className="form-label2 m-0">
                        Notes
                      </label>
                      <input
                        type="text"
                        name="Notes"
                        value={data?.Notes}
                        onChange={handleChange}
                        placeholder=""
                        className="form-control"
                      />
                      <span className="note error_note py-2 m-0 text-danger">
                        {errors.Notes}
                      </span>
                    </Col>
                    <Col lg="6" className="my-2 doc-select">
                      <label htmlFor="" className="form-label2 m-0">
                        Tax Problem
                      </label>
                      <Select
                        className="demo-simple-select-label mt-2"
                        id="demo-simple-select"
                        name="TaxProblem"
                        value={data.TaxProblem}
                        onChange={handleChange}
                        MenuProps={TaxProblems}
                      >
                        {TaxProblems &&
                          TaxProblems.length > 0 &&
                          TaxProblems.map((item, idx) => {
                            return (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <span className="note error_note py-2 m-0 text-danger">
                        {errors.TaxProblem}
                      </span>
                    </Col>
                    <Col lg="6" className="my-2 doc-select">
                      <label htmlFor="" className="form-label2 m-0">
                        Tax Relief Tax Type
                      </label>
                      <Select
                        className="demo-simple-select-label mt-2"
                        id="demo-simple-select"
                        name="TAX_RELIEF_TAX_TYPE"
                        value={data.TAX_RELIEF_TAX_TYPE}
                        onChange={handleChange}
                        MenuProps={TaxType}
                      >
                        {TaxType &&
                          TaxType.length > 0 &&
                          TaxType.map((item, idx) => {
                            return (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <span className="note error_note py-2 m-0 text-danger">
                        {errors.TAX_RELIEF_TAX_TYPE}
                      </span>
                    </Col>
                    <Col lg="6" className="my-2">
                      <label htmlFor="" className="form-label2 m-0">
                        Source Name
                      </label>
                      <input
                        type="text"
                        name="SourceName"
                        value={data?.SourceName}
                        onChange={handleChange}
                        placeholder=""
                        className="form-control"
                      />
                      <span className="note error_note py-2 m-0 text-danger">
                        {errors.SourceName}
                      </span>
                    </Col>
                    {(data.TAX_RELIEF_TAX_TYPE == "Personal and Business" ||
                      data.TAX_RELIEF_TAX_TYPE == "Business") && (
                      <Col lg="6" className="my-2 doc-select">
                        <label htmlFor="" className="form-label2 m-0">
                          Business Type
                        </label>
                        <Select
                          className="demo-simple-select-label mt-2"
                          id="demo-simple-select"
                          name="BusinessType"
                          value={data.BusinessType}
                          onChange={handleChange}
                          MenuProps={BusinessTypes}
                        >
                          {BusinessTypes &&
                            BusinessTypes.length > 0 &&
                            BusinessTypes.map((item, idx) => {
                              return (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <span className="note error_note py-2 m-0 text-danger">
                          {errors.BusinessType}
                        </span>
                      </Col>
                    )}
                    {(data.TAX_RELIEF_TAX_TYPE == "Personal and Business" ||
                      data.TAX_RELIEF_TAX_TYPE == "Business") && (
                      <Col lg="6" className="my-2">
                        <label htmlFor="" className="form-label2 m-0">
                          Business Name
                        </label>
                        <input
                          type="text"
                          name="BusinessName"
                          value={data?.BusinessName}
                          onChange={handleChange}
                          placeholder=""
                          className="form-control"
                        />
                        <span className="note error_note py-2 m-0 text-danger">
                          {errors.BusinessName}
                        </span>
                      </Col>
                    )}
                    {(data.TAX_RELIEF_TAX_TYPE == "Personal and Business" ||
                      data.TAX_RELIEF_TAX_TYPE == "Business") && (
                      <Col lg="6" className="my-2">
                        <label htmlFor="" className="form-label2 m-0">
                          EIN
                        </label>
                        <input
                          type="number"
                          name="EIN"
                          value={data?.EIN}
                          onChange={handleChange}
                          placeholder=""
                          className="form-control"
                        />
                        <span className="note error_note py-2 m-0 text-danger">
                          {errors.EIN}
                        </span>
                      </Col>
                    )}
                    <Col lg="6" className="my-2 doc-select">
                      <label htmlFor="" className="form-label2 m-0">
                        SMS Permit
                      </label>
                      <Select
                        className="demo-simple-select-label mt-2"
                        id="demo-simple-select"
                        name="SMSPermit"
                        value={data.SMSPermit}
                        onChange={handleChange}
                        // MenuProps={Templates}
                      >
                        <MenuItem key={true} value={true}>
                          Yes
                        </MenuItem>
                        <MenuItem key={false} value={false}>
                          No
                        </MenuItem>
                      </Select>
                      {data.SMSPermit == false && (
                        <span className="note error_note py-2 m-0 disclaimer-text">
                          You will not be able to receive notifications and will
                          miss out on important status updates for your case!
                        </span>
                      )}
                      {data.SMSPermit == "no" && (
                        <span className="note error_note py-2 m-0 disclaimer-text">
                          You will not be able to receive notifications and will
                          miss out on important status updates for your case!
                        </span>
                      )}
                    </Col>
                    <Col lg="6" className="my-2 doc-select">
                      <label htmlFor="" className="form-label2 m-0">
                        Select Timezone
                      </label>
                      <Select
                        className="demo-simple-select-label mt-2"
                        id="demo-simple-select"
                        name="timezone"
                        value={data.timezone}
                        onChange={handleChange}
                        MenuProps={Zones}
                      >
                        {Zones &&
                          Zones.length > 0 &&
                          Zones.map((item, idx) => {
                            return (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <span className="note error_note py-2 m-0 text-danger">
                        {errors.timezone}
                      </span>
                    </Col>

                    <Col lg="12" className="my-2">
                      <div className="pt-4 btn-wrp d-flex algign-items-center">
                        <Button
                          // disabled={errors.disabled}
                          onClick={handleSubmit}
                          className={`d-inline-flex align-items-center justify-content-center me-2`}
                        >
                          Submit
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default ProfileForm;
