import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Tab,
  Nav,
  Dropdown,
} from "react-bootstrap";
import {
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTablePagination,
} from "../../../Common/DataTable";
import { useDispatch, useSelector } from "react-redux";
import { getNoteById, deleteNote } from "../../../store/actions";
import Filters from "./Filters";
import { toast } from "react-toastify";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Link, useParams } from "react-router-dom";
import moment from "moment/moment";

const DueDate = (value) => {
  return moment(value.due_date).format("MM-DD-YYYY");
};

const columnData = [
  { id: "_id", align: false, disablePadding: true, label: "#", order: 1 },
  {
    id: "title",
    align: false,
    disablePadding: true,
    label: "Title",
    title: "Title",
    order: 1,
  },
  {
    id: "action",
    align: true,
    disablePadding: false,
    title: "action",
    label: "Action",
  },
];

const NotesIndex = () => {
  const dispatch = useDispatch();
  const { editId } = useParams();
  const [tabs, setTabs] = useState("all");
  const [filter, setFilter] = useState("");
  const [state, setState] = useState({
    orderBy: "sort_index",
    order: "asc",
    page: 0,
    limit: 10,
    searchBy: "name",
    search: "",
    tab: "all",
  });

  const [Delete, setDelete] = useState({
    open: false,
    dumpId: "",
  });
  const [dragUsers, setDragUsers] = useState("");
  const userData = useSelector((s) => s?.Customers?.getNote?.data);
  const totalCount = useSelector((s) => s?.Customers?.getNote?.total_count);
  const [loading, setLoading] = useState(false);
  const user_id = localStorage && localStorage.getItem("id");
  useEffect(() => {
    if (userData) {
      setDragUsers(userData);
    }
  }, [userData]);

  const getTableRecords = () => {
    let body = {
      orderBy: state.orderBy,
      order: state.order,
      page: state.page,
      limit: state.limit,
      tab: state.tab,
      search: filter,
      searchBy: state.searchBy,
      sender_id: user_id,
      receiver_id: editId,
    };

    dispatch(getNoteById(body));
  };

  useEffect(() => {
    getTableRecords(state.order, state.orderBy);
  }, [state]);

  const handleStatus = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setState((pre) => ({
      ...pre,
      status: value,
    }));
  };

  const resetFilter = () => {
    setState((pre) => ({
      ...pre,
      search: "",
    }));
    setFilter("");
  };

  const handleChangePage = (event, page) => {
    setState((pre) => ({
      ...pre,
      page: page,
    }));
    // getTableRecords(state.order, state.orderBy);
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setFilter(value);
  };

  const filterdata = (e) => {
    e.preventDefault();
    getTableRecords(1, state.orderBy);
  };

  const DeletePopup = (state, id) => {
    setDelete((pre) => ({
      ...pre,
      open: state,
      dumpId: id,
    }));
  };

  const handleDelete = () => {
    const callBack = (response) => {
      if (response.data) {
        toast.success(response.message);
        setDelete(false);
        dispatch(
          getNoteById({
            orderBy: "id",
            order: "asc",
            page: 0,
            limit: 10,
            searchBy: "name",
            search: "",
            sender_id: user_id,
            receiver_id: editId,
          })
        );
      } else {
        setDelete(false);
      }
    };
    dispatch(deleteNote(Delete.dumpId, callBack));
  };

  const handleRequestSort = (data) => {
    if (data.key !== "action") {
      let NewColoumns = state.columns.map((key, index) => {
        if (key.id !== data.key) {
          return { ...key, order: 1 };
        } else {
          return { ...key, order: data.order };
        }
      });
      setState(
        {
          loading: true,
          columns: NewColoumns,
          orderBy: data.key,
          order: data.order,
          rowsPerPage: 10,
          page: 0,
        },
        () => getTableRecords(data.order, data.key)
      );
    }
  };

  // const handleDragEnd = (e) => {
  //   if (!e.destination) return;
  //   let tempData = Array.from(dragUsers);
  //   let [source_data] = tempData.splice(e.source.index, 1);
  //   tempData.splice(e.destination.index, 0, source_data);
  //   setDragUsers(tempData);

  //   let body = {
  //     id: e?.draggableId,
  //     current_index: JSON.stringify(e?.source?.index + 1),
  //     destination_index: JSON.stringify(e?.destination?.index + 1),
  //     action_on: "admins",
  //   };
  //   dispatch(rowSorting(body));
  // };

  const handleTabChange = (value) => {
    setState((pre) => ({ ...pre, tab: value }));
  };

  return (
    <>
      <Modal
        show={Delete.open}
        onHide={() => {
          setDelete(false);
        }}
        className="deleteAdd-pop delete-pop"
        centered
      >
        <Modal.Body>
          <div className="text-center py-3">
            <div className="icn my-3">
              <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M256,0C114.497,0,0,114.507,0,256c0,141.503,114.507,256,256,256c141.503,0,256-114.507,256-256    C512,114.497,397.493,0,256,0z M256,472c-119.393,0-216-96.615-216-216c0-119.393,96.615-216,216-216    c119.393,0,216,96.615,216,216C472,375.393,375.385,472,256,472z"
                  fill="#f8c886"
                ></path>
                <path
                  d="M256,128.877c-11.046,0-20,8.954-20,20V277.67c0,11.046,8.954,20,20,20s20-8.954,20-20V148.877    C276,137.831,267.046,128.877,256,128.877z"
                  fill="#f8c886"
                ></path>
                <circle cx="256" cy="349.16" r="27" fill="#f8c886"></circle>{" "}
              </svg>
            </div>
            <h2 className="pop-head m-0 pb-2">Are you Sure?</h2>
            <div className="btn-wrap my-2 d-flex align-items-center justify-content-center mt-3">
              <div className="pe-2 w-50">
                <Button
                  className="btn-2 w-100 d-flex align-items-center justify-content-center"
                  onClick={() => {
                    setDelete(false);
                  }}
                >
                  No Cancel!
                </Button>
              </div>
              <div className="pe-2 w-50">
                <Button
                  onClick={handleDelete}
                  className="w-100 d-flex align-items-center justify-content-center"
                >
                  Yes, Delete it
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <section className="dashboard py-2">
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="top mb-2">
                <h4 className="dashboard-head m-0">Notes</h4>
              </div>
            </Col>
            <Col lg="12" className="my-2">
              {/* <div className="bg-white px-4 py-3">
                <Filters
                  status={state.status}
                  columns={state.columns}
                  filter={filter}
                  handleStatus={handleStatus}
                  handleSearchChange={handleSearchChange}
                  filterdata={filterdata}
                  resetFilter={resetFilter}
                />
              </div> */}
              <div className="d-flex justify-content-between">
                <div className="m-2">
                  {/* <Link
                    className="btn cstm-link-btn"
                    to={`/customers/profile/${editId}`}
                  >
                    Back
                  </Link> */}
                </div>
                <div className="text-end m-2">
                  <Link className="btn cstm-link-btn" to={`/client/notes/add`}>
                    Add New Note
                  </Link>
                </div>
              </div>
              <Tab.Container id="left-tabs-example" defaultActiveKey={tabs}>
                {/* <Nav variant="pills" className="bg-white mb-3 commonTabs">
                  <Nav.Item>
                    <Nav.Link
                      className="bg-transparent border-0 py-3 position-relative"
                      eventKey="all"
                      onClick={() => handleTabChange("all")}
                    >
                      All
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className="bg-transparent border-0 py-3 position-relative"
                      eventKey="today"
                      onClick={() => handleTabChange("today")}
                    >
                      Due Tasks Today
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className="bg-transparent border-0 py-3 position-relative"
                      eventKey="thisWeek"
                      onClick={() => handleTabChange("week")}
                    >
                      Due Tasks This Week
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className="bg-transparent border-0 py-3 position-relative"
                      eventKey="thisMonth"
                      onClick={() => handleTabChange("month")}
                    >
                      Due Tasks This Month
                    </Nav.Link>
                  </Nav.Item>
                </Nav> */}
                <div className="bg-white px-4 py-3 card-box">
                  <div className="table-responsive py-3">
                    {/* <DragDropContext onDragEnd={handleDragEnd}> */}
                    <DataTable className="">
                      <DataTableHead
                        columns={columnData}
                        orderBy={state.orderBy}
                        sort={handleRequestSort}
                      />
                      {/* <Droppable droppableId="droppable-1">
                          {(provider) => ( */}
                      <DataTableBody
                        loading={loading}
                        column={columnData}
                        data={dragUsers}
                        page={state.page}
                        rowsPerPage={state.limit}
                        // innerRef={provider.innerRef}
                        // dropProp={provider.droppableProps}
                        actionBtn={[
                          {
                            label: "Edit",
                            icon: "",
                            link: `/client/notes/edit`,
                          },
                          {
                            label: "Delete",
                            icon: "",
                            action: DeletePopup,
                          },
                        ]}
                      />
                      {/* )} */}
                      {/* </Droppable> */}
                    </DataTable>
                    {/* </DragDropContext> */}
                    {dragUsers?.length > 0 && (
                      <div className="custom-pagination d-flex align-items-center w-100 justify-content-end">
                        <div className="main-pagination-drop d-flex gap-2 align-items-center">
                          <h6 className="row-per-page-heading m-0">
                            Rows per page:
                          </h6>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              {state.limit}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() =>
                                  setState((pre) => ({ ...pre, limit: 10 }))
                                }
                              >
                                10
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  setState((pre) => ({ ...pre, limit: 25 }))
                                }
                              >
                                25
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  setState((pre) => ({ ...pre, limit: 50 }))
                                }
                              >
                                50
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  setState((pre) => ({
                                    ...pre,
                                    limit: 100,
                                  }))
                                }
                              >
                                100
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>

                        <DataTablePagination
                          count={totalCount}
                          rowsPerPage={state.limit}
                          page={state.page}
                          onPageChange={handleChangePage}
                          rowsPerPageOptions={[]}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default NotesIndex;
