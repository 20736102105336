import { MenuItem, Select } from "@material-ui/core";
import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

const YesNo = [
  {
    name: "Yes",
    id: "Yes",
  },
  {
    name: "No",
    id: "No",
  },
];

const IncomeExpenseStatement = ({
  incomeExpenseState,
  setIncomeExpenseState,
  errors,
  setError,
  handleTabChange,
  handleSubmitForm,
  handleBackBtn,
  handleSubmitBlur,
  ConfiguresIncome,
  extraFields,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    // const Error = { ...errors };

    // switch (name) {
    //   case "gross_you":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "gross_spouse_partner":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "rent_you":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "rent_spouse_partner":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "other_you":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "other_spouse_partner":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "child_you":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "child_spouse_partner":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "soc_you":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "soc_spouse_partner":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "pension_you":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "pension_spouse_partner":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "misc_you":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "misc_spouse_partner":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "primary_home_mortgage":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "primary_home_tax_ins":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "any_other_mortgage":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "any_other_property_tax_ins":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "credit_card_payments_total":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "any_other_revolving_debt":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "installment_loan_payments":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "dependent_child_care":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "personal_loans":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "income_tax_from_paycheck":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "fourZeroOneKLoans":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "child_support":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "alimony":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "irs_liens":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "tuition":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "student_loans":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "gas":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "electric":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "water":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "trash_sewer":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "telephone":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "cell_phone":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "internet":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "cable_satellite":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "vehicle_insurance":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "maintenance":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "food":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "transportation":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "gardening_landscaping":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "housecleaning":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "other":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "insurance_medical_amount":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "insurance_medical_paycheck":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "insurance_dental_amount":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "insurance_dental_paycheck":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "insurance_life_amount":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "insurance_life_paycheck":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "insurance_vision_amount":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "insurance_vision_paycheck":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "insurance_disability_amount":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "insurance_disability_paycheck":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "insurance_surrender_amount":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "insurance_surrender_paycheck":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   default:
    //     break;
    // }
    setIncomeExpenseState((pre) => ({
      ...pre,
      [name]: value,
    }));
    // setError((pre) => ({ ...pre, ...Error }));
  };

  const handleRadioChange = (name, value) => {
    setIncomeExpenseState((pre) => ({ ...pre, [name]: value }));
  };

  const handleCheckChange = (name, value) => {
    if (incomeExpenseState[name]?.includes(value)) {
      setIncomeExpenseState((pre) => ({
        ...pre,
        [name]: pre[name]?.filter((item) => {
          return item !== value;
        }),
      }));
    } else {
      setIncomeExpenseState((pre) => ({
        ...pre,
        [name]: pre[name] ? [...pre[name], value] : [value],
      }));
    }
    // handleSubmitBlur();
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setIncomeExpenseState((pre) => ({ ...pre, [name]: value }));
  };
  return (
    <div className="bg-white card-box px-4 py-3 rounded">
      <Form>
        <Row className="align-items-start">
          <Col lg="12" className="my-2">
            <h4 className="text-center">Income Statement</h4>
          </Col>
          {ConfiguresIncome?.gross_income_visible && (
            <>
              <Col lg="6" className="my-2">
                <label htmlFor="" className="form-label2 m-0">
                  Your Gross Income
                </label>
                <input
                  type="number"
                  name="gross_you"
                  value={incomeExpenseState?.gross_you}
                  onChange={handleChange}
                  placeholder=""
                  className="form-control"
                  onBlur={handleSubmitBlur}
                />
                <span className="note error_note py-2 m-0 text-danger">
                  {errors?.gross_you}
                </span>
              </Col>
              <Col lg="6" className="my-2">
                <label htmlFor="" className="form-label2 m-0">
                  Spouse Gross Income
                </label>
                <input
                  type="number"
                  name="gross_spouse_partner"
                  value={incomeExpenseState?.gross_spouse_partner}
                  onChange={handleChange}
                  placeholder=""
                  className="form-control"
                  onBlur={handleSubmitBlur}
                />
                <span className="note error_note py-2 m-0 text-danger">
                  {errors?.gross_spouse_partner}
                </span>
              </Col>
            </>
          )}
          {ConfiguresIncome?.rent_income_visible && (
            <>
              <Col lg="6" className="my-2">
                <label htmlFor="" className="form-label2 m-0">
                  Your Rent Income
                </label>
                <input
                  type="number"
                  name="rent_you"
                  value={incomeExpenseState?.rent_you}
                  onChange={handleChange}
                  placeholder=""
                  className="form-control"
                  onBlur={handleSubmitBlur}
                />
                <span className="note error_note py-2 m-0 text-danger">
                  {errors?.rent_you}
                </span>
              </Col>
              <Col lg="6" className="my-2">
                <label htmlFor="" className="form-label2 m-0">
                  Spouse Rent Income
                </label>
                <input
                  type="number"
                  name="rent_spouse_partner"
                  value={incomeExpenseState?.rent_spouse_partner}
                  onChange={handleChange}
                  placeholder=""
                  className="form-control"
                  onBlur={handleSubmitBlur}
                />
                <span className="note error_note py-2 m-0 text-danger">
                  {errors?.rent_spouse_partner}
                </span>
              </Col>
            </>
          )}
          {ConfiguresIncome?.other_income_visible && (
            <>
              <Col lg="6" className="my-2">
                <label htmlFor="" className="form-label2 m-0">
                  Your Other Income
                </label>
                <input
                  type="number"
                  name="other_you"
                  value={incomeExpenseState?.other_you}
                  onChange={handleChange}
                  placeholder=""
                  className="form-control"
                  onBlur={handleSubmitBlur}
                />
                <span className="note error_note py-2 m-0 text-danger">
                  {errors?.other_you}
                </span>
              </Col>
              <Col lg="6" className="my-2">
                <label htmlFor="" className="form-label2 m-0">
                  Spouse Other Income
                </label>
                <input
                  type="number"
                  name="other_spouse_partner"
                  value={incomeExpenseState?.other_spouse_partner}
                  onChange={handleChange}
                  placeholder=""
                  className="form-control"
                  onBlur={handleSubmitBlur}
                />
                <span className="note error_note py-2 m-0 text-danger">
                  {errors?.other_spouse_partner}
                </span>
              </Col>
            </>
          )}
          {ConfiguresIncome?.child_support_visible && (
            <>
              <Col lg="6" className="my-2">
                <label htmlFor="" className="form-label2 m-0">
                  Your Child Support
                </label>
                <input
                  type="number"
                  name="child_you"
                  value={incomeExpenseState?.child_you}
                  onChange={handleChange}
                  placeholder=""
                  className="form-control"
                  onBlur={handleSubmitBlur}
                />
                <span className="note error_note py-2 m-0 text-danger">
                  {errors?.child_you}
                </span>
              </Col>
              <Col lg="6" className="my-2">
                <label htmlFor="" className="form-label2 m-0">
                  Spouse Child Support
                </label>
                <input
                  type="number"
                  name="child_spouse_partner"
                  value={incomeExpenseState?.child_spouse_partner}
                  onChange={handleChange}
                  placeholder=""
                  className="form-control"
                  onBlur={handleSubmitBlur}
                />
                <span className="note error_note py-2 m-0 text-danger">
                  {errors?.child_spouse_partner}
                </span>
              </Col>
            </>
          )}
          {ConfiguresIncome?.soc_sec_ssi_visible && (
            <>
              <Col lg="6" className="my-2">
                <label htmlFor="" className="form-label2 m-0">
                  Your Soc Sec,SSI
                </label>
                <input
                  type="number"
                  name="soc_you"
                  value={incomeExpenseState?.soc_you}
                  onChange={handleChange}
                  placeholder=""
                  className="form-control"
                  onBlur={handleSubmitBlur}
                />
                <span className="note error_note py-2 m-0 text-danger">
                  {errors?.soc_you}
                </span>
              </Col>
              <Col lg="6" className="my-2">
                <label htmlFor="" className="form-label2 m-0">
                  Spouse Soc Sec,SSI
                </label>
                <input
                  type="number"
                  name="soc_spouse_partner"
                  value={incomeExpenseState?.soc_spouse_partner}
                  onChange={handleChange}
                  placeholder=""
                  className="form-control"
                  onBlur={handleSubmitBlur}
                />
                <span className="note error_note py-2 m-0 text-danger">
                  {errors?.soc_spouse_partner}
                </span>
              </Col>
            </>
          )}
          {ConfiguresIncome?.pension_visible && (
            <>
              <Col lg="6" className="my-2">
                <label htmlFor="" className="form-label2 m-0">
                  Your Pension
                </label>
                <input
                  type="number"
                  name="pension_you"
                  value={incomeExpenseState?.pension_you}
                  onChange={handleChange}
                  placeholder=""
                  className="form-control"
                  onBlur={handleSubmitBlur}
                />
                <span className="note error_note py-2 m-0 text-danger">
                  {errors?.pension_you}
                </span>
              </Col>
              <Col lg="6" className="my-2">
                <label htmlFor="" className="form-label2 m-0">
                  Spouse Pension
                </label>
                <input
                  type="number"
                  name="pension_spouse_partner"
                  value={incomeExpenseState?.pension_spouse_partner}
                  onChange={handleChange}
                  placeholder=""
                  className="form-control"
                  onBlur={handleSubmitBlur}
                />
                <span className="note error_note py-2 m-0 text-danger">
                  {errors?.pension_spouse_partner}
                </span>
              </Col>
            </>
          )}
          {ConfiguresIncome?.other_misc_visible && (
            <>
              <Col lg="6" className="my-2">
                <label htmlFor="" className="form-label2 m-0">
                  Your Other/Misc
                </label>
                <input
                  type="number"
                  name="misc_you"
                  value={incomeExpenseState?.misc_you}
                  onChange={handleChange}
                  placeholder=""
                  className="form-control"
                  onBlur={handleSubmitBlur}
                />
                <span className="note error_note py-2 m-0 text-danger">
                  {errors?.misc_you}
                </span>
              </Col>
              <Col lg="6" className="my-2">
                <label htmlFor="" className="form-label2 m-0">
                  Spouse Other/Misc
                </label>
                <input
                  type="number"
                  name="misc_spouse_partner"
                  value={incomeExpenseState?.misc_spouse_partner}
                  onChange={handleChange}
                  placeholder=""
                  className="form-control"
                  onBlur={handleSubmitBlur}
                />
                <span className="note error_note py-2 m-0 text-danger">
                  {errors?.misc_spouse_partner}
                </span>
              </Col>
            </>
          )}
          <Col lg="12" className="my-2">
            <h4 className="text-center">Expense Statement</h4>
          </Col>
          <Col lg="12" className="my-2">
            <h6>Debt (Monthly)</h6>
          </Col>
          {ConfiguresIncome?.primary_home_mortgage_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Primary Home Mortgage
              </label>
              <input
                type="number"
                name="primary_home_mortgage"
                value={incomeExpenseState?.primary_home_mortgage}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.primary_home_mortgage}
              </span>
            </Col>
          )}
          {ConfiguresIncome?.personal_loans_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Personal Loan
              </label>
              <input
                type="number"
                name="personal_loans"
                value={incomeExpenseState?.personal_loans}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.personal_loans}
              </span>
            </Col>
          )}
          {ConfiguresIncome?.primary_home_tax_ins_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Primary Home Tax & Ins.
              </label>
              <input
                type="number"
                name="primary_home_tax_ins"
                value={incomeExpenseState?.primary_home_tax_ins}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.primary_home_tax_ins}
              </span>
            </Col>
          )}
          {ConfiguresIncome?.income_tax_from_paycheck_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Income Taxes (from paycheck)
              </label>
              <input
                type="number"
                name="income_tax_from_paycheck"
                value={incomeExpenseState?.income_tax_from_paycheck}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.income_tax_from_paycheck}
              </span>
            </Col>
          )}
          {ConfiguresIncome?.any_other_mortgage_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Any Other Mortgages
              </label>
              <input
                type="number"
                name="any_other_mortgage"
                value={incomeExpenseState?.any_other_mortgage}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.any_other_mortgage}
              </span>
            </Col>
          )}
          {ConfiguresIncome?.fourZeroOneKLoans_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                401k loans
              </label>
              <input
                type="number"
                name="fourZeroOneKLoans"
                value={incomeExpenseState?.fourZeroOneKLoans}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.fourZeroOneKLoans}
              </span>
            </Col>
          )}
          {ConfiguresIncome?.any_other_property_tax_ins_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Any other property tax/ins.
              </label>
              <input
                type="number"
                name="any_other_property_tax_ins"
                value={incomeExpenseState?.any_other_property_tax_ins}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.any_other_property_tax_ins}
              </span>
            </Col>
          )}
          {ConfiguresIncome?.child_support_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Child Support
              </label>
              <input
                type="number"
                name="child_support"
                value={incomeExpenseState?.child_support}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.child_support}
              </span>
            </Col>
          )}
          {ConfiguresIncome?.credit_card_payments_total_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Credit Card Payments (total)
              </label>
              <input
                type="number"
                name="credit_card_payments_total"
                value={incomeExpenseState?.credit_card_payments_total}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.credit_card_payments_total}
              </span>
            </Col>
          )}
          {ConfiguresIncome?.alimony_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Alimony
              </label>
              <input
                type="number"
                name="alimony"
                value={incomeExpenseState?.alimony}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.alimony}
              </span>
            </Col>
          )}
          {ConfiguresIncome?.any_other_revolving_debt_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Any Other revolving debt
              </label>
              <input
                type="number"
                name="any_other_revolving_debt"
                value={incomeExpenseState?.any_other_revolving_debt}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.any_other_revolving_debt}
              </span>
            </Col>
          )}
          {ConfiguresIncome?.irs_liens_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                IRS Liens
              </label>
              <input
                type="number"
                name="irs_liens"
                value={incomeExpenseState?.irs_liens}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.irs_liens}
              </span>
            </Col>
          )}
          {ConfiguresIncome?.installment_loan_payments_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Installment loan payments
              </label>
              <input
                type="number"
                name="installment_loan_payments"
                value={incomeExpenseState?.installment_loan_payments}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.installment_loan_payments}
              </span>
            </Col>
          )}
          {ConfiguresIncome?.tuition_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Tuition
              </label>
              <input
                type="number"
                name="tuition"
                value={incomeExpenseState?.tuition}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.tuition}
              </span>
            </Col>
          )}
          {ConfiguresIncome?.dependent_child_care_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Dependent/Child Care
              </label>
              <input
                type="number"
                name="dependent_child_care"
                value={incomeExpenseState?.dependent_child_care}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.dependent_child_care}
              </span>
            </Col>
          )}
          {ConfiguresIncome?.student_loans_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Student Loans
              </label>
              <input
                type="number"
                name="student_loans"
                value={incomeExpenseState?.student_loans}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.student_loans}
              </span>
            </Col>
          )}
          <Col lg="12" className="my-2">
            <h6>Insurance (Monthly)</h6>
          </Col>
          {ConfiguresIncome?.medical_visible && (
            <Col lg="6" className="my-2">
              <Row className="d-flex">
                <Col sm="6">
                  <label htmlFor="" className="form-label2 m-0">
                    Medical
                  </label>
                  <input
                    type="number"
                    name="insurance_medical_amount"
                    value={incomeExpenseState?.insurance_medical_amount}
                    onChange={handleChange}
                    placeholder="$"
                    className="form-control"
                    onBlur={handleSubmitBlur}
                  />
                  <span className="note error_note py-2 m-0 text-danger">
                    {errors?.insurance_medical_amount}
                  </span>
                </Col>
                <Col sm="6" className="doc-select">
                  <label htmlFor="" className="form-label2 m-0">
                    Is this deducted from paycheck?
                  </label>
                  <Select
                    className="demo-simple-select-label mt-2"
                    id="demo-simple-select"
                    name="insurance_medical_paycheck"
                    value={incomeExpenseState?.insurance_medical_paycheck}
                    onChange={handleChange}
                    MenuProps={YesNo}
                    onBlur={handleSubmitBlur}
                  >
                    {YesNo &&
                      YesNo.length > 0 &&
                      YesNo.map((item, idx) => {
                        return (
                          <MenuItem key={item?.id} value={item?.id}>
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                  </Select>

                  <span className="note error_note py-2 m-0 text-danger">
                    {errors?.insurance_medical_paycheck}
                  </span>
                </Col>
              </Row>
            </Col>
          )}
          {ConfiguresIncome?.vision_visible && (
            <Col lg="6" className="my-2">
              <Row className="d-flex">
                <Col sm="6">
                  <label htmlFor="" className="form-label2 m-0">
                    Vision
                  </label>
                  <input
                    type="number"
                    name="insurance_vision_amount"
                    value={incomeExpenseState?.insurance_vision_amount}
                    onChange={handleChange}
                    placeholder="$"
                    className="form-control"
                    onBlur={handleSubmitBlur}
                  />
                  <span className="note error_note py-2 m-0 text-danger">
                    {errors?.insurance_vision_amount}
                  </span>
                </Col>
                <Col sm="6" className="doc-select">
                  <label htmlFor="" className="form-label2 m-0">
                    Is this deducted from paycheck?
                  </label>
                  <Select
                    className="demo-simple-select-label mt-2"
                    id="demo-simple-select"
                    name="insurance_vision_paycheck"
                    value={incomeExpenseState?.insurance_vision_paycheck}
                    onChange={handleChange}
                    MenuProps={YesNo}
                    onBlur={handleSubmitBlur}
                  >
                    {YesNo &&
                      YesNo.length > 0 &&
                      YesNo.map((item, idx) => {
                        return (
                          <MenuItem key={item?.id} value={item?.id}>
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                  </Select>

                  <span className="note error_note py-2 m-0 text-danger">
                    {errors?.insurance_vision_paycheck}
                  </span>
                </Col>
              </Row>
            </Col>
          )}
          {ConfiguresIncome?.dental_visible && (
            <Col lg="6" className="my-2">
              <Row className="d-flex">
                <Col sm="6">
                  <label htmlFor="" className="form-label2 m-0">
                    Dental
                  </label>
                  <input
                    type="number"
                    name="insurance_dental_amount"
                    value={incomeExpenseState?.insurance_dental_amount}
                    onChange={handleChange}
                    placeholder="$"
                    className="form-control"
                    onBlur={handleSubmitBlur}
                  />
                  <span className="note error_note py-2 m-0 text-danger">
                    {errors?.insurance_dental_amount}
                  </span>
                </Col>
                <Col sm="6" className="doc-select">
                  <label htmlFor="" className="form-label2 m-0">
                    Is this deducted from paycheck?
                  </label>
                  <Select
                    className="demo-simple-select-label mt-2"
                    id="demo-simple-select"
                    name="insurance_dental_paycheck"
                    value={incomeExpenseState?.insurance_dental_paycheck}
                    onChange={handleChange}
                    MenuProps={YesNo}
                    onBlur={handleSubmitBlur}
                  >
                    {YesNo &&
                      YesNo.length > 0 &&
                      YesNo.map((item, idx) => {
                        return (
                          <MenuItem key={item?.id} value={item?.id}>
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                  </Select>

                  <span className="note error_note py-2 m-0 text-danger">
                    {errors?.insurance_dental_paycheck}
                  </span>
                </Col>
              </Row>
            </Col>
          )}
          {ConfiguresIncome?.disability_visible && (
            <Col lg="6" className="my-2">
              <Row className="d-flex">
                <Col sm="6">
                  <label htmlFor="" className="form-label2 m-0">
                    Disability
                  </label>
                  <input
                    type="number"
                    name="insurance_disability_amount"
                    value={incomeExpenseState?.insurance_disability_amount}
                    onChange={handleChange}
                    placeholder="$"
                    className="form-control"
                    onBlur={handleSubmitBlur}
                  />
                  <span className="note error_note py-2 m-0 text-danger">
                    {errors?.insurance_disability_amount}
                  </span>
                </Col>
                <Col sm="6" className="doc-select">
                  <label htmlFor="" className="form-label2 m-0">
                    Is this deducted from paycheck?
                  </label>
                  <Select
                    className="demo-simple-select-label mt-2"
                    id="demo-simple-select"
                    name="insurance_disability_paycheck"
                    value={incomeExpenseState?.insurance_disability_paycheck}
                    onChange={handleChange}
                    MenuProps={YesNo}
                    onBlur={handleSubmitBlur}
                  >
                    {YesNo &&
                      YesNo.length > 0 &&
                      YesNo.map((item, idx) => {
                        return (
                          <MenuItem key={item?.id} value={item?.id}>
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                  </Select>

                  <span className="note error_note py-2 m-0 text-danger">
                    {errors?.insurance_disability_paycheck}
                  </span>
                </Col>
              </Row>
            </Col>
          )}
          {ConfiguresIncome?.life_visible && (
            <Col lg="6" className="my-2">
              <Row className="d-flex">
                <Col sm="6">
                  <label htmlFor="" className="form-label2 m-0">
                    Life
                  </label>
                  <input
                    type="number"
                    name="insurance_life_amount"
                    value={incomeExpenseState?.insurance_life_amount}
                    onChange={handleChange}
                    placeholder="$"
                    className="form-control"
                    onBlur={handleSubmitBlur}
                  />
                  <span className="note error_note py-2 m-0 text-danger">
                    {errors?.insurance_life_amount}
                  </span>
                </Col>
                <Col sm="6" className="doc-select">
                  <label htmlFor="" className="form-label2 m-0">
                    Is this deducted from paycheck?
                  </label>
                  <Select
                    className="demo-simple-select-label mt-2"
                    id="demo-simple-select"
                    name="insurance_life_paycheck"
                    value={incomeExpenseState?.insurance_life_paycheck}
                    onChange={handleChange}
                    MenuProps={YesNo}
                    onBlur={handleSubmitBlur}
                  >
                    {YesNo &&
                      YesNo.length > 0 &&
                      YesNo.map((item, idx) => {
                        return (
                          <MenuItem key={item?.id} value={item?.id}>
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                  </Select>

                  <span className="note error_note py-2 m-0 text-danger">
                    {errors?.insurance_life_paycheck}
                  </span>
                </Col>
              </Row>
            </Col>
          )}
          {ConfiguresIncome?.surrender_visible && (
            <Col lg="6" className="my-2">
              <Row className="d-flex">
                <Col sm="6">
                  <label htmlFor="" className="form-label2 m-0">
                    Surrender Value
                  </label>
                  <input
                    type="number"
                    name="insurance_surrender_amount"
                    value={incomeExpenseState?.insurance_surrender_amount}
                    onChange={handleChange}
                    placeholder="$"
                    className="form-control"
                    onBlur={handleSubmitBlur}
                  />
                  <span className="note error_note py-2 m-0 text-danger">
                    {errors?.insurance_surrender_amount}
                  </span>
                </Col>
                <Col sm="6" className="doc-select">
                  <label htmlFor="" className="form-label2 m-0">
                    Is this deducted from paycheck?
                  </label>
                  <Select
                    className="demo-simple-select-label mt-2"
                    id="demo-simple-select"
                    name="insurance_surrender_paycheck"
                    value={incomeExpenseState?.insurance_surrender_paycheck}
                    onChange={handleChange}
                    MenuProps={YesNo}
                    onBlur={handleSubmitBlur}
                  >
                    {YesNo &&
                      YesNo.length > 0 &&
                      YesNo.map((item, idx) => {
                        return (
                          <MenuItem key={item?.id} value={item?.id}>
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                  </Select>

                  <span className="note error_note py-2 m-0 text-danger">
                    {errors?.insurance_surrender_paycheck}
                  </span>
                </Col>
              </Row>
            </Col>
          )}
          <Col lg="12" className="my-2">
            <h6>Utilities (Monthly)</h6>
          </Col>
          {ConfiguresIncome?.gas_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Gas Expense
              </label>
              <input
                type="number"
                name="gas"
                value={incomeExpenseState?.gas}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.gas}
              </span>
            </Col>
          )}
          {ConfiguresIncome?.vehicle_insurance_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Vehicle Insurance
              </label>
              <input
                type="number"
                name="vehicle_insurance"
                value={incomeExpenseState?.vehicle_insurance}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.vehicle_insurance}
              </span>
            </Col>
          )}
          {ConfiguresIncome?.electric_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Electric Expense
              </label>
              <input
                type="number"
                name="electric"
                value={incomeExpenseState?.electric}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.electric}
              </span>
            </Col>
          )}
          {ConfiguresIncome?.water_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Water Expense
              </label>
              <input
                type="number"
                name="water"
                value={incomeExpenseState?.water}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.water}
              </span>
            </Col>
          )}
          {ConfiguresIncome?.maintenance_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Maintenance Expense
              </label>
              <input
                type="number"
                name="maintenance"
                value={incomeExpenseState?.maintenance}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.maintenance}
              </span>
            </Col>
          )}
          {ConfiguresIncome?.trash_sewer_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Trash/Sewer Expense
              </label>
              <input
                type="number"
                name="trash_sewer"
                value={incomeExpenseState?.trash_sewer}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.trash_sewer}
              </span>
            </Col>
          )}
          {ConfiguresIncome?.telephone_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Telephone Expense
              </label>
              <input
                type="number"
                name="telephone"
                value={incomeExpenseState?.telephone}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.telephone}
              </span>
            </Col>
          )}
          {ConfiguresIncome?.cell_phone_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Cell Phone Expense
              </label>
              <input
                type="number"
                name="cell_phone"
                value={incomeExpenseState?.cell_phone}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.cell_phone}
              </span>
            </Col>
          )}
          {ConfiguresIncome?.internet_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Internet Expense
              </label>
              <input
                type="number"
                name="internet"
                value={incomeExpenseState?.internet}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.internet}
              </span>
            </Col>
          )}
          {ConfiguresIncome?.cable_satellite_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Cable/Satellite TV Expense
              </label>
              <input
                type="number"
                name="cable_satellite"
                value={incomeExpenseState?.cable_satellite}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.cable_satellite}
              </span>
            </Col>
          )}
          <Col lg="12" className="my-2">
            <h6>Other Living Expenses</h6>
          </Col>
          {ConfiguresIncome?.food_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Food Expense
              </label>
              <input
                type="number"
                name="food"
                value={incomeExpenseState?.food}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.food}
              </span>
            </Col>
          )}
          {ConfiguresIncome?.gardening_landscaping_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Gardening/Landscaping Expense
              </label>
              <input
                type="number"
                name="gardening_landscaping"
                value={incomeExpenseState?.gardening_landscaping}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.gardening_landscaping}
              </span>
            </Col>
          )}
          {ConfiguresIncome?.transportation_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Transportation (other than above: tolls,bus,etc)
              </label>
              <input
                type="number"
                name="transportation"
                value={incomeExpenseState?.transportation}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.transportation}
              </span>
            </Col>
          )}
          {ConfiguresIncome?.housecleaning_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Housecleaning Expense
              </label>
              <input
                type="number"
                name="housecleaning"
                value={incomeExpenseState?.housecleaning}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.housecleaning}
              </span>
            </Col>
          )}
          {ConfiguresIncome?.other_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Other Expense
              </label>
              <input
                type="number"
                name="other"
                value={incomeExpenseState?.other}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.other}
              </span>
            </Col>
          )}

          {extraFields &&
            extraFields.length > 0 &&
            extraFields.map((item, idx) => {
              return (
                <>
                  {ConfiguresIncome &&
                    ConfiguresIncome[
                      item?.title.toLowerCase().split(" ").join("_")
                    ] + "_visible" && (
                      <>
                        {(item?.type === "text" ||
                          item?.type === "number" ||
                          item?.type === "email" ||
                          item?.type === "password" ||
                          item?.type === "date" ||
                          item?.type === "time" ||
                          item?.type === "date-time-local") && (
                          <Col lg="6" className="my-2" key={idx}>
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize"
                            >
                              {item?.title}
                            </label>
                            <input
                              type={item?.type}
                              name={item?.title
                                .toLowerCase()
                                .split(" ")
                                .join("_")}
                              value={
                                (incomeExpenseState &&
                                  incomeExpenseState[
                                    item?.title
                                      .toLowerCase()
                                      .split(" ")
                                      .join("_")
                                  ]) ||
                                ""
                              }
                              onChange={handleChange}
                              placeholder={item?.placeholder}
                              className="form-control"
                              onBlur={handleSubmitBlur}
                            />

                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}

                        {item?.type === "radio" && (
                          <Col
                            lg="6"
                            className="my-2 dynamic-checkbox"
                            key={idx}
                          >
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize w-100"
                            >
                              {item?.title}
                            </label>
                            {item?.option &&
                              item?.option.length > 0 &&
                              item?.option.map((s, i) => {
                                return (
                                  <>
                                    <input
                                      type={item?.type}
                                      name={item?.title
                                        .toLowerCase()
                                        .split(" ")
                                        .join("_")}
                                      value={
                                        (incomeExpenseState &&
                                          incomeExpenseState[
                                            item?.title
                                              .toLowerCase()
                                              .split(" ")
                                              .join("_")
                                          ]) ||
                                        ""
                                      }
                                      checked={
                                        (incomeExpenseState &&
                                          incomeExpenseState[
                                            item?.title
                                              .toLowerCase()
                                              .split(" ")
                                              .join("_")
                                          ] == s.title) ||
                                        ""
                                      }
                                      onChange={() =>
                                        handleRadioChange(
                                          item?.title
                                            .toLowerCase()
                                            .split(" ")
                                            .join("_"),
                                          s.title
                                        )
                                      }
                                      className=""
                                      onBlur={handleSubmitBlur}
                                    />
                                    <label
                                      htmlFor=""
                                      className="form-label2 m-0 text-capitalize"
                                    >
                                      {s.title}
                                    </label>
                                  </>
                                );
                              })}

                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}

                        {item?.type === "checkbox" && (
                          <Col
                            lg="6"
                            className="my-2 dynamic-checkbox"
                            key={idx}
                          >
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize w-100"
                            >
                              {item?.title}
                            </label>
                            {item?.option &&
                              item?.option.length > 0 &&
                              item?.option.map((s, i) => {
                                return (
                                  <>
                                    <input
                                      type={item?.type}
                                      name={item?.title
                                        .toLowerCase()
                                        .split(" ")
                                        .join("_")}
                                      value={
                                        (incomeExpenseState &&
                                          incomeExpenseState[
                                            item?.title
                                              .toLowerCase()
                                              .split(" ")
                                              .join("_")
                                          ]) ||
                                        ""
                                      }
                                      checked={
                                        (incomeExpenseState &&
                                          incomeExpenseState[
                                            item?.title
                                              .toLowerCase()
                                              .split(" ")
                                              .join("_")
                                          ]?.includes(s.title)) ||
                                        []
                                      }
                                      onChange={() =>
                                        handleCheckChange(
                                          item?.title
                                            .toLowerCase()
                                            .split(" ")
                                            .join("_"),
                                          s.title
                                        )
                                      }
                                      className=""
                                      onBlur={handleSubmitBlur}
                                    />
                                    <label
                                      htmlFor=""
                                      className="form-label2 m-0 text-capitalize"
                                    >
                                      {s.title}
                                    </label>
                                  </>
                                );
                              })}

                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}

                        {item?.type === "select" && (
                          <Col lg="6" className="my-2 doc-select" key={idx}>
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize w-100"
                            >
                              {item?.title}
                            </label>
                            <Select
                              className="demo-simple-select-label"
                              id="demo-simple-select"
                              name={item?.title
                                .toLowerCase()
                                .split(" ")
                                .join("_")}
                              value={
                                (incomeExpenseState &&
                                  incomeExpenseState[
                                    item?.title
                                      .toLowerCase()
                                      .split(" ")
                                      .join("_")
                                  ]) ||
                                ""
                              }
                              onBlur={handleSubmitBlur}
                              onChange={(e) =>
                                handleSelectChange(
                                  e,
                                  item?.title.toLowerCase().split(" ").join("_")
                                )
                              }
                              MenuProps={item?.option}
                            >
                              {item?.option &&
                                item?.option.length > 0 &&
                                item?.option.map((s, i) => {
                                  return (
                                    <MenuItem key={s.title} value={s.title}>
                                      {s.title}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}

                        {item?.type === "multiselect" && (
                          <Col lg="6" className="my-2 doc-select" key={idx}>
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize w-100"
                            >
                              {item?.title}
                            </label>
                            <Select
                              className="demo-simple-select-label"
                              id="demo-simple-select"
                              name={item?.title
                                .toLowerCase()
                                .split(" ")
                                .join("_")}
                              value={
                                (incomeExpenseState &&
                                  incomeExpenseState[
                                    item?.title
                                      .toLowerCase()
                                      .split(" ")
                                      .join("_")
                                  ]) ||
                                ""
                              }
                              onBlur={handleSubmitBlur}
                              multiple
                              onChange={(e) =>
                                handleSelectChange(
                                  e,
                                  item?.title.toLowerCase().split(" ").join("_")
                                )
                              }
                              MenuProps={item?.option}
                            >
                              {item?.option &&
                                item?.option.length > 0 &&
                                item?.option.map((s, i) => {
                                  return (
                                    <MenuItem key={s.title} value={s.title}>
                                      {s.title}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}
                      </>
                    )}
                </>
              );
            })}

          <Col lg="12" className="my-2">
            <div className="pt-4 btn-wrp d-flex algign-items-center">
              <Button
                //   disabled={errors?.disabled}
                onClick={handleSubmitForm}
                className={`d-inline-flex align-items-center justify-content-center me-2`}
              >
                Submit
              </Button>
              <Button
                //   disabled={errors?.disabled}
                onClick={() => handleBackBtn("assets")}
                className={`back-form-btnn d-inline-flex align-items-center justify-content-center me-2`}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default IncomeExpenseStatement;
