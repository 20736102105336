import React, { useState } from "react";
import AccountLayout from "../AccountLayout";
import { Container, Form, Row, Button, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ReactPinField from "react-pin-field";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  checkForgotOtp,
  forgotPassOtp,
  passwordRemind,
} from "../../../store/actions";
import { isEmail, isEmpty } from "validator";

const PasswordRemindForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [field, setField] = useState("forgot");
  const [data, setData] = useState({
    email: "",
  });
  const [error, setError] = useState("");
  const [otp, setOtp] = useState("");

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    if (name == "email") {
      if (isEmpty(value)) {
        setError("Required");
        setData((prev) => ({
          ...prev,
          [name]: value,
        }));
      } else if (!isEmail(value)) {
        setError("Invalid Email");
        setData((prev) => ({
          ...prev,
          [name]: value,
        }));
      } else {
        setError("");
        setData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else {
      setData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleEmailSend = (e) => {
    e.preventDefault();
    const callback = (res) => {
      if (res.data) {
        toast.success(res.detail);
        navigate("/client/login");
      } else {
        toast.error(res.detail);
      }
    };

    dispatch(passwordRemind(data, callback));
  };

  return (
    <>
      <AccountLayout>
        <div className="account-card px-4 py-4 bg-white">
          <div className="head-div mb-3">
            <h5 className="mb-0 pb-2 head text-center">
              Enter your email to get Email of Password Remind
            </h5>
          </div>
          <Form autoComplete="off">
            <Row>
              <Col lg="12" className="my-3">
                <label className="form-label m-0">Your email</label>
                <Form.Control
                  type="email"
                  placeholder="Your email"
                  name="email"
                  autoComplete="off"
                  onChange={handleEmailChange}
                />
                <span className="note error_note py-2 m-0 text-danger">
                  {error}
                </span>
              </Col>
              <Col lg="12" className="text-end">
                <Link to="/client/login" className="form-link">
                  Back to login
                </Link>
              </Col>
              <Col lg="12" className="my-3 btn-wrap">
                <Button
                  className="w-100 text-white submit-btn"
                  onClick={handleEmailSend}
                  disabled={data.email == "" || error !== "" ? true : false}
                >
                  Send
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </AccountLayout>
    </>
  );
};
export default PasswordRemindForm;
