// ================= FAQ_LIST =================== //

export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL";
export const GET_FINANCE_BY_ID = "GET_FINANCE_BY_ID";
export const GET_FINANCE_BY_ID_SUCCESS = "GET_FINANCE_BY_ID_SUCCESS";
export const GET_FINANCE_BY_ID_FAIL = "GET_FINANCE_BY_ID_FAIL";

// // ================= ADD_FAQ =================== //

// export const ADD_FAQ = "ADD_FAQ";
// export const ADD_FAQ_SUCCESS = "ADD_FAQ_SUCCESS";
// export const ADD_FAQ_FAIL = "ADD_FAQ_FAIL";

// // ================= FAQ_DETAILS =================== //
// export const FAQ_DETAILS = "FAQ_DETAILS";
// export const FAQ_DETAILS_SUCCESS = "FAQ_DETAILS_SUCCESS";
// export const FAQ_DETAILS_FAIL = "FAQ_DETAILS_FAIL";

// // ================= EDIT_FAQ =================== //

// export const EDIT_FAQ = "EDIT_FAQ";
// export const EDIT_FAQ_SUCCESS = "EDIT_FAQ_SUCCESS";
// export const EDIT_FAQ_FAIL = "EDIT_FAQ_FAIL";

// // ================= DELETE_FAQ =================== //

// export const DELETE_FAQ = "DELETE_FAQ";
// export const DELETE_FAQ_SUCCESS = "DELETE_FAQ_SUCCESS";
// export const DELETE_FAQ_FAIL = "DELETE_FAQ_FAIL";
