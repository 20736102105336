import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import CKEditor from "react-ckeditor-component";
import { useDispatch, useSelector } from "react-redux";
import { addTasks, getSingleTasks, updateTasks } from "../../../store/actions";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loading from "../../../Common/extra/loading";
import { MenuItem, Select } from "@material-ui/core";
import { DatePicker, Space } from "antd";
import locale from "antd/es/date-picker/locale/en_US";
import dayjs from "dayjs";

const AddEditTasksIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { noteId } = useParams();
  console.log(
    "🚀 ~ file: AddEditTasks.jsx:18 ~ AddEditTasksIndex ~ noteId:",
    noteId
  );
  const [loading, setLoading] = useState(false);
  const user_id = localStorage && localStorage.getItem("id");
  const [state, setState] = useState({
    id: "",
    receiver_id: "",
    sender_id: "",
    task_content: "",
    sender_type: "user",
    type: "sent",
    status: "1",
    title: "",
    due_date: "",
    reminder_date: "",
    reminder_time: "",
    sent_task_status: "Reminder to be done",
  });
  const [disabled, setDisabled] = useState(true);
  const taskData = useSelector((s) => s?.Customers?.singleTask?.data);

  useEffect(() => {
    if (!noteId && !taskData) {
      if (state.task_content == "" || state.title == "") {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [state]);

  useEffect(() => {
    if (noteId && taskData) {
      if (state.title === taskData?.title) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [noteId, taskData, state]);

  // useEffect(() => {
  //   dispatch(getSubadminList());
  // }, [dispatch]);

  useEffect(() => {
    if (noteId && taskData) {
      setState((pre) => ({
        ...pre,
        task_content: taskData?.task_content,
        id: taskData?.id,
        title: taskData?.title,
        due_date: taskData?.due_date,
        reminder_date: taskData?.reminder_date,
        reminder_time: taskData?.reminder_time,
      }));
    }
  }, [taskData, noteId]);

  useEffect(() => {
    if (user_id) {
      setState((pre) => ({ ...pre, sender_id: user_id }));
      dispatch(getSingleTasks(noteId));
    }
  }, [user_id]);

  const onChange = (evt) => {
    const newContent = evt.editor.getData();
    setState((prevState) => ({
      ...prevState,
      task_content: newContent,
    }));
  };

  const handleSubmit = (e, type) => {
    e.preventDefault();
    setLoading(true);

    const callback = (res) => {
      setLoading(false);
      if (res.data) {
        toast.success("Added Successfully");
        setDisabled(true);
        if (type == "exit") {
          navigate(`/client/tasks`);
        }
      } else {
      }
    };

    const updateCallBack = (res) => {
      setLoading(false);
      if (res.data) {
        toast.success("Updated Successfully");
        setDisabled(true);
        if (type == "exit") {
          navigate(`/client/tasks`);
        }
      } else {
      }
    };
    if (noteId) {
      dispatch(
        updateTasks(
          {
            ...state,
            receiver_type: "admin",
          },
          updateCallBack
        )
      );
    } else {
      dispatch(
        addTasks(
          {
            ...state,
            receiver_type: "admin",
          },
          callback
        )
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState((pre) => ({ ...pre, [name]: value }));
  };

  const handlePickerChange = (e, dat, name) => {
    setState((pre) => ({ ...pre, [name]: dat }));
  };

  return (
    <>
      <section className="dashboard py-2">
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="bg-white px-4 py-3 card-box rounded">
                <Form>
                  <Row>
                    <Col lg="6" className="my-2">
                      <label htmlFor="" className="form-label2 m-0">
                        Subject
                      </label>
                      <input
                        type="text"
                        name="title"
                        value={state?.title}
                        onChange={handleChange}
                        placeholder=""
                        className="form-control"
                      />
                    </Col>
                    <Col lg="6" className="my-2 due-date-field">
                      <label htmlFor="" className="d-block form-label2 m-0">
                        Due Date
                      </label>
                      <DatePicker
                        format={"YYYY-MM-DD"}
                        locale={locale}
                        inputReadOnly={true}
                        value={state.due_date ? dayjs(state.due_date) : null}
                        onChange={(e, dat) =>
                          handlePickerChange(e, dat, "due_date")
                        }
                        defaultValue={dayjs}
                        allowClear={false}
                        picker="date"
                        className="date-picker-width"
                      />
                    </Col>
                    <Col lg="6" className="my-2 due-date-field">
                      <label htmlFor="" className="d-block form-label2 m-0">
                        Reminder Date
                      </label>
                      <DatePicker
                        format={"YYYY-MM-DD"}
                        locale={locale}
                        inputReadOnly={true}
                        value={
                          state.reminder_date
                            ? dayjs(state.reminder_date)
                            : null
                        }
                        onChange={(e, dat) =>
                          handlePickerChange(e, dat, "reminder_date")
                        }
                        defaultValue={dayjs}
                        allowClear={false}
                        picker="date"
                        className="date-picker-width"
                      />
                    </Col>
                    <Col lg="6" className="my-2">
                      <label htmlFor="" className="form-label2 m-0">
                        Reminder Time
                      </label>
                      <input
                        type="time"
                        name="reminder_time"
                        value={state?.reminder_time}
                        onChange={handleChange}
                        placeholder=""
                        className="form-control"
                      />
                    </Col>
                    <Col lg="12" className="my-2">
                      <label for="" className="form-label2 m-0">
                        Add your comment
                      </label>
                      {loading && <Loading className={"cstmLoader"} />}
                      <CKEditor
                        activeClass="p10"
                        content={state.task_content}
                        events={{
                          change: onChange,
                          disabled: true,
                        }}
                      />
                    </Col>
                  </Row>
                </Form>
                <div className="d-flex subscribe-back due-submit-btn">
                  <Button
                    onClick={(e) => handleSubmit(e, "save")}
                    className="d-flex align-items-center justify-content-center me-1"
                    disabled={disabled}
                  >
                    Save
                  </Button>
                  <Button
                    onClick={(e) => handleSubmit(e, "exit")}
                    className="d-flex align-items-center justify-content-center"
                    disabled={disabled}
                  >
                    Save & Exit
                  </Button>
                  <Link
                    className="bck-btn-link d-flex btn-2 border align-items-center justify-content-center me-2"
                    to={"/client/tasks"}
                  >
                    Back
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default AddEditTasksIndex;
