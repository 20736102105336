import React, { Fragment } from "react";
import { Card, CardBody } from "reactstrap";
import gif from "../../assest/images/loader.gif";

const NotFound = ({ css, className, style }) => (
  <>
    <div
      className={`${className && className} ${
        style ? style : "text-center h-100 w-100 loader-admin"
      }`}
      style={css && css}
    >
      <img alt="No data available" src={gif} />
    </div>
  </>
);

export default NotFound;
