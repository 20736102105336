import React, { useState } from "react";
import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  TextSearch,
  Annotation,
  FormFields,
  FormDesigner,
  Inject,
} from "@syncfusion/ej2-react-pdfviewer";
import PdfFile from "../../../pdfviewer/scorp.pdf";
import { useEffect } from "react";

function ScorpForm() {
  let viewer;

  const [file, setFile] = useState("");
  useEffect(() => {
    getBase64();
  }, []);

  async function getBase64() {
    const response = await fetch(PdfFile); // Replace 'sample.pdf' with the actual path to your PDF file in the public folder
    const file = await response.blob();
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log("reader=======>>>", reader.result);
      setFile(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  return (
    <div>
      <div className="control-section">
        <PdfViewerComponent
          id="container"
          ref={(scope) => {
            viewer = scope;
          }}
          documentPath={file}
          serviceUrl="https://services.syncfusion.com/react/production/api/pdfviewer"
          validateFormFields={validateFormFields}
          enableFormFieldsValidation={true}
          showNotificationDialog={false}
          style={{ height: "640px" }}
          checkBoxFieldSettings={{ isPrint: true }}
        >
          {console.log("filefilefile", file)}
          <Inject
            services={[
              Toolbar,
              Magnification,
              Navigation,
              LinkAnnotation,
              BookmarkView,
              ThumbnailView,
              Print,
              TextSelection,
              TextSearch,
              Annotation,
              FormFields,
              FormDesigner,
            ]}
          />
        </PdfViewerComponent>
      </div>
    </div>
  );
  function validateFormFields(args) {
    var errorMessage = "Required Field(s): ";
    var forms = viewer.formFieldCollections;
    var flag = false;
    var radioGroupName = "";
    for (var i = 0; i < forms.length; i++) {
      var text = "";
      if (forms[i].isRequired == true) {
        if (
          forms[i].type.toString() == "Checkbox" &&
          forms[i].isChecked == false
        ) {
          text = forms[i].name;
        } else if (forms[i].type == "RadioButton" && flag == false) {
          radioGroupName = forms[i].name;
          if (forms[i].isSelected == true) flag = true;
        } else if (
          forms[i].type.toString() != "Checkbox" &&
          forms[i].type != "RadioButton" &&
          forms[i].value == ""
        ) {
          text = forms[i].name;
        }
        if (text != "") {
          if (errorMessage == "Required Field(s): ") {
            errorMessage += text;
          } else {
            errorMessage += ", " + text;
          }
        }
      }
    }
    if (!flag && radioGroupName != "") {
      if (errorMessage == "Required Field(s): ") errorMessage += radioGroupName;
      else errorMessage += ", " + radioGroupName;
    }
    if (errorMessage != "Required Field(s): ") {
      viewer.showNotificationPopup(errorMessage);
    }
  }
}
export default ScorpForm;
