import { MenuItem, Select } from "@material-ui/core";
import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

const PayPer = [
  {
    name: "Weekly",
    id: "Weekly",
  },
  {
    name: "Bi-Weekly",
    id: "Bi-Weekly",
  },
  {
    name: "Monthly",
    id: "Monthly",
  },
];
const YesNo = [
  {
    name: "Yes",
    id: "Yes",
  },
  {
    name: "No",
    id: "No",
  },
];

const PlainDef = [
  {
    name: "Plaintiff",
    id: "Plaintiff",
  },
  {
    name: "Defendent",
    id: "Defendent",
  },
];

const OtherFinancialInfo = ({
  otherFinanceInfo,
  setOtherFinanceInfo,
  errors,
  setError,
  handleTabChange,
  handleSubmitBlur,
  handleBackBtn,
  Configures,
  extraFields,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    // const Error = { ...errors };

    // switch (name) {
    //   case "are_you_currently_a_party_to_law_suit":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "plaintiff_defendent":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "location_of_filing":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "case_no":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "subject_of_suit":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "have_you_ever_filed_for_bankcrupty_protection":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "date_of_filing":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "date_of_discharge":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "petition_no":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "location":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "increase_decrease_in_income":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "beneficiary_to_any_trust_estate_life_insurance":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   default:
    //     break;
    // }
    setOtherFinanceInfo((pre) => ({
      ...pre,
      [name]: value,
    }));
    // setError((pre) => ({ ...pre, ...Error }));
  };

  const handleRadioChange = (name, value) => {
    setOtherFinanceInfo((pre) => ({ ...pre, [name]: value }));
  };

  const handleCheckChange = (name, value) => {
    if (otherFinanceInfo[name]?.includes(value)) {
      setOtherFinanceInfo((pre) => ({
        ...pre,
        [name]: pre[name]?.filter((item) => {
          return item !== value;
        }),
      }));
    } else {
      setOtherFinanceInfo((pre) => ({
        ...pre,
        [name]: pre[name] ? [...pre[name], value] : [value],
      }));
    }
    // handleSubmitBlur();
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setOtherFinanceInfo((pre) => ({ ...pre, [name]: value }));
  };
  return (
    <div className="bg-white card-box px-4 py-3 rounded">
      <Form>
        <Row className="align-items-start">
          {Configures?.are_you_currently_a_party_to_law_suit_visible && (
            <Col lg="12" className="my-2 doc-select">
              <label htmlFor="" className="form-label2 m-0">
                Are you currently a party to lawsuit?
              </label>
              <Select
                className="demo-simple-select-label mt-2"
                id="demo-simple-select"
                name="are_you_currently_a_party_to_law_suit"
                value={otherFinanceInfo?.are_you_currently_a_party_to_law_suit}
                onChange={handleChange}
                MenuProps={YesNo}
                onBlur={handleSubmitBlur}
              >
                {YesNo &&
                  YesNo.length > 0 &&
                  YesNo.map((item, idx) => {
                    return (
                      <MenuItem key={item?.id} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    );
                  })}
              </Select>

              <span className="note error_note py-2 m-0 text-danger">
                {errors?.are_you_currently_a_party_to_law_suit}
              </span>
            </Col>
          )}
          {otherFinanceInfo?.are_you_currently_a_party_to_law_suit == "Yes" && (
            <>
              {Configures?.law_suit_yes_plaintiff_defendent_visible && (
                <Col lg="6" className="my-2 doc-select">
                  <label htmlFor="" className="form-label2 m-0">
                    Plaintiff or Defendent?
                  </label>
                  <Select
                    className="demo-simple-select-label mt-2"
                    id="demo-simple-select"
                    name="law_suit_yes_plaintiff_defendent"
                    value={otherFinanceInfo?.law_suit_yes_plaintiff_defendent}
                    onChange={handleChange}
                    MenuProps={PlainDef}
                    onBlur={handleSubmitBlur}
                  >
                    {PlainDef &&
                      PlainDef.length > 0 &&
                      PlainDef.map((item, idx) => {
                        return (
                          <MenuItem key={item?.id} value={item?.id}>
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <span className="note error_note py-2 m-0 text-danger">
                    {errors?.law_suit_yes_plaintiff_defendent}
                  </span>
                </Col>
              )}
              {Configures?.law_suit_yes_location_of_filing_visible && (
                <Col lg="6" className="my-2">
                  <label htmlFor="" className="form-label2 m-0">
                    Location of Filing
                  </label>
                  <input
                    type="text"
                    name="law_suit_yes_location_of_filing"
                    value={otherFinanceInfo?.law_suit_yes_location_of_filing}
                    onChange={handleChange}
                    placeholder=""
                    className="form-control"
                    onBlur={handleSubmitBlur}
                  />
                  <span className="note error_note py-2 m-0 text-danger">
                    {errors?.law_suit_yes_location_of_filing}
                  </span>
                </Col>
              )}
              {Configures?.law_suit_yes_case_no_visible && (
                <Col lg="6" className="my-2">
                  <label htmlFor="" className="form-label2 m-0">
                    Case No.
                  </label>
                  <input
                    type="text"
                    name="law_suit_yes_case_no"
                    value={otherFinanceInfo?.law_suit_yes_case_no}
                    onChange={handleChange}
                    placeholder=""
                    className="form-control"
                    onBlur={handleSubmitBlur}
                  />
                  <span className="note error_note py-2 m-0 text-danger">
                    {errors?.law_suit_yes_case_no}
                  </span>
                </Col>
              )}
              {Configures?.law_suit_yes_subject_of_suit_visible && (
                <Col lg="6" className="my-2">
                  <label htmlFor="" className="form-label2 m-0">
                    Subject of Suit
                  </label>
                  <input
                    type="text"
                    name="law_suit_yes_subject_of_suit"
                    value={otherFinanceInfo?.law_suit_yes_subject_of_suit}
                    onChange={handleChange}
                    placeholder=""
                    className="form-control"
                    onBlur={handleSubmitBlur}
                  />
                  <span className="note error_note py-2 m-0 text-danger">
                    {errors?.law_suit_yes_subject_of_suit}
                  </span>
                </Col>
              )}
            </>
          )}
          {Configures?.have_you_ever_filed_for_bankcrupty_protection_visible && (
            <Col lg="12" className="my-2 doc-select">
              <label htmlFor="" className="form-label2 m-0">
                Filed for Bankrupty protection?
              </label>
              <Select
                className="demo-simple-select-label mt-2"
                id="demo-simple-select"
                name="have_you_ever_filed_for_bankcrupty_protection"
                value={
                  otherFinanceInfo?.have_you_ever_filed_for_bankcrupty_protection
                }
                onChange={handleChange}
                MenuProps={YesNo}
                onBlur={handleSubmitBlur}
              >
                {YesNo &&
                  YesNo.length > 0 &&
                  YesNo.map((item, idx) => {
                    return (
                      <MenuItem key={item?.id} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    );
                  })}
              </Select>

              <span className="note error_note py-2 m-0 text-danger">
                {errors?.have_you_ever_filed_for_bankcrupty_protection}
              </span>
            </Col>
          )}
          {otherFinanceInfo?.have_you_ever_filed_for_bankcrupty_protection ==
            "Yes" && (
            <>
              {Configures?.bankcrupty_protection_yes_date_of_filing_visible && (
                <Col lg="6" className="my-2">
                  <label htmlFor="" className="form-label2 m-0">
                    Date of filing
                  </label>
                  <input
                    type="date"
                    name="bankcrupty_protection_yes_date_of_filing"
                    value={
                      otherFinanceInfo?.bankcrupty_protection_yes_date_of_filing
                    }
                    onChange={handleChange}
                    placeholder=""
                    className="form-control"
                    onBlur={handleSubmitBlur}
                  />
                  <span className="note error_note py-2 m-0 text-danger">
                    {errors?.bankcrupty_protection_yes_date_of_filing}
                  </span>
                </Col>
              )}
              {Configures?.bankcrupty_protection_yes_date_of_discharge_visible && (
                <Col lg="6" className="my-2">
                  <label htmlFor="" className="form-label2 m-0">
                    Date of discharge
                  </label>
                  <input
                    type="date"
                    name="bankcrupty_protection_yes_date_of_discharge"
                    value={
                      otherFinanceInfo?.bankcrupty_protection_yes_date_of_discharge
                    }
                    onChange={handleChange}
                    placeholder=""
                    className="form-control"
                    onBlur={handleSubmitBlur}
                  />
                  <span className="note error_note py-2 m-0 text-danger">
                    {errors?.bankcrupty_protection_yes_date_of_discharge}
                  </span>
                </Col>
              )}
              {Configures?.bankcrupty_protection_yes_petition_no_visible && (
                <Col lg="6" className="my-2">
                  <label htmlFor="" className="form-label2 m-0">
                    Petition No.
                  </label>
                  <input
                    type="number"
                    name="bankcrupty_protection_yes_petition_no"
                    value={
                      otherFinanceInfo?.bankcrupty_protection_yes_petition_no
                    }
                    onChange={handleChange}
                    placeholder=""
                    className="form-control"
                    onBlur={handleSubmitBlur}
                  />
                  <span className="note error_note py-2 m-0 text-danger">
                    {errors?.bankcrupty_protection_yes_petition_no}
                  </span>
                </Col>
              )}
              {Configures?.bankcrupty_protection_yes_location_visible && (
                <Col lg="6" className="my-2">
                  <label htmlFor="" className="form-label2 m-0">
                    Location
                  </label>
                  <input
                    type="text"
                    name="bankcrupty_protection_yes_location"
                    value={otherFinanceInfo?.bankcrupty_protection_yes_location}
                    onChange={handleChange}
                    placeholder=""
                    className="form-control"
                    onBlur={handleSubmitBlur}
                  />
                  <span className="note error_note py-2 m-0 text-danger">
                    {errors?.bankcrupty_protection_yes_location}
                  </span>
                </Col>
              )}
            </>
          )}
          {Configures?.increase_decrease_in_income_visible && (
            <Col lg="6" className="my-2 doc-select">
              <label htmlFor="" className="form-label2 m-0">
                Any anticipated increase or decrease in income?
              </label>
              <Select
                className="demo-simple-select-label mt-2"
                id="demo-simple-select"
                name="increase_decrease_in_income"
                value={otherFinanceInfo?.increase_decrease_in_income}
                onChange={handleChange}
                MenuProps={YesNo}
                onBlur={handleSubmitBlur}
              >
                {YesNo &&
                  YesNo.length > 0 &&
                  YesNo.map((item, idx) => {
                    return (
                      <MenuItem key={item?.id} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    );
                  })}
              </Select>

              <span className="note error_note py-2 m-0 text-danger">
                {errors?.increase_decrease_in_income}
              </span>
            </Col>
          )}
          {Configures?.beneficiary_to_any_trust_estate_life_insurance_visible && (
            <Col lg="6" className="my-2 doc-select">
              <label htmlFor="" className="form-label2 m-0">
                Are you Beneficiary to any trust,estate or life insurance?
              </label>
              <Select
                className="demo-simple-select-label mt-2"
                id="demo-simple-select"
                name="beneficiary_to_any_trust_estate_life_insurance"
                value={
                  otherFinanceInfo?.beneficiary_to_any_trust_estate_life_insurance
                }
                onChange={handleChange}
                MenuProps={YesNo}
                onBlur={handleSubmitBlur}
              >
                {YesNo &&
                  YesNo.length > 0 &&
                  YesNo.map((item, idx) => {
                    return (
                      <MenuItem key={item?.id} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    );
                  })}
              </Select>

              <span className="note error_note py-2 m-0 text-danger">
                {errors?.beneficiary_to_any_trust_estate_life_insurance}
              </span>
            </Col>
          )}

          {extraFields &&
            extraFields.length > 0 &&
            extraFields.map((item, idx) => {
              return (
                <>
                  {Configures &&
                    Configures[item?.title.toLowerCase().split(" ").join("_")] +
                      "_visible" && (
                      <>
                        {(item?.type === "text" ||
                          item?.type === "number" ||
                          item?.type === "email" ||
                          item?.type === "password" ||
                          item?.type === "date" ||
                          item?.type === "time" ||
                          item?.type === "date-time-local") && (
                          <Col lg="6" className="my-2" key={idx}>
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize"
                            >
                              {item?.title}
                            </label>
                            <input
                              type={item?.type}
                              name={item?.title
                                .toLowerCase()
                                .split(" ")
                                .join("_")}
                              value={
                                (otherFinanceInfo &&
                                  otherFinanceInfo[
                                    item?.title
                                      .toLowerCase()
                                      .split(" ")
                                      .join("_")
                                  ]) ||
                                ""
                              }
                              onChange={handleChange}
                              placeholder={item?.placeholder}
                              className="form-control"
                              onBlur={handleSubmitBlur}
                            />

                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}

                        {item?.type === "radio" && (
                          <Col
                            lg="6"
                            className="my-2 dynamic-checkbox"
                            key={idx}
                          >
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize w-100"
                            >
                              {item?.title}
                            </label>
                            {item?.option &&
                              item?.option.length > 0 &&
                              item?.option.map((s, i) => {
                                return (
                                  <>
                                    <input
                                      type={item?.type}
                                      name={item?.title
                                        .toLowerCase()
                                        .split(" ")
                                        .join("_")}
                                      value={
                                        (otherFinanceInfo &&
                                          otherFinanceInfo[
                                            item?.title
                                              .toLowerCase()
                                              .split(" ")
                                              .join("_")
                                          ]) ||
                                        ""
                                      }
                                      checked={
                                        (otherFinanceInfo &&
                                          otherFinanceInfo[
                                            item?.title
                                              .toLowerCase()
                                              .split(" ")
                                              .join("_")
                                          ] == s.title) ||
                                        ""
                                      }
                                      onChange={() =>
                                        handleRadioChange(
                                          item?.title
                                            .toLowerCase()
                                            .split(" ")
                                            .join("_"),
                                          s.title
                                        )
                                      }
                                      className=""
                                      onBlur={handleSubmitBlur}
                                    />
                                    <label
                                      htmlFor=""
                                      className="form-label2 m-0 text-capitalize"
                                    >
                                      {s.title}
                                    </label>
                                  </>
                                );
                              })}

                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}

                        {item?.type === "checkbox" && (
                          <Col
                            lg="6"
                            className="my-2 dynamic-checkbox"
                            key={idx}
                          >
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize w-100"
                            >
                              {item?.title}
                            </label>
                            {item?.option &&
                              item?.option.length > 0 &&
                              item?.option.map((s, i) => {
                                return (
                                  <>
                                    <input
                                      type={item?.type}
                                      name={item?.title
                                        .toLowerCase()
                                        .split(" ")
                                        .join("_")}
                                      value={
                                        (otherFinanceInfo &&
                                          otherFinanceInfo[
                                            item?.title
                                              .toLowerCase()
                                              .split(" ")
                                              .join("_")
                                          ]) ||
                                        ""
                                      }
                                      checked={
                                        otherFinanceInfo &&
                                        otherFinanceInfo[
                                          item?.title
                                            .toLowerCase()
                                            .split(" ")
                                            .join("_")
                                        ]?.includes(s.title)
                                      }
                                      onChange={() =>
                                        handleCheckChange(
                                          item?.title
                                            .toLowerCase()
                                            .split(" ")
                                            .join("_"),
                                          s.title
                                        ) || []
                                      }
                                      className=""
                                      onBlur={handleSubmitBlur}
                                    />
                                    <label
                                      htmlFor=""
                                      className="form-label2 m-0 text-capitalize"
                                    >
                                      {s.title}
                                    </label>
                                  </>
                                );
                              })}

                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}

                        {item?.type === "select" && (
                          <Col lg="6" className="my-2 doc-select" key={idx}>
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize w-100"
                            >
                              {item?.title}
                            </label>
                            <Select
                              className="demo-simple-select-label"
                              id="demo-simple-select"
                              name={item?.title
                                .toLowerCase()
                                .split(" ")
                                .join("_")}
                              value={
                                (otherFinanceInfo &&
                                  otherFinanceInfo[
                                    item?.title
                                      .toLowerCase()
                                      .split(" ")
                                      .join("_")
                                  ]) ||
                                ""
                              }
                              onBlur={handleSubmitBlur}
                              onChange={(e) =>
                                handleSelectChange(
                                  e,
                                  item?.title.toLowerCase().split(" ").join("_")
                                )
                              }
                              MenuProps={item?.option}
                            >
                              {item?.option &&
                                item?.option.length > 0 &&
                                item?.option.map((s, i) => {
                                  return (
                                    <MenuItem key={s.title} value={s.title}>
                                      {s.title}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}

                        {item?.type === "multiselect" && (
                          <Col lg="6" className="my-2 doc-select" key={idx}>
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize w-100"
                            >
                              {item?.title}
                            </label>
                            <Select
                              className="demo-simple-select-label"
                              id="demo-simple-select"
                              name={item?.title
                                .toLowerCase()
                                .split(" ")
                                .join("_")}
                              value={
                                (otherFinanceInfo &&
                                  otherFinanceInfo[
                                    item?.title
                                      .toLowerCase()
                                      .split(" ")
                                      .join("_")
                                  ]) ||
                                ""
                              }
                              onBlur={handleSubmitBlur}
                              multiple
                              onChange={(e) =>
                                handleSelectChange(
                                  e,
                                  item?.title.toLowerCase().split(" ").join("_")
                                )
                              }
                              MenuProps={item?.option}
                            >
                              {item?.option &&
                                item?.option.length > 0 &&
                                item?.option.map((s, i) => {
                                  return (
                                    <MenuItem key={s.title} value={s.title}>
                                      {s.title}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}
                      </>
                    )}
                </>
              );
            })}

          <Col lg="12" className="my-2">
            <div className="pt-4 btn-wrp d-flex algign-items-center">
              <Button
                //   disabled={errors?.disabled}
                onClick={() => handleTabChange("personalAssets")}
                className={`d-inline-flex align-items-center justify-content-center me-2`}
              >
                Next
              </Button>
              <Button
                //   disabled={errors?.disabled}
                onClick={() => handleBackBtn("empInfo")}
                className={`back-form-btnn d-inline-flex align-items-center justify-content-center me-2`}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default OtherFinancialInfo;
