import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import CKEditor from "react-ckeditor-component";
import { useDispatch, useSelector } from "react-redux";
import { addNote, getSingleNote, updateNote } from "../../../store/actions";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loading from "../../../Common/extra/loading";
import { MenuItem, Select } from "@material-ui/core";
import { DatePicker, Space } from "antd";
import locale from "antd/es/date-picker/locale/en_US";
import dayjs from "dayjs";

const AddEditNotesIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { noteId } = useParams();
  const [loading, setLoading] = useState(false);
  const user_id = localStorage && localStorage.getItem("id");
  const [state, setState] = useState({
    id: "",
    receiver_id: "",
    sender_id: "",
    note_content: "",
    sender_type: "user",
    type: "sent",
    status: "1",
    title: "",
  });
  const [disabled, setDisabled] = useState(true);
  const noteData = useSelector((s) => s?.Customers?.singleNote?.data);

  useEffect(() => {
    if (!noteId && !noteData) {
      if (state.note_content == "" || state.title == "") {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [state]);

  useEffect(() => {
    if (noteId && noteData) {
      if (state.title === noteData?.title) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [noteId, noteData, state]);

  useEffect(() => {
    if (noteId && noteData) {
      setState((pre) => ({
        ...pre,
        note_content: noteData?.note_content,
        id: noteData?.id,
        title: noteData?.title,
      }));
    }
  }, [noteData, noteId]);

  useEffect(() => {
    if (user_id) {
      setState((pre) => ({ ...pre, sender_id: user_id }));
      dispatch(getSingleNote(noteId));
    }
  }, [user_id]);

  const onChange = (evt) => {
    const newContent = evt.editor.getData();
    setState((prevState) => ({
      ...prevState,
      note_content: newContent,
    }));
  };

  const handleSubmit = (e, type) => {
    e.preventDefault();
    setLoading(true);

    const callback = (res) => {
      setLoading(false);
      if (res.data) {
        toast.success("Added Successfully");
        setDisabled(true);
        if (type == "exit") {
          navigate(`/client/notes`);
        }
      } else {
      }
    };

    const updateCallBack = (res) => {
      setLoading(false);
      if (res.data) {
        toast.success("Updated Successfully");
        setDisabled(true);
        if (type == "exit") {
          navigate(`/client/notes`);
        }
      } else {
      }
    };
    if (noteId) {
      dispatch(
        updateNote(
          {
            ...state,
            receiver_type: "admin",
          },
          updateCallBack
        )
      );
    } else {
      dispatch(
        addNote(
          {
            ...state,
            receiver_type: "admin",
          },
          callback
        )
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState((pre) => ({ ...pre, [name]: value }));
  };

  return (
    <>
      <section className="dashboard py-2">
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="bg-white px-4 py-3 card-box rounded">
                <Form>
                  <Row>
                    <Col lg="6" className="my-2">
                      <label htmlFor="" className="form-label2 m-0">
                        Subject
                      </label>
                      <input
                        type="text"
                        name="title"
                        value={state?.title}
                        onChange={handleChange}
                        placeholder=""
                        className="form-control"
                      />
                    </Col>
                    <Col lg="12" className="my-2">
                      <label for="" className="form-label2 m-0">
                        Add your comment
                      </label>
                      {loading && <Loading className={"cstmLoader"} />}
                      <CKEditor
                        activeClass="p10"
                        content={state.note_content}
                        events={{
                          change: onChange,
                          disabled: true,
                        }}
                      />
                    </Col>
                  </Row>
                </Form>
                <div className="d-flex subscribe-back due-submit-btn">
                  <Button
                    onClick={(e) => handleSubmit(e, "save")}
                    className="d-flex align-items-center justify-content-center me-1"
                    disabled={disabled}
                  >
                    Save
                  </Button>
                  <Button
                    onClick={(e) => handleSubmit(e, "exit")}
                    className="d-flex align-items-center justify-content-center"
                    disabled={disabled}
                  >
                    Save & Exit
                  </Button>
                  <Link
                    className="bck-btn-link d-flex btn-2 border align-items-center justify-content-center me-2"
                    to={"/client/notes"}
                  >
                    Back
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default AddEditNotesIndex;
