import { MenuItem, Select } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { isEmail, isEmpty } from "validator";
import { getFinanceById } from "../../../../store/actions";
import { useDispatch } from "react-redux";
const MarryStatus = [
  {
    name: "Single",
    id: "Single",
  },
  {
    name: "Married",
    id: "Married",
  },
  {
    name: "Divorced",
    id: "Divorced",
  },
  {
    name: "Widowed",
    id: "Widowed",
  },
];
const PersonalInfo = ({
  personalInfo,
  setPersonalInfo,
  handleTabChange,
  errors,
  setError,
  handleSubmitBlur,
  Configures,
  extraFields,
}) => {
  const currentDate = new Date();
  const minDate = new Date();
  const dispatch = useDispatch();
  minDate.setFullYear(currentDate.getFullYear() - 18);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const Error = { ...errors };

    switch (name) {
      case "email": {
        if (!isEmail(value)) {
          Error[name] = "Invalid Email";

          Error["disabled"] = true;
        } else {
          Error[name] = "";
          Error["disabled"] = true;
        }
        break;
      }

      case "social_security_no":
        Error[name] = "";
        if (value.length < 9) {
          Error[name] = "Social Security Number must contain 9 digits";
          Error["disabled"] = true;
        } else if (value.length > 9) {
          Error[name] = "Social Security Number must contain 9 digits";
          Error["disabled"] = true;
        } else {
          Error["disabled"] = true;
        }
        break;

      default:
        break;
    }
    setPersonalInfo((pre) => ({
      ...pre,
      [name]: value,
    }));
    setError((pre) => ({ ...pre, ...Error }));
  };

  const handlePhone = (name, phoneNumber, dialCode, validate) => {
    if (!phoneNumber || !dialCode) return false;
    const Error = { ...errors };
    switch (name) {
      case "cell_phone":
        if (!validate) {
          Error[name] = "Invalid Number";
        } else {
          Error[name] = "";
        }
        break;

      case "work_phone":
        if (!validate) {
          Error[name] = "Invalid Number";
        } else {
          Error[name] = "";
        }
        break;

      case "home_phone":
        if (!validate) {
          Error[name] = "Invalid Number";
        } else {
          Error[name] = "";
        }
        break;

      default:
        break;
    }
    setPersonalInfo((pre) => ({
      ...pre,
      [name]: phoneNumber,
    }));
    setError((pre) => ({ ...pre, ...Error }));
  };

  const userId = localStorage && localStorage.getItem("id");

  useEffect(() => {
    if (userId) {
      dispatch(getFinanceById(userId));
    }
  }, [userId]);

  const handleRadioChange = (name, value) => {
    setPersonalInfo((pre) => ({ ...pre, [name]: value }));
    // handleSubmitBlur();
  };
  const handleCheckChange = (name, value) => {
    if (personalInfo[name]?.includes(value)) {
      setPersonalInfo((pre) => ({
        ...pre,
        [name]: pre[name]?.filter((item) => {
          return item !== value;
        }),
      }));
    } else {
      setPersonalInfo((pre) => ({
        ...pre,
        [name]: pre[name] ? [...pre[name], value] : [value],
      }));
    }
    // handleSubmitBlur();
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setPersonalInfo((pre) => ({ ...pre, [name]: value }));
  };

  return (
    <div className="bg-white card-box px-4 py-3 rounded">
      <Form>
        <Row className="align-items-start">
          {Configures?.full_name_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Full Name
              </label>
              <input
                type="text"
                name="full_name"
                value={personalInfo?.full_name}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors.full_name}
              </span>
            </Col>
          )}
          {Configures?.marital_status_visible && (
            <Col lg="6" className="my-2 doc-select">
              <label htmlFor="" className="form-label2 m-0">
                Marital Status
              </label>
              <Select
                className="demo-simple-select-label mt-2"
                id="demo-simple-select"
                name="marital_status"
                value={personalInfo?.marital_status}
                onChange={handleChange}
                onBlur={handleSubmitBlur}
                MenuProps={MarryStatus}
              >
                {MarryStatus &&
                  MarryStatus.length > 0 &&
                  MarryStatus.map((item, idx) => {
                    return (
                      <MenuItem key={item?.id} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    );
                  })}
              </Select>
              <span className="note error_note py-2 m-0 text-danger">
                {errors.marital_status}
              </span>
            </Col>
          )}
          {Configures?.address_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Address
              </label>
              <input
                type="text"
                name="address"
                value={personalInfo?.address}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors.address}
              </span>
            </Col>
          )}
          {Configures?.cell_phone_visible && (
            <Col lg="6" className="my-2 col-12 phone-input">
              <label for="" className="form-label2 m-0">
                Cell Phone
              </label>
              <PhoneInput
                country={"us"}
                value={personalInfo?.cell_phone}
                placeholder="Cell phone"
                onChange={(data, country) => {
                  let validateWithLength = country.format?.split(".");
                  validateWithLength = validateWithLength?.length - 1;
                  if (validateWithLength == data.length) {
                    handlePhone("cell_phone", data, country.dialCode, true);
                  } else {
                    handlePhone("cell_phone", data, country.dialCode, false);
                  }
                }}
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors.cell_phone}
              </span>
            </Col>
          )}
          {Configures?.work_phone_visible && (
            <Col lg="6" className="my-2 col-12">
              <label for="" className="form-label2 m-0">
                Work Phone
              </label>
              <PhoneInput
                country={"us"}
                value={personalInfo?.work_phone}
                placeholder="Work Phone"
                onChange={(data, country) => {
                  let validateWithLength = country.format?.split(".");
                  validateWithLength = validateWithLength?.length - 1;
                  if (validateWithLength == data.length) {
                    handlePhone("work_phone", data, country.dialCode, true);
                  } else {
                    handlePhone("work_phone", data, country.dialCode, false);
                  }
                }}
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors.work_phone}
              </span>
            </Col>
          )}
          {Configures?.home_phone_visible && (
            <Col lg="6" className="my-2 col-12">
              <label for="" className="form-label2 m-0">
                Home Phone
              </label>
              <PhoneInput
                country={"us"}
                value={personalInfo?.home_phone}
                placeholder="Home Phone"
                onChange={(data, country) => {
                  let validateWithLength = country.format?.split(".");
                  validateWithLength = validateWithLength?.length - 1;
                  if (validateWithLength == data.length) {
                    handlePhone("home_phone", data, country.dialCode, true);
                  } else {
                    handlePhone("home_phone", data, country.dialCode, false);
                  }
                }}
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors.home_phone}
              </span>
            </Col>
          )}
          {Configures?.email_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Email
              </label>
              <input
                type="email"
                name="email"
                value={personalInfo?.email}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors.email}
              </span>
            </Col>
          )}
          {Configures?.fax_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Fax
              </label>
              <input
                type="number"
                name="fax"
                value={personalInfo?.fax}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />

              <span className="note error_note py-2 m-0 text-danger">
                {errors.fax}
              </span>
            </Col>
          )}
          {Configures?.social_security_no_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Social Security No. (SSN)
              </label>
              <input
                type="number"
                name="social_security_no"
                value={personalInfo?.social_security_no}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors.social_security_no}
              </span>
            </Col>
          )}
          {Configures?.date_of_birth_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                DOB
              </label>
              <input
                type="date"
                name="date_of_birth"
                value={personalInfo?.date_of_birth}
                onChange={handleChange}
                placeholder=""
                max={minDate.toISOString().split("T")[0]}
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors.date_of_birth}
              </span>
            </Col>
          )}
          <h5>Dependents</h5>
          {Configures?.dependents_name_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Name
              </label>
              <input
                type="text"
                name="dependents_name"
                value={personalInfo?.dependents_name}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
                required={true}
                onError={"fdkjsfdjsdkjfjhds"}
              />

              <span className="note error_note py-2 m-0 text-danger">
                {errors.dependents_name}
              </span>
            </Col>
          )}
          {Configures?.dependents_age_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Age
              </label>
              <input
                type="number"
                name="dependents_age"
                value={personalInfo?.dependents_age}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />

              <span className="note error_note py-2 m-0 text-danger">
                {errors.dependents_age}
              </span>
            </Col>
          )}
          {Configures?.dependents_relationship_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Relationship
              </label>
              <input
                type="text"
                name="dependents_relationship"
                value={personalInfo?.dependents_relationship}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />

              <span className="note error_note py-2 m-0 text-danger">
                {errors.dependents_relationship}
              </span>
            </Col>
          )}

          {extraFields &&
            extraFields.length > 0 &&
            extraFields.map((item, idx) => {
              return (
                <>
                  {Configures &&
                    Configures[item?.title.toLowerCase().split(" ").join("_")] +
                      "_visible" && (
                      <>
                        {(item?.type === "text" ||
                          item?.type === "number" ||
                          item?.type === "email" ||
                          item?.type === "password" ||
                          item?.type === "date" ||
                          item?.type === "time" ||
                          item?.type === "date-time-local") && (
                          <Col lg="6" className="my-2" key={idx}>
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize"
                            >
                              {item?.title}
                            </label>
                            <input
                              type={item?.type}
                              name={item?.title
                                .toLowerCase()
                                .split(" ")
                                .join("_")}
                              value={
                                (personalInfo &&
                                  personalInfo[
                                    item?.title
                                      .toLowerCase()
                                      .split(" ")
                                      .join("_")
                                  ]) ||
                                ""
                              }
                              onChange={handleChange}
                              placeholder={item?.placeholder}
                              className="form-control"
                              onBlur={handleSubmitBlur}
                            />

                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}

                        {item?.type === "radio" && (
                          <Col
                            lg="6"
                            className="my-2 dynamic-checkbox"
                            key={idx}
                          >
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize w-100"
                            >
                              {item?.title}
                            </label>
                            {item?.option &&
                              item?.option.length > 0 &&
                              item?.option.map((s, i) => {
                                return (
                                  <>
                                    <input
                                      type={item?.type}
                                      name={item?.title
                                        .toLowerCase()
                                        .split(" ")
                                        .join("_")}
                                      value={
                                        (personalInfo &&
                                          personalInfo[
                                            item?.title
                                              .toLowerCase()
                                              .split(" ")
                                              .join("_")
                                          ]) ||
                                        ""
                                      }
                                      checked={
                                        (personalInfo &&
                                          personalInfo[
                                            item?.title
                                              .toLowerCase()
                                              .split(" ")
                                              .join("_")
                                          ] == s.title) ||
                                        ""
                                      }
                                      onChange={() =>
                                        handleRadioChange(
                                          item?.title
                                            .toLowerCase()
                                            .split(" ")
                                            .join("_"),
                                          s.title
                                        )
                                      }
                                      className=""
                                      onBlur={handleSubmitBlur}
                                    />
                                    <label
                                      htmlFor=""
                                      className="form-label2 m-0 text-capitalize"
                                    >
                                      {s.title}
                                    </label>
                                  </>
                                );
                              })}

                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}

                        {item?.type === "checkbox" && (
                          <Col
                            lg="6"
                            className="my-2 dynamic-checkbox"
                            key={idx}
                          >
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize w-100"
                            >
                              {item?.title}
                            </label>
                            {item?.option &&
                              item?.option.length > 0 &&
                              item?.option.map((s, i) => {
                                return (
                                  <>
                                    <input
                                      type={item?.type}
                                      name={item?.title
                                        .toLowerCase()
                                        .split(" ")
                                        .join("_")}
                                      value={
                                        (personalInfo &&
                                          personalInfo[
                                            item?.title
                                              .toLowerCase()
                                              .split(" ")
                                              .join("_")
                                          ]) ||
                                        ""
                                      }
                                      checked={
                                        (personalInfo &&
                                          personalInfo[
                                            item?.title
                                              .toLowerCase()
                                              .split(" ")
                                              .join("_")
                                          ]?.includes(s.title)) ||
                                        ""
                                      }
                                      onChange={() =>
                                        handleCheckChange(
                                          item?.title
                                            .toLowerCase()
                                            .split(" ")
                                            .join("_"),
                                          s.title
                                        )
                                      }
                                      className=""
                                      onBlur={handleSubmitBlur}
                                    />
                                    <label
                                      htmlFor=""
                                      className="form-label2 m-0 text-capitalize"
                                    >
                                      {s.title}
                                    </label>
                                  </>
                                );
                              })}

                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}

                        {item?.type === "select" && (
                          <Col lg="6" className="my-2 doc-select" key={idx}>
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize w-100"
                            >
                              {item?.title}
                            </label>
                            <Select
                              className="demo-simple-select-label"
                              id="demo-simple-select"
                              name={item?.title
                                .toLowerCase()
                                .split(" ")
                                .join("_")}
                              value={
                                (personalInfo &&
                                  personalInfo[
                                    item?.title
                                      .toLowerCase()
                                      .split(" ")
                                      .join("_")
                                  ]) ||
                                ""
                              }
                              onBlur={handleSubmitBlur}
                              onChange={(e) =>
                                handleSelectChange(
                                  e,
                                  item?.title.toLowerCase().split(" ").join("_")
                                )
                              }
                              MenuProps={item?.option}
                            >
                              {item?.option &&
                                item?.option.length > 0 &&
                                item?.option.map((s, i) => {
                                  return (
                                    <MenuItem key={s.title} value={s.title}>
                                      {s.title}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}

                        {item?.type === "multiselect" && (
                          <Col lg="6" className="my-2 doc-select" key={idx}>
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize w-100"
                            >
                              {item?.title}
                            </label>
                            <Select
                              className="demo-simple-select-label"
                              id="demo-simple-select"
                              name={item?.title
                                .toLowerCase()
                                .split(" ")
                                .join("_")}
                              value={
                                (personalInfo &&
                                  personalInfo[
                                    item?.title
                                      .toLowerCase()
                                      .split(" ")
                                      .join("_")
                                  ]) ||
                                ""
                              }
                              onBlur={handleSubmitBlur}
                              multiple
                              onChange={(e) =>
                                handleSelectChange(
                                  e,
                                  item?.title.toLowerCase().split(" ").join("_")
                                )
                              }
                              MenuProps={item?.option}
                            >
                              {item?.option &&
                                item?.option.length > 0 &&
                                item?.option.map((s, i) => {
                                  return (
                                    <MenuItem key={s.title} value={s.title}>
                                      {s.title}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}
                      </>
                    )}
                </>
              );
            })}
          <Col lg="12" className="my-2">
            <div className="pt-4 btn-wrp d-flex algign-items-center">
              <Button
                // disabled={errors.disabled}
                onClick={() => handleTabChange("empInfo")}
                className={`d-inline-flex align-items-center justify-content-center me-2`}
              >
                Next
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default PersonalInfo;
