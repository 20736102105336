import { MenuItem, Select } from "@material-ui/core";
import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

const PayPer = [
  {
    name: "Weekly",
    id: "Weekly",
  },
  {
    name: "Bi-Weekly",
    id: "Bi-Weekly",
  },
  {
    name: "Monthly",
    id: "Monthly",
  },
];
const EmploymentInfo = ({
  employmentInfo,
  setEmploymentInfo,
  errors,
  setError,
  handleTabChange,
  handleSubmitBlur,
  handleBackBtn,
  Configures,
  extraFields,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    // const Error = { ...errors };

    // switch (name) {
    //   case "employer":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "length_of_job":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "employer_address":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "occupation":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "pay_period":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "spouse_employer":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "spouse_length_of_job":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "spouse_employer_address":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "spouse_occupation":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "spouse_pay_period":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   default:
    //     break;
    // }
    setEmploymentInfo((pre) => ({
      ...pre,
      [name]: value,
    }));
    // setError((pre) => ({ ...pre, ...Error }));
  };

  const handleRadioChange = (name, value) => {
    setEmploymentInfo((pre) => ({ ...pre, [name]: value }));
  };

  const handleCheckChange = (name, value) => {
    if (employmentInfo[name]?.includes(value)) {
      setEmploymentInfo((pre) => ({
        ...pre,
        [name]: pre[name]?.filter((item) => {
          return item !== value;
        }),
      }));
    } else {
      setEmploymentInfo((pre) => ({
        ...pre,
        [name]: pre[name] ? [...pre[name], value] : [value],
      }));
    }
    // handleSubmitBlur();
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setEmploymentInfo((pre) => ({ ...pre, [name]: value }));
  };

  return (
    <div className="bg-white card-box px-4 py-3 rounded">
      <Form>
        <Row className="align-items-start">
          {Configures?.employer_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Employer
              </label>
              <input
                type="text"
                name="employer"
                value={employmentInfo?.employer}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors.employer}
              </span>
            </Col>
          )}
          {Configures?.length_of_job_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Length on Job (in years)
              </label>
              <input
                type="number"
                name="length_of_job"
                value={employmentInfo?.length_of_job}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors.length_of_job}
              </span>
            </Col>
          )}
          {Configures?.employer_address_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Employer Address
              </label>
              <input
                type="text"
                name="employer_address"
                value={employmentInfo?.employer_address}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors.employer_address}
              </span>
            </Col>
          )}
          {Configures?.occupation_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Occupation
              </label>
              <input
                type="text"
                name="occupation"
                value={employmentInfo?.occupation}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors.occupation}
              </span>
            </Col>
          )}
          {Configures?.pay_period_visible && (
            <Col lg="6" className="my-2 doc-select">
              <label htmlFor="" className="form-label2 m-0">
                Pay Period
              </label>
              <Select
                className="demo-simple-select-label mt-2"
                id="demo-simple-select"
                name="pay_period"
                value={employmentInfo?.pay_period}
                onChange={handleChange}
                MenuProps={PayPer}
                onBlur={handleSubmitBlur}
              >
                {PayPer &&
                  PayPer.length > 0 &&
                  PayPer.map((item, idx) => {
                    return (
                      <MenuItem key={item?.id} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    );
                  })}
              </Select>

              <span className="note error_note py-2 m-0 text-danger">
                {errors.pay_period}
              </span>
            </Col>
          )}
          <h5>For Spouse:-</h5>
          {Configures?.spouse_employer_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Employer
              </label>
              <input
                type="text"
                name="spouse_employer"
                value={employmentInfo?.spouse_employer}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors.spouse_employer}
              </span>
            </Col>
          )}
          {Configures?.spouse_length_of_job_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Length on Job (in years)
              </label>
              <input
                type="number"
                name="spouse_length_of_job"
                value={employmentInfo?.spouse_length_of_job}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors.spouse_length_of_job}
              </span>
            </Col>
          )}
          {Configures?.spouse_employer_address_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Employer Address
              </label>
              <input
                type="text"
                name="spouse_employer_address"
                value={employmentInfo?.spouse_employer_address}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors.spouse_employer_address}
              </span>
            </Col>
          )}
          {Configures?.spouse_occupation_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Occupation
              </label>
              <input
                type="text"
                name="spouse_occupation"
                value={employmentInfo?.spouse_occupation}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors.spouse_occupation}
              </span>
            </Col>
          )}
          {Configures?.spouse_pay_period_visible && (
            <Col lg="6" className="my-2 doc-select">
              <label htmlFor="" className="form-label2 m-0">
                Pay Period
              </label>
              <Select
                className="demo-simple-select-label mt-2"
                id="demo-simple-select"
                name="spouse_pay_period"
                value={employmentInfo?.spouse_pay_period}
                onChange={handleChange}
                MenuProps={PayPer}
                onBlur={handleSubmitBlur}
              >
                {PayPer &&
                  PayPer.length > 0 &&
                  PayPer.map((item, idx) => {
                    return (
                      <MenuItem key={item?.id} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    );
                  })}
              </Select>

              <span className="note error_note py-2 m-0 text-danger">
                {errors.spouse_pay_period}
              </span>
            </Col>
          )}

          {extraFields &&
            extraFields.length > 0 &&
            extraFields.map((item, idx) => {
              return (
                <>
                  {Configures &&
                    Configures[item?.title.toLowerCase().split(" ").join("_")] +
                      "_visible" && (
                      <>
                        {(item?.type === "text" ||
                          item?.type === "number" ||
                          item?.type === "email" ||
                          item?.type === "password" ||
                          item?.type === "date" ||
                          item?.type === "time" ||
                          item?.type === "date-time-local") && (
                          <Col lg="6" className="my-2" key={idx}>
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize"
                            >
                              {item?.title}
                            </label>
                            <input
                              type={item?.type}
                              name={item?.title
                                .toLowerCase()
                                .split(" ")
                                .join("_")}
                              value={
                                (employmentInfo &&
                                  employmentInfo[
                                    item?.title
                                      .toLowerCase()
                                      .split(" ")
                                      .join("_")
                                  ]) ||
                                ""
                              }
                              onChange={handleChange}
                              placeholder={item?.placeholder}
                              className="form-control"
                              onBlur={handleSubmitBlur}
                            />

                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}

                        {item?.type === "radio" && (
                          <Col
                            lg="6"
                            className="my-2 dynamic-checkbox"
                            key={idx}
                          >
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize w-100"
                            >
                              {item?.title}
                            </label>
                            {item?.option &&
                              item?.option.length > 0 &&
                              item?.option.map((s, i) => {
                                return (
                                  <>
                                    <input
                                      type={item?.type}
                                      name={item?.title
                                        .toLowerCase()
                                        .split(" ")
                                        .join("_")}
                                      value={
                                        (employmentInfo &&
                                          employmentInfo[
                                            item?.title
                                              .toLowerCase()
                                              .split(" ")
                                              .join("_")
                                          ]) ||
                                        ""
                                      }
                                      checked={
                                        (employmentInfo &&
                                          employmentInfo[
                                            item?.title
                                              .toLowerCase()
                                              .split(" ")
                                              .join("_")
                                          ] == s.title) ||
                                        ""
                                      }
                                      onChange={() =>
                                        handleRadioChange(
                                          item?.title
                                            .toLowerCase()
                                            .split(" ")
                                            .join("_"),
                                          s.title
                                        )
                                      }
                                      className=""
                                      onBlur={handleSubmitBlur}
                                    />
                                    <label
                                      htmlFor=""
                                      className="form-label2 m-0 text-capitalize"
                                    >
                                      {s.title}
                                    </label>
                                  </>
                                );
                              })}

                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}

                        {item?.type === "checkbox" && (
                          <Col
                            lg="6"
                            className="my-2 dynamic-checkbox"
                            key={idx}
                          >
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize w-100"
                            >
                              {item?.title}
                            </label>
                            {item?.option &&
                              item?.option.length > 0 &&
                              item?.option.map((s, i) => {
                                return (
                                  <>
                                    <input
                                      type={item?.type}
                                      name={item?.title
                                        .toLowerCase()
                                        .split(" ")
                                        .join("_")}
                                      value={
                                        (employmentInfo &&
                                          employmentInfo[
                                            item?.title
                                              .toLowerCase()
                                              .split(" ")
                                              .join("_")
                                          ]) ||
                                        ""
                                      }
                                      checked={
                                        (employmentInfo &&
                                          employmentInfo[
                                            item?.title
                                              .toLowerCase()
                                              .split(" ")
                                              .join("_")
                                          ]?.includes(s.title)) ||
                                        []
                                      }
                                      onChange={() =>
                                        handleCheckChange(
                                          item?.title
                                            .toLowerCase()
                                            .split(" ")
                                            .join("_"),
                                          s.title
                                        )
                                      }
                                      className=""
                                      onBlur={handleSubmitBlur}
                                    />
                                    <label
                                      htmlFor=""
                                      className="form-label2 m-0 text-capitalize"
                                    >
                                      {s.title}
                                    </label>
                                  </>
                                );
                              })}

                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}

                        {item?.type === "select" && (
                          <Col lg="6" className="my-2 doc-select" key={idx}>
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize w-100"
                            >
                              {item?.title}
                            </label>
                            <Select
                              className="demo-simple-select-label"
                              id="demo-simple-select"
                              name={item?.title
                                .toLowerCase()
                                .split(" ")
                                .join("_")}
                              value={
                                (employmentInfo &&
                                  employmentInfo[
                                    item?.title
                                      .toLowerCase()
                                      .split(" ")
                                      .join("_")
                                  ]) ||
                                ""
                              }
                              onBlur={handleSubmitBlur}
                              onChange={(e) =>
                                handleSelectChange(
                                  e,
                                  item?.title.toLowerCase().split(" ").join("_")
                                )
                              }
                              MenuProps={item?.option}
                            >
                              {item?.option &&
                                item?.option.length > 0 &&
                                item?.option.map((s, i) => {
                                  return (
                                    <MenuItem key={s.title} value={s.title}>
                                      {s.title}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}

                        {item?.type === "multiselect" && (
                          <Col lg="6" className="my-2 doc-select" key={idx}>
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize w-100"
                            >
                              {item?.title}
                            </label>
                            <Select
                              className="demo-simple-select-label"
                              id="demo-simple-select"
                              name={item?.title
                                .toLowerCase()
                                .split(" ")
                                .join("_")}
                              value={
                                (employmentInfo &&
                                  employmentInfo[
                                    item?.title
                                      .toLowerCase()
                                      .split(" ")
                                      .join("_")
                                  ]) ||
                                ""
                              }
                              onBlur={handleSubmitBlur}
                              multiple
                              onChange={(e) =>
                                handleSelectChange(
                                  e,
                                  item?.title.toLowerCase().split(" ").join("_")
                                )
                              }
                              MenuProps={item?.option}
                            >
                              {item?.option &&
                                item?.option.length > 0 &&
                                item?.option.map((s, i) => {
                                  return (
                                    <MenuItem key={s.title} value={s.title}>
                                      {s.title}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item?.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}
                      </>
                    )}
                </>
              );
            })}

          <Col lg="12" className="my-2 form-submit-butn">
            <div className="pt-4 btn-wrp d-flex algign-items-center">
              <Button
                //   disabled={errors.disabled}
                onClick={() => handleTabChange("otherFinancialInfo")}
                className={`d-inline-flex align-items-center justify-content-center me-2`}
              >
                Next
              </Button>
              <Button
                //   disabled={errors.disabled}
                onClick={() => handleBackBtn("personalInfo")}
                className={`back-form-btnn d-inline-flex align-items-center justify-content-center me-2`}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default EmploymentInfo;
