import React from "react";
import AccountLayout from "../AccountLayout";
import { Container, Form, Row, Button, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginOtp, loginUser, selectOtpOption } from "../../../store/actions";
import ReactPinField from "react-pin-field";
import { toast } from "react-toastify";
import { isEmail, isEmpty } from "validator";
import Loading from "../../../Common/extra/loading";

const LoginForm = () => {
  const dispatch = useDispatch();
  const [field, setField] = useState("login");
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showPass, setShowpass] = useState(false);
  const [otpOption, setOtpOption] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "email") {
      if (isEmpty(value)) {
        setError("Required");
        setData((prev) => ({
          ...prev,
          [name]: value,
        }));
      } else if (!isEmail(value)) {
        setError("Invalid Email");
        setData((prev) => ({
          ...prev,
          [name]: value,
        }));
      } else {
        setError("");
        setData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else {
      setData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const callback = (res) => {
      setLoading(false);
    };
    dispatch(loginUser({ otp: otp, ...data }, callback));
  };

  const handleRequestOtp = (e) => {
    e.preventDefault();
    setLoading(true);
    const callback = (res) => {
      setLoading(false);
      if (res.detail) {
        setField("selectOption");
        toast.success(res.data.message);
      }
    };

    dispatch(loginOtp(data, callback));
  };

  const handleField = () => {
    if (field == "login") {
    } else {
      setField("login");
    }
  };

  const handleOtpChange = (val) => {
    setOtp(val);
  };

  const handleSubmitOption = (e, Val) => {
    e.preventDefault();
    setOtpOption(Val);
    setLoading(true);
    const callback = (res) => {
      setLoading(false);
      if (res.data) {
        setField("otp");
        toast.success(res.message);
      }
    };
    dispatch(selectOtpOption({ ...data, otp_option: Val }, callback));
  };

  const handleOptionBack = (e) => {
    e.preventDefault();

    setField("selectOption");
  };

  return (
    <>
      {loading && <Loading className={"cstmLoader"} />}
      <AccountLayout>
        <div className="account-card px-4 py-4 bg-white">
          {field === "otp" && (
            <Button
              variant="transparent"
              className="back-otp-btn mb-3"
              onClick={handleOptionBack}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xmlnsSvgjs="http://svgjs.com/svgjs"
                width="15"
                height="15"
                x="0"
                y="0"
                viewBox="0 0 24 24"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                class=""
              >
                <g>
                  <path
                    d="M22 11H4.414l5.293-5.293a1 1 0 1 0-1.414-1.414l-7 7a1 1 0 0 0 0 1.414l7 7a1 1 0 0 0 1.414-1.414L4.414 13H22a1 1 0 0 0 0-2z"
                    fill="#2199b7"
                    data-original="#2199b7"
                    class=""
                  ></path>
                </g>
              </svg>{" "}
              Back
            </Button>
          )}
          <div className="head-div mb-3">
            <h2
              className={`mb-0 pb-2 head text-center ${
                otpOption == "mobile" ? "login-otp-mobile" : "login-otp-both"
              }`}
            >
              {(field == "login" && "Sign In") ||
                (field == "otp" &&
                  `${
                    otpOption == "mobile"
                      ? "Code has been sent to your mobile number"
                      : ""
                  } ${
                    otpOption == "email"
                      ? "Code has been sent to your mail"
                      : ""
                  } ${
                    otpOption == "call"
                      ? "Code has been sent to you via call"
                      : ""
                  }`)}
            </h2>
          </div>
          <Form onSubmit={handleSubmit} autoComplete="off">
            <input
              type="text"
              name="hidden"
              autoComplete="false"
              style={{ display: "none" }}
            />
            {field == "login" && (
              <Row>
                <Col lg="12" className="my-3">
                  <label className="form-label m-0">Your email</label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={data?.email}
                    placeholder="Your email"
                    onChange={handleChange}
                  />
                  <span className="note error_note py-2 m-0 text-danger">
                    {error}
                  </span>
                </Col>
                <Col lg="12" className="my-3">
                  <div className="edit-pass d-flex align-items-center">
                    <label className="m-0 form-label2 m-0 me-3">Password</label>
                  </div>
                  <div className={"icon-with-div pass position-relative"}>
                    <a
                      onClick={() => setShowpass(!showPass)}
                      className="icon-wrp2 position-absolute"
                    >
                      {showPass == false ? (
                        <svg
                          className="MuiSvgIcon-root"
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path>
                        </svg>
                      ) : (
                        <svg
                          className="MuiSvgIcon-root"
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path
                            fill="none"
                            d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z"
                          ></path>
                          <path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"></path>
                        </svg>
                      )}
                    </a>

                    <input
                      type={showPass == false ? "password" : "text"}
                      value={data?.password}
                      name="password"
                      onChange={handleChange}
                      // autocomplete="off"
                      className="form-control"
                    />
                  </div>
                </Col>

                <Col
                  lg="12"
                  className="d-flex align-items-center justify-content-between"
                >
                  <Link to="/client/password-remind" className="form-link">
                    Password Remind
                  </Link>
                  <Link to="/client/forgetpassword" className="form-link">
                    Forget Password
                  </Link>
                </Col>
                <Col lg="12" className="my-3 btn-wrap">
                  <Button
                    type="submit"
                    className="w-100 text-white submit-btn"
                    onClick={handleRequestOtp}
                    disabled={!data?.password || !data?.email || error !== ""}
                  >
                    Sign in
                  </Button>
                </Col>
              </Row>
            )}
            {field == "otp" && (
              <Row>
                <Col lg="12" className="my-3 btn-wrap otp-field">
                  <ReactPinField
                    style={{ width: "50px", height: "40px" }}
                    class="pin-field"
                    validate="0123456789"
                    length="6"
                    onChange={handleOtpChange}
                  />
                </Col>
                <Col lg="12" className="my-3 btn-wrap">
                  <Button
                    type="submit"
                    className="w-100 text-white submit-btn"
                    disabled={otp.length < 6 ? true : false}
                    onClick={handleSubmit}
                  >
                    Verify OTP
                  </Button>
                  <div className="text-center mt-3">
                    <Button
                      className="recieve-code-link bg-transparent border-0 text-decoration-underline text-black"
                      onClick={(e) => handleSubmitOption(e, otpOption)}
                    >
                      Resend code
                    </Button>
                  </div>
                </Col>
              </Row>
            )}
            {field == "selectOption" && (
              <div class="otp-selectt-option">
                <div className="heading-otp text-center">
                  <h2>Select an option to get security code</h2>
                  <p>
                    {/* Your registered phone number is{" "}
                    <strong> *********7581</strong> */}
                  </p>
                </div>
                <div className="link-btn-otp pt-2">
                  <Button
                    className="w-100 bg-transparent border-0"
                    onClick={(e) => handleSubmitOption(e, "call")}
                  >
                    <div className="btn-main-otp d-flex justify-content-between align-items-center">
                      <div className="icon-left-head d-flex align-items-center">
                        <div className="icon-svg d-flex align-items-center justify-content-center">
                          <svg
                            width="24"
                            height="24"
                            fill="currentColor"
                            focusable="false"
                            viewBox="0 0 24 24"
                          >
                            <path d="M18.814 8.872c-.257-3.514-3.214-6.3-6.814-6.3s-6.557 2.786-6.814 6.3c-1.5.343-2.614 1.629-2.614 3.129s1.114 2.786 2.614 3.128c.257 3.515 3.214 6.3 6.814 6.3h2.572v-1.714H12c-2.828 0-5.143-2.314-5.143-5.143V9.43c0-2.828 2.315-5.142 5.143-5.142 2.829 0 5.143 2.314 5.143 5.142v4.929a.86.86 0 0 0 .857.857c1.886 0 3.429-1.457 3.429-3.214 0-1.5-1.114-2.786-2.614-3.129ZM4.286 12.001c0-.557.343-1.029.857-1.286v2.572c-.514-.258-.857-.729-.857-1.286Zm14.571 1.286v-2.572c.515.257.858.729.858 1.286s-.343 1.028-.858 1.286Z"></path>
                          </svg>
                        </div>
                        <h4 className="m-0">Send code by voice call</h4>
                      </div>
                      <div className="right-icon-otp">
                        <svg
                          width="16"
                          height="16"
                          fill="currentColor"
                          focusable="false"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z"></path>
                        </svg>
                      </div>
                    </div>
                  </Button>
                  <Button
                    className="w-100 bg-transparent border-0"
                    onClick={(e) => handleSubmitOption(e, "email")}
                  >
                    <div className="btn-main-otp d-flex justify-content-between align-items-center">
                      <div className="icon-left-head d-flex align-items-center">
                        <div className="icon-svg d-flex align-items-center justify-content-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            xmlnsSvgjs="http://svgjs.com/svgjs"
                            width="24"
                            height="24"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            style={{ enableBackground: "new 0 0 512 512" }}
                            xmlSpace="preserve"
                            class=""
                          >
                            <g>
                              <path
                                d="M485.743 85.333H26.257C11.815 85.333 0 97.148 0 111.589V400.41c0 14.44 11.815 26.257 26.257 26.257h459.487c14.44 0 26.257-11.815 26.257-26.257V111.589c-.001-14.441-11.816-26.256-26.258-26.256zm-9.853 19.691L271.104 258.626c-3.682 2.802-9.334 4.555-15.105 4.529-5.77.026-11.421-1.727-15.104-4.529L36.109 105.024H475.89zM366.5 268.761l111.59 137.847c.112.138.249.243.368.368H33.542c.118-.131.256-.23.368-.368L145.5 268.761a9.846 9.846 0 0 0-1.464-13.851c-4.227-3.419-10.424-2.771-13.844 1.457l-110.5 136.501V117.332l209.394 157.046c7.871 5.862 17.447 8.442 26.912 8.468 9.452-.02 19.036-2.6 26.912-8.468l209.394-157.046v275.534L381.807 256.367c-3.42-4.227-9.623-4.877-13.844-1.457-4.234 3.419-4.884 9.624-1.463 13.851z"
                                fill="#000000"
                                data-original="#000000"
                                class=""
                              ></path>
                            </g>
                          </svg>
                        </div>
                        <h4 className="m-0">Send code by Mail</h4>
                      </div>
                      <div className="right-icon-otp">
                        <svg
                          width="16"
                          height="16"
                          fill="currentColor"
                          focusable="false"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z"></path>
                        </svg>
                      </div>
                    </div>
                  </Button>
                  <Button
                    className="w-100 bg-transparent border-0"
                    onClick={(e) => handleSubmitOption(e, "mobile")}
                  >
                    <div className="btn-main-otp d-flex justify-content-between align-items-center">
                      <div className="icon-left-head align-items-center d-flex">
                        <div className="icon-svg d-flex align-items-center justify-content-center">
                          <svg
                            width="24"
                            height="24"
                            fill="currentColor"
                            focusable="false"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M21.429 3H2.572a.86.86 0 0 0-.858.857v12.857a.86.86 0 0 0 .857.857h4.286v2.572c0 .3.172.6.472.771.128.086.257.086.385.086a.864.864 0 0 0 .472-.129l4.928-3.3h8.315a.86.86 0 0 0 .857-.857V3.857A.86.86 0 0 0 21.429 3Zm-.857 12.857h-7.715a.864.864 0 0 0-.471.129l-3.814 2.571v-1.843a.86.86 0 0 0-.858-.857H3.43V4.714H20.57v11.143Zm-1.715-8.574H5.143v1.714h13.714V7.283Zm-13.714 4.29h13.714v1.714H5.143v-1.715Z"
                            ></path>
                          </svg>
                        </div>
                        <h4 className="m-0"> Send code by SMS</h4>
                      </div>
                      <div className="right-icon-otp">
                        <svg
                          width="16"
                          height="16"
                          fill="currentColor"
                          focusable="false"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z"></path>
                        </svg>
                      </div>
                    </div>
                  </Button>
                </div>
              </div>
            )}
          </Form>
        </div>
      </AccountLayout>
    </>
  );
};
export default LoginForm;
