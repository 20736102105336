import {
  put,
  call,
  take,
  every,
  takeLatest,
  takeEvery,
} from "redux-saga/effects";
import * as CONST from "./actionTypes";
import * as ACTION from "./actions";
import * as API from "../../Services/ApiCalls";
import { toast } from "react-toastify";

function* customerListSaga({ payload, callBack }) {
  try {
    const response = yield call(API.CUSTOMERS_LIST, payload);
    if (response.status == 200) {
      yield put(ACTION.customerList_Success(response.data));
    } else {
      yield put(ACTION.customerList_Fail(response.data.error));
    }
  } catch (error) {
    yield put(ACTION.customerList_Fail(response.data.error));
  }
}
function* EditUsersaga({ payload, callBack }) {
  try {
    const response = yield call(API.EDIT_MANAGE_CUSTOMER, payload);
    if (response.status == 200) {
      yield put(ACTION.editCustomer_Success(response.data));
    } else {
      yield put(ACTION.editCustomer_Fail(response.data.error));
    }
  } catch (error) {
    yield put(ACTION.editCustomer_Fail(response.data.error));
  }
}
function* updateUserSaga({ payload, callBack }) {
  try {
    const response = yield call(API.UPDATE_USER, payload);
    if (response.data.status == "success") {
      yield put(ACTION.updateUser_Success(response.data));
      callBack && callBack(response.data);
    } else {
      yield put(ACTION.updateUser_Fail(response.data.error));
    }
  } catch (error) {
    yield put(ACTION.updateUser_Fail(response.data.error));
  }
}
function* addUserSaga({ payload, callBack }) {
  try {
    const response = yield call(API.ADD_USER, payload);
    if (response.data.status == "success") {
      yield put(ACTION.addUser_Success(response.data));
      callBack && callBack(response.data);
    } else {
      yield put(ACTION.addUser_Fail(response.data.error));
    }
  } catch (error) {
    yield put(ACTION.addUser_Fail(response.data.error));
  }
}
function* blockUnblockUserSaga({ payload, callBack }) {
  try {
    const response = yield call(API.BLOCK_UNBLOCK_USER, payload);
    if (response.data.status == "success") {
      yield put(ACTION.blockUnblockUser_Success(response.data));
      callBack && callBack(response.data);
    } else {
      yield put(ACTION.blockUnblockUser_Fail(response.data.error));
    }
  } catch (error) {
    yield put(ACTION.blockUnblockUser_Fail(response.data.error));
  }
}
function* deleteUserSaga({ payload, callBack }) {
  try {
    const response = yield call(API.DELETE_USER, payload);
    if (response.data.status == "success") {
      yield put(ACTION.deleteUser_Success(response.data));
      callBack && callBack(response.data);
    } else {
      yield put(ACTION.deleteUser_Fail(response.data.error));
    }
  } catch (error) {
    yield put(ACTION.deleteUser_Fail(response.data.error));
  }
}

function* addTaskSaga({ payload, callBack }) {
  try {
    const response = yield call(API.ADD_TASKS, payload);
    callBack && callBack(response.data);
    if (response.data.data) {
      yield put(ACTION.addTasks_Success(response.data));
    } else {
      yield put(ACTION.addTasks_Fail(response.data.error));
    }
  } catch (error) {
    yield put(ACTION.addTasks_Fail(response.data.error));
  }
}

function* getTaskByidSaga({ payload, callBack }) {
  try {
    const response = yield call(API.GET_TASKS_BY_ID, payload);
    callBack && callBack(response.data);
    if (response.data.data) {
      yield put(ACTION.getTasksById_Success(response.data));
    } else {
      yield put(ACTION.getTasksById_Fail(response.data.error));
    }
  } catch (error) {
    yield put(ACTION.getTasksById_Fail(response.data.error));
  }
}

function* updateTaskSaga({ payload, callBack }) {
  try {
    const response = yield call(API.UPDATE_TASKS, payload);
    callBack && callBack(response.data);
    if (response.data.data) {
      yield put(ACTION.updateTasks_Success(response.data));
    } else {
      yield put(ACTION.updateTasks_Fail(response.data.error));
    }
  } catch (error) {
    yield put(ACTION.updateTasks_Fail(response.data.error));
  }
}

function* singleTaskSaga({ payload, callBack }) {
  try {
    const response = yield call(API.GET_SINGLE_TASKS, payload);
    callBack && callBack(response.data);
    if (response.data.data) {
      yield put(ACTION.getSingleTasks_Success(response.data));
    } else {
      yield put(ACTION.getSingleTasks_Fail(response.data.error));
    }
  } catch (error) {
    yield put(ACTION.getSingleTasks_Fail(response.data.error));
  }
}

function* deleteTaskSaga({ payload, callBack }) {
  try {
    const response = yield call(API.DELETE_TASKS, payload);
    callBack && callBack(response.data);
    if (response.data.data) {
      yield put(ACTION.deleteTasks_Success(response.data));
    } else {
      yield put(ACTION.deleteTasks_Fail(response.data.error));
    }
  } catch (error) {
    yield put(ACTION.deleteTasks_Fail(response.data.error));
  }
}

function* addNoteSaga({ payload, callBack }) {
  try {
    const response = yield call(API.ADD_NOTE, payload);
    callBack && callBack(response.data);
    if (response.data.data) {
      yield put(ACTION.addNote_Success(response.data));
    } else {
      yield put(ACTION.addNote_Fail(response.data.error));
    }
  } catch (error) {
    yield put(ACTION.addNote_Fail(response.data.error));
  }
}

function* getNoteByidSaga({ payload, callBack }) {
  try {
    console.log("Mango");
    const response = yield call(API.GET_NOTE_BY_ID, payload);
    callBack && callBack(response.data);
    if (response.data.data) {
      yield put(ACTION.getNoteById_Success(response.data));
    } else {
      yield put(ACTION.getNoteById_Fail(response.data.error));
    }
  } catch (error) {
    yield put(ACTION.getNoteById_Fail(response.data.error));
  }
}

function* updateNoteSaga({ payload, callBack }) {
  try {
    const response = yield call(API.UPDATE_NOTE, payload);
    callBack && callBack(response.data);
    if (response.data.data) {
      yield put(ACTION.updateNote_Success(response.data));
    } else {
      yield put(ACTION.updateNote_Fail(response.data.error));
    }
  } catch (error) {
    yield put(ACTION.updateNote_Fail(response.data.error));
  }
}

function* singleNoteSaga({ payload, callBack }) {
  try {
    const response = yield call(API.GET_SINGLE_NOTE, payload);
    callBack && callBack(response.data);
    if (response.data.data) {
      yield put(ACTION.getSingleNote_Success(response.data));
    } else {
      yield put(ACTION.getSingleNote_Fail(response.data.error));
    }
  } catch (error) {
    yield put(ACTION.getSingleNote_Fail(response.data.error));
  }
}

function* deleteNoteSaga({ payload, callBack }) {
  try {
    const response = yield call(API.DELETE_NOTE, payload);
    callBack(response.data);
    if (response.data.data) {
      yield put(ACTION.deleteNote_Success(response.data));
    } else {
      yield put(ACTION.deleteNote_Fail(response.data.error));
    }
  } catch (error) {
    yield put(ACTION.deleteNote_Fail(error.message));
  }
}

function* UserSaga() {
  yield takeEvery(CONST.CUSTOMERS_LIST, customerListSaga);
  yield takeEvery(CONST.EDIT_CUSTOMER, EditUsersaga);
  yield takeEvery(CONST.UPDATE_USER, updateUserSaga);
  yield takeEvery(CONST.ADD_USER, addUserSaga);
  yield takeEvery(CONST.BLOCK_UNBLOCK_USER, blockUnblockUserSaga);
  yield takeEvery(CONST.DELETE_USER, deleteUserSaga);
  yield takeEvery(CONST.ADD_TASKS, addTaskSaga);
  yield takeEvery(CONST.GET_TASKS_BY_ID, getTaskByidSaga);
  yield takeEvery(CONST.UPDATE_TASKS, updateTaskSaga);
  yield takeEvery(CONST.GET_SINGLE_TASKS, singleTaskSaga);
  yield takeEvery(CONST.DELETE_TASKS, deleteTaskSaga);
  yield takeEvery(CONST.ADD_NOTE, addNoteSaga);
  yield takeEvery(CONST.GET_NOTE_BY_ID, getNoteByidSaga);
  yield takeEvery(CONST.UPDATE_NOTE, updateNoteSaga);
  yield takeEvery(CONST.GET_SINGLE_NOTE, singleNoteSaga);
  yield takeEvery(CONST.DELETE_NOTE, deleteNoteSaga);
}

export default UserSaga;
