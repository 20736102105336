import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

const Assets = ({
  assets,
  setAssets,
  errors,
  setError,
  handleTabChange,
  handleBackBtn,
  handleSubmitBlur,
  Configures,
  extraFields,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    // const Error = { ...errors };

    // switch (name) {
    //   case "furniture_location":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "furniture_value":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "furniture_loanbalance":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "personal_location":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "personal_value":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "personal_loanbalance":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "artwork_location":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "artwork_value":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "artwork_loanbalance":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "jewellery_location":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "jewellery_value":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "jewellery_loanbalance":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "collection_location":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "collection_value":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "collection_loanbalance":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "guns_location":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "guns_value":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "guns_loanbalance":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "coins_location":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "coins_value":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "coins_loanbalance":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "antiques_location":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;
    //   case "antiques_value":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   case "antiques_loanbalance":
    //     Error[name] = "";
    //     if (value.length < 1) {
    //       Error[name] = "Required";
    //       Error["disabled"] = true;
    //     } else {
    //       Error["disabled"] = true;
    //     }
    //     break;

    //   default:
    //     break;
    // }
    setAssets((pre) => ({
      ...pre,
      [name]: value,
    }));
    // setError((pre) => ({ ...pre, ...Error }));
  };

  const handleRadioChange = (name, value) => {
    setAssets((pre) => ({ ...pre, [name]: value }));
  };

  const handleCheckChange = (name, value) => {
    if (assets[name]?.includes(value)) {
      setAssets((pre) => ({
        ...pre,
        [name]: pre[name]?.filter((item) => {
          return item !== value;
        }),
      }));
    } else {
      setAssets((pre) => ({
        ...pre,
        [name]: pre[name] ? [...pre[name], value] : [value],
      }));
    }
    // handleSubmitBlur();
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setAssets((pre) => ({ ...pre, [name]: value }));
  };
  return (
    <div className="bg-white card-box px-4 py-3 rounded">
      <Form>
        <Row className="align-items-start">
          <Col lg="12" className="my-2">
            <h6>Furniture</h6>
          </Col>
          {Configures?.furniture_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Location
              </label>
              <input
                type="text"
                name="furniture_location"
                value={assets?.furniture_location}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.furniture_location}
              </span>
            </Col>
          )}
          {Configures?.furniture_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Value
              </label>
              <input
                type="number"
                name="furniture_value"
                value={assets?.furniture_value}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.furniture_value}
              </span>
            </Col>
          )}
          {Configures?.furniture_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Loan Balance
              </label>
              <input
                type="number"
                name="furniture_loanbalance"
                value={assets?.furniture_loanbalance}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.furniture_loanbalance}
              </span>
            </Col>
          )}

          <Col lg="12" className="my-2">
            <h6>Personal Effects</h6>
          </Col>
          {Configures?.personal_effects_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Location
              </label>
              <input
                type="text"
                name="personal_location"
                value={assets?.personal_location}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.personal_location}
              </span>
            </Col>
          )}
          {Configures?.personal_effects_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Value
              </label>
              <input
                type="number"
                name="personal_value"
                value={assets?.personal_value}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.personal_value}
              </span>
            </Col>
          )}
          {Configures?.personal_effects_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Loan Balance
              </label>
              <input
                type="number"
                name="personal_loanbalance"
                value={assets?.personal_loanbalance}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.personal_loanbalance}
              </span>
            </Col>
          )}

          <Col lg="12" className="my-2">
            <h6>Artwork</h6>
          </Col>
          {Configures?.artwork_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Location
              </label>
              <input
                type="text"
                name="artwork_location"
                value={assets?.artwork_location}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.artwork_location}
              </span>
            </Col>
          )}
          {Configures?.artwork_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Value
              </label>
              <input
                type="number"
                name="artwork_value"
                value={assets?.artwork_value}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.artwork_value}
              </span>
            </Col>
          )}
          {Configures?.artwork_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Loan Balance
              </label>
              <input
                type="number"
                name="artwork_loanbalance"
                value={assets?.artwork_loanbalance}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.artwork_loanbalance}
              </span>
            </Col>
          )}

          <Col lg="12" className="my-2">
            <h6>Jewellery</h6>
          </Col>
          {Configures?.jewellery_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Location
              </label>
              <input
                type="text"
                name="jewellery_location"
                value={assets?.jewellery_location}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.jewellery_location}
              </span>
            </Col>
          )}
          {Configures?.jewellery_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Value
              </label>
              <input
                type="number"
                name="jewellery_value"
                value={assets?.jewellery_value}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.jewellery_value}
              </span>
            </Col>
          )}
          {Configures?.jewellery_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Loan Balance
              </label>
              <input
                type="number"
                name="jewellery_loanbalance"
                value={assets?.jewellery_loanbalance}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.jewellery_loanbalance}
              </span>
            </Col>
          )}

          <Col lg="12" className="my-2">
            <h6>Collections</h6>
          </Col>
          {Configures?.collections_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Location
              </label>
              <input
                type="text"
                name="collection_location"
                value={assets?.collection_location}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.collection_location}
              </span>
            </Col>
          )}
          {Configures?.collections_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Value
              </label>
              <input
                type="number"
                name="collection_value"
                value={assets?.collection_value}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.collection_value}
              </span>
            </Col>
          )}
          {Configures?.collections_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Loan Balance
              </label>
              <input
                type="number"
                name="collection_loanbalance"
                value={assets?.collection_loanbalance}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.collection_loanbalance}
              </span>
            </Col>
          )}

          <Col lg="12" className="my-2">
            <h6>Guns</h6>
          </Col>
          {Configures?.guns_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Location
              </label>
              <input
                type="text"
                name="guns_location"
                value={assets?.guns_location}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.guns_location}
              </span>
            </Col>
          )}
          {Configures?.guns_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Value
              </label>
              <input
                type="number"
                name="guns_value"
                value={assets?.guns_value}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.guns_value}
              </span>
            </Col>
          )}
          {Configures?.guns_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Loan Balance
              </label>
              <input
                type="number"
                name="guns_loanbalance"
                value={assets?.guns_loanbalance}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.guns_loanbalance}
              </span>
            </Col>
          )}

          <Col lg="12" className="my-2">
            <h6>Coins</h6>
          </Col>
          {Configures?.coins_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Location
              </label>
              <input
                type="text"
                name="coins_location"
                value={assets?.coins_location}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.coins_location}
              </span>
            </Col>
          )}
          {Configures?.coins_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Value
              </label>
              <input
                type="number"
                name="coins_value"
                value={assets?.coins_value}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.coins_value}
              </span>
            </Col>
          )}
          {Configures?.coins_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Loan Balance
              </label>
              <input
                type="number"
                name="coins_loanbalance"
                value={assets?.coins_loanbalance}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.coins_loanbalance}
              </span>
            </Col>
          )}

          <Col lg="12" className="my-2">
            <h6>Antiques</h6>
          </Col>
          {Configures?.antiques_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Location
              </label>
              <input
                type="text"
                name="antiques_location"
                value={assets?.antiques_location}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.antiques_location}
              </span>
            </Col>
          )}
          {Configures?.antiques_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Value
              </label>
              <input
                type="number"
                name="antiques_value"
                value={assets?.antiques_value}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.antiques_value}
              </span>
            </Col>
          )}
          {Configures?.antiques_visible && (
            <Col lg="6" className="my-2">
              <label htmlFor="" className="form-label2 m-0">
                Loan Balance
              </label>
              <input
                type="number"
                name="antiques_loanbalance"
                value={assets?.antiques_loanbalance}
                onChange={handleChange}
                placeholder=""
                className="form-control"
                onBlur={handleSubmitBlur}
              />
              <span className="note error_note py-2 m-0 text-danger">
                {errors?.antiques_loanbalance}
              </span>
            </Col>
          )}

          {extraFields &&
            extraFields.length > 0 &&
            extraFields.map((item, idx) => {
              return (
                <>
                  {Configures &&
                    Configures[item?.title.toLowerCase().split(" ").join("_")] +
                      "_visible" && (
                      <>
                        {(item.type === "text" ||
                          item.type === "number" ||
                          item.type === "email" ||
                          item.type === "password" ||
                          item.type === "date" ||
                          item.type === "time" ||
                          item.type === "date-time-local") && (
                          <Col lg="6" className="my-2" key={idx}>
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize"
                            >
                              {item.title}
                            </label>
                            <input
                              type={item.type}
                              name={item.title
                                .toLowerCase()
                                .split(" ")
                                .join("_")}
                              value={
                                (assets &&
                                  assets[
                                    item.title
                                      .toLowerCase()
                                      .split(" ")
                                      .join("_")
                                  ]) ||
                                ""
                              }
                              onChange={handleChange}
                              placeholder={item.placeholder}
                              className="form-control"
                              onBlur={handleSubmitBlur}
                            />

                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}

                        {item.type === "radio" && (
                          <Col
                            lg="6"
                            className="my-2 dynamic-checkbox"
                            key={idx}
                          >
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize w-100"
                            >
                              {item.title}
                            </label>
                            {item.option &&
                              item.option.length > 0 &&
                              item.option.map((s, i) => {
                                return (
                                  <>
                                    <input
                                      type={item.type}
                                      name={item.title
                                        .toLowerCase()
                                        .split(" ")
                                        .join("_")}
                                      value={
                                        (assets &&
                                          assets[
                                            item.title
                                              .toLowerCase()
                                              .split(" ")
                                              .join("_")
                                          ]) ||
                                        ""
                                      }
                                      checked={
                                        assets &&
                                        assets[
                                          item.title
                                            .toLowerCase()
                                            .split(" ")
                                            .join("_")
                                        ] == s.title
                                      }
                                      onChange={() =>
                                        handleRadioChange(
                                          item.title
                                            .toLowerCase()
                                            .split(" ")
                                            .join("_"),
                                          s.title
                                        )
                                      }
                                      className=""
                                      onBlur={handleSubmitBlur}
                                    />
                                    <label
                                      htmlFor=""
                                      className="form-label2 m-0 text-capitalize"
                                    >
                                      {s.title}
                                    </label>
                                  </>
                                );
                              })}

                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}

                        {item.type === "checkbox" && (
                          <Col
                            lg="6"
                            className="my-2 dynamic-checkbox"
                            key={idx}
                          >
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize w-100"
                            >
                              {item.title}
                            </label>
                            {item.option &&
                              item.option.length > 0 &&
                              item.option.map((s, i) => {
                                return (
                                  <>
                                    <input
                                      type={item.type}
                                      name={item.title
                                        .toLowerCase()
                                        .split(" ")
                                        .join("_")}
                                      value={
                                        (assets &&
                                          assets[
                                            item.title
                                              .toLowerCase()
                                              .split(" ")
                                              .join("_")
                                          ]) ||
                                        ""
                                      }
                                      checked={
                                        assets &&
                                        assets[
                                          item.title
                                            .toLowerCase()
                                            .split(" ")
                                            .join("_")
                                        ]?.includes(s.title)
                                      }
                                      onChange={() =>
                                        handleCheckChange(
                                          item.title
                                            .toLowerCase()
                                            .split(" ")
                                            .join("_"),
                                          s.title
                                        )
                                      }
                                      className=""
                                      onBlur={handleSubmitBlur}
                                    />
                                    <label
                                      htmlFor=""
                                      className="form-label2 m-0 text-capitalize"
                                    >
                                      {s.title}
                                    </label>
                                  </>
                                );
                              })}

                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}

                        {item.type === "select" && (
                          <Col lg="6" className="my-2 doc-select" key={idx}>
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize w-100"
                            >
                              {item.title}
                            </label>
                            <Select
                              className="demo-simple-select-label"
                              id="demo-simple-select"
                              name={item.title
                                .toLowerCase()
                                .split(" ")
                                .join("_")}
                              value={
                                (assets &&
                                  assets[
                                    item.title
                                      .toLowerCase()
                                      .split(" ")
                                      .join("_")
                                  ]) ||
                                ""
                              }
                              onBlur={handleSubmitBlur}
                              onChange={(e) =>
                                handleSelectChange(
                                  e,
                                  item.title.toLowerCase().split(" ").join("_")
                                )
                              }
                              MenuProps={item.option}
                            >
                              {item.option &&
                                item.option.length > 0 &&
                                item.option.map((s, i) => {
                                  return (
                                    <MenuItem key={s.title} value={s.title}>
                                      {s.title}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}

                        {item.type === "multiselect" && (
                          <Col lg="6" className="my-2 doc-select" key={idx}>
                            <label
                              htmlFor=""
                              className="form-label2 m-0 text-capitalize w-100"
                            >
                              {item.title}
                            </label>
                            <Select
                              className="demo-simple-select-label"
                              id="demo-simple-select"
                              name={item.title
                                .toLowerCase()
                                .split(" ")
                                .join("_")}
                              value={
                                (assets &&
                                  assets[
                                    item.title
                                      .toLowerCase()
                                      .split(" ")
                                      .join("_")
                                  ]) ||
                                ""
                              }
                              onBlur={handleSubmitBlur}
                              multiple
                              onChange={(e) =>
                                handleSelectChange(
                                  e,
                                  item.title.toLowerCase().split(" ").join("_")
                                )
                              }
                              MenuProps={item.option}
                            >
                              {item.option &&
                                item.option.length > 0 &&
                                item.option.map((s, i) => {
                                  return (
                                    <MenuItem key={s.title} value={s.title}>
                                      {s.title}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <span className="note error_note py-2 m-0 text-danger">
                              {
                                errors[
                                  item.title.toLowerCase().split(" ").join("_")
                                ]
                              }
                            </span>
                          </Col>
                        )}
                      </>
                    )}
                </>
              );
            })}

          <Col lg="12" className="my-2">
            <div className="pt-4 btn-wrp d-flex algign-items-center">
              <Button
                //   disabled={errors?.disabled}
                onClick={() => handleTabChange("incomeExpenseStatement")}
                className={`d-inline-flex align-items-center justify-content-center me-2`}
              >
                Next
              </Button>
              <Button
                //   disabled={errors?.disabled}
                onClick={() => handleBackBtn("vehicles")}
                className={`back-form-btnn d-inline-flex align-items-center justify-content-center me-2`}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Assets;
