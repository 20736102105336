import { MenuItem, Select } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";

const optionObj = {
  title: "",
  value: "",
};
const PropertyAddons = ({
  data,
  handleChange,
  activeTab,
  setRealPropertyOwned,
  realPropertyOwned,
  handleSaveField,
  handleSubmitBlur,
  handleAmountChange,
}) => {
  const [state, setState] = useState({
    addons: data,
  });
  console.log(state.addons, "state.addons");
  const [isChange, setIsChange] = useState(false);
  const [saveChange, setSaveChange] = useState(false);
  const [addState, setAddState] = useState({
    property_description: "",
    location: "",
    current_fair_market_value: "",
    loan_balance: "",
    amount_of_monthly_payment: "",
    amount_of_your_equity: "",
    name_of_lender: "",
    address: "",
    account_no: "",
  });

  useEffect(() => {
    setState({ addons: data });
  }, [data]);

  useEffect(() => {
    handleChange(state.addons);
  }, [isChange]);

  const addRow = (e) => {
    e.preventDefault();
    if (state.addons.length > 0) {
      setState((pre) => ({
        ...pre,
        addons: [...pre.addons, addState],
      }));
    } else {
      setState((pre) => ({
        ...pre,
        addons: [addState],
      }));
    }
  };

  const deleteRow = (e, index) => {
    e.preventDefault();
    const addons = state.addons.filter((e, i) => i !== index);
    setState({
      addons: [...addons],
    });
    setIsChange(!isChange);
    setSaveChange(!saveChange);
  };

  const addOptionRow = (e, i) => {
    e.preventDefault();
    setState((pre) => ({
      ...pre,
      addons: [
        ...pre.addons.slice(0, i),
        {
          ...pre.addons[i],
          option: [...pre.addons[i].option, optionObj],
        },

        ...pre.addons.slice(i + 1),
      ],
    }));
  };

  const deleteOptionRow = (e, i, optionIndx) => {
    e.preventDefault();
    setState((pre) => ({
      ...pre,
      addons: [
        ...pre.addons.slice(0, i),
        {
          ...pre.addons[i],
          option: pre.addons[i].option.filter((_, indx) => indx !== optionIndx),
        },

        ...pre.addons.slice(i + 1),
      ],
    }));
  };

  const changeRowvalue = (e, i) => {
    const { name, value } = e.target;

    if (
      name === "current_fair_market_value" ||
      name === "loan_balance" ||
      name === "amount_of_monthly_payment" ||
      name === "amount_of_your_equity"
    ) {
      const numericValue = value.replace(/[^0-9]/g, "");
      setState((pre) => ({
        ...pre,
        addons: [
          ...pre.addons.slice(0, i),
          {
            ...pre.addons[i],
            [name]: numericValue,
          },

          ...pre.addons.slice(i + 1),
        ],
      }));
    } else {
      setState((pre) => ({
        ...pre,
        addons: [
          ...pre.addons.slice(0, i),
          {
            ...pre.addons[i],
            [name]: value,
          },

          ...pre.addons.slice(i + 1),
        ],
      }));
    }

    setIsChange(!isChange);
  };
  console.log(state?.addons, "<<<<state?.addons");
  return (
    <>
      {state?.addons &&
        state?.addons.map((s, i) => {
          return (
            <div className="my-2">
              <h2 className="text-black custom-maintext">Property #{i + 1}</h2>
              <Row className="align-items-center columns-fields property-addon">
                <Col lg="6" className="my-2">
                  <label htmlFor="" className="form-label2 m-0">
                    Property Description
                  </label>
                  <input
                    type="text"
                    name="property_description"
                    value={s?.property_description}
                    onChange={(e) => changeRowvalue(e, i)}
                    placeholder="i.e. Home, Rental, Time Share"
                    className="form-control"
                    onBlur={handleSubmitBlur}
                  />
                  {/* <span className="note error_note py-2 m-0 text-danger">
                    {errors.property_description}
                  </span> */}
                </Col>
                <Col lg="6" className="my-2">
                  <label htmlFor="" className="form-label2 m-0">
                    Location
                  </label>
                  <input
                    type="text"
                    name="location"
                    value={s?.location}
                    onChange={(e) => changeRowvalue(e, i)}
                    placeholder=""
                    className="form-control"
                    onBlur={handleSubmitBlur}
                  />
                  {/* <span className="note error_note py-2 m-0 text-danger">
                    {errors.location}
                  </span> */}
                </Col>
                <Col lg="6" className="my-2">
                  <label htmlFor="" className="form-label2 m-0">
                    Current Fair Market Value
                  </label>
                  <input
                    type="text"
                    name="current_fair_market_value"
                    value={
                      s?.current_fair_market_value &&
                      Number(s?.current_fair_market_value).toLocaleString()
                    }
                    onChange={(e) => changeRowvalue(e, i)}
                    placeholder=""
                    className="form-control"
                    onBlur={handleSubmitBlur}
                  />
                  {/* <span className="note error_note py-2 m-0 text-danger">
                    {errors.current_fair_market_value}
                  </span> */}
                </Col>
                <Col lg="6" className="my-2">
                  <label htmlFor="" className="form-label2 m-0">
                    Loan Balance
                  </label>
                  <input
                    type="text"
                    name="loan_balance"
                    value={
                      s?.loan_balance &&
                      Number(s?.loan_balance).toLocaleString()
                    }
                    onChange={(e) => changeRowvalue(e, i)}
                    placeholder=""
                    className="form-control"
                    onBlur={handleSubmitBlur}
                  />
                  {/* <span className="note error_note py-2 m-0 text-danger">
                    {errors.loan_balance}
                  </span> */}
                </Col>
                <Col lg="6" className="my-2">
                  <label htmlFor="" className="form-label2 m-0">
                    Amount of Monthly Payment
                  </label>
                  <input
                    type="text"
                    name="amount_of_monthly_payment"
                    value={
                      s?.amount_of_monthly_payment &&
                      Number(s?.amount_of_monthly_payment).toLocaleString()
                    }
                    onChange={(e) => changeRowvalue(e, i)}
                    placeholder=""
                    className="form-control"
                    onBlur={handleSubmitBlur}
                  />
                  {/* <span className="note error_note py-2 m-0 text-danger">
                    {errors.amount_of_monthly_payment}
                  </span> */}
                </Col>
                <Col lg="6" className="my-2">
                  <label htmlFor="" className="form-label2 m-0">
                    Amount of your Equity
                  </label>
                  <input
                    type="text"
                    name="amount_of_your_equity"
                    value={
                      s?.amount_of_your_equity &&
                      Number(s?.amount_of_your_equity).toLocaleString()
                    }
                    onChange={(e) => changeRowvalue(e, i)}
                    placeholder=""
                    className="form-control"
                    onBlur={handleSubmitBlur}
                  />
                  {/* <span className="note error_note py-2 m-0 text-danger">
                    {errors.amount_of_your_equity}
                  </span> */}
                </Col>
                <Col lg="6" className="my-2">
                  <label htmlFor="" className="form-label2 m-0">
                    Name of Lender
                  </label>
                  <input
                    type="text"
                    name="name_of_lender"
                    value={s?.name_of_lender}
                    onChange={(e) => changeRowvalue(e, i)}
                    placeholder=""
                    className="form-control"
                    onBlur={handleSubmitBlur}
                  />
                  {/* <span className="note error_note py-2 m-0 text-danger">
                    {errors.name_of_lender}
                  </span> */}
                </Col>
                <Col lg="6" className="my-2">
                  <label htmlFor="" className="form-label2 m-0">
                    Address of Lender
                  </label>
                  <input
                    type="text"
                    name="address"
                    value={s?.address}
                    onChange={(e) => changeRowvalue(e, i)}
                    placeholder=""
                    className="form-control"
                    onBlur={handleSubmitBlur}
                  />
                  {/* <span className="note error_note py-2 m-0 text-danger">
                    {errors.address}
                  </span> */}
                </Col>
                <Col lg="6" className="my-2">
                  <label htmlFor="" className="form-label2 m-0">
                    Account Number
                  </label>
                  <input
                    type="number"
                    name="account_no"
                    value={s?.account_no}
                    onChange={(e) => changeRowvalue(e, i)}
                    placeholder=""
                    className="form-control"
                    onBlur={handleSubmitBlur}
                  />
                  {/* <span className="note error_note py-2 m-0 text-danger">
                    {errors.account_no}
                  </span> */}
                </Col>
                <Col md={12} className="text-left">
                  <div className=" text-end pt-4">
                    <button
                      className="remove-btn addon-btn-delete address-btn-remove address-remove position-relative"
                      onClick={(e) => deleteRow(e, i, "addons")}
                    >
                      Remove Property
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          );
        })}
      {/* {state.addons?.length > 0 && ( */}
      <div className="text-end pt-3 d-flex justify-content-between">
        <Button
          type="button"
          className="expension-button text-center"
          data-toggle="modal"
          data-target="#exampleModalScrollable1"
          onClick={(e) => addRow(e, "addons")}
        >
          Add More Property
        </Button>
        <Button className="" onClick={() => handleSaveField(state.addons)}>
          Save Fields
        </Button>
      </div>
      {/* )} */}
      <div className="expension-btn text-left"></div>
    </>
  );
};

export default React.memo(PropertyAddons);
